export default {
  environment: "production",
  envUrl: "https://must.sumit-ai.com",
  server: {
    host: "https://must-server.sumit-ai.com",
    auth: "auth",
    users: "users",
    jobs: "jobs",
    attachments: "attachments",
    uploads: "uploads",
    tags: "tags",
    files: "files",
    export: "export",
    invoices: "invoices",
  },
  googleAuthProvider: {
    clientId:
      "136073597195-vir5hjhcaea9itiudj4g0n41o452a6s0.apps.googleusercontent.com",
  },
  microsoftAuthProvider: {
    clientId: "b6a0456f-97a2-4d28-a402-69eb197b24d6",
    scopes: ["Calendars.Read"],
    redirectUri: "https://must.sumit-ai.com",
    authority: "https://login.microsoftonline.com/common",
  },
  firebase: {
    clientId:
      "136073597195-uealeull2afketl3q2pnjceshv8jecg0.apps.googleusercontent.com",
    apiKey: "AIzaSyAzaed6HrVfp_hrxcBO01zD4EOG7U9OITY",
    authDomain: "sumit-249611.firebaseapp.com",
    projectId: "sumit-249611",
    storageBucket: "sumit-249611.appspot.com",
    messagingSenderId: "136073597195",
    appId: "1:136073597195:web:3c9866d32d3be0ffa8120e",
    measurementId: "G-S1WFT6CQBS",
    tenantId: "platforms-ds2lb",
  },
  featureFlags: {
    jobContextSearch: true,
    apiKey:
      "must:production.8d0031d76c045c13356d89e0385b72bfc6c129cb20b1daa5bbe783ca",
    autoTranscript: {
      clients: [
        210, 443, 486, 573, 764, 875, 901, 910, 913, 756, 915, 917, 919, 425,
        921, 933, 934, 937, 946, 947, 948, 949, 952, 956, 957, 961, 963, 964,
        966, 969, 972, 973, 975, 977, 978, 981, 983,
      ],
      enabled: true,
    },
    transcriptEditor: {
      enabled: true,
    },
    extract: {
      enabled: true,
      clients: [443, 915, 975],
    },
  },
  cloudinary: {
    cloud_name: "df9neab8j",
    upload_preset: "ejh79zy5",
    api_key: "724134266828553",
  },
};
