import { recordStore } from "../../../../store";
import { CircleButton } from "../../../../index";
import { faMicrophone, faPause } from "@fortawesome/pro-light-svg-icons";
import classNames from "classnames";

export const RecordButton = ({
  disabled,
  handleToggleRecord,
}: {
  handleToggleRecord: () => void;
  disabled: boolean;
}) => {
  const { isRecording, isPaused } = recordStore();

  return (
    <CircleButton
      className={classNames("recordButton")}
      border={isRecording && !isPaused ? "red" : isPaused ? "black" : null}
      onClick={handleToggleRecord}
      disabled={disabled}
      icon={isRecording ? (!isPaused ? faPause : faMicrophone) : faMicrophone}
    />
  );
};
