import { type UpdateClientUploadsSettings } from "../../../../index";
import { CardItem, CardToggleGroup } from "../../../../v3";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { faFileCheck, faGears } from "@fortawesome/pro-light-svg-icons";
import { Direction, Upload, UploadFileForm } from "@sumit-platforms/types";

interface Props {
  uploadState: UploadFileForm;
  onUploadsUpdate: UpdateClientUploadsSettings;
  dir?: Direction;
  label?: string;
}

export const AutoTypeGroup = ({
  onUploadsUpdate,
  uploadState,
  label,
  dir,
}: Props) => {
  const { t } = useTranslation();
  const deliveryTypeButtonsGroup = useMemo(() => {
    return [
      {
        label: t("automatic"),
        subTitle: t("automatic_subtitle"),
        id: "autoTranscript",
        icon: faGears,
        style: { width: "280px" },
      },
      {
        label: t("sumit_ai_professional_team"),
        subTitle: t("sumit_ai_professional_team_subtitle"),
        id: "humanTranscript",
        icon: faFileCheck,
        style: { width: "300px" },
      },
    ] as CardItem[];
  }, [t]);

  const onTabChange = useCallback((tab: string) => {
    const value = tab === "autoTranscript";

    onUploadsUpdate({
      data: value,
      field: "jobType.autoTranscript" as keyof Upload,
    });
  }, []);

  const selectedTab = uploadState.jobType?.autoTranscript
    ? "autoTranscript"
    : "humanTranscript";

  return (
    <>
      <CardToggleGroup
        items={deliveryTypeButtonsGroup}
        selectedTab={selectedTab}
        onTabChange={onTabChange}
        cardSize={"large"}
        dir={dir}
        label={label}
      />
    </>
  );
};
