import React, { FC, useMemo, useState } from "react";
import classNames from "classnames";
import { Box, InputLabel } from "@mui/material";
import { PasswordReveal } from "../PasswordReveal/PasswordReveal";

import "./FormInput.scss";
import PhoneInput from "react-phone-input-2";
import { Controller } from "react-hook-form";

interface Props {
  className?: string;
  placeholder?: string;
  error?: string | null;
  register?: any;
  control?: any;
  autofocus?: boolean;
  disabled?: boolean;
  type?: "text" | "number" | "password" | "email" | "phone";
  floatActionElement?: JSX.Element;
  label?: string;
  validate?: (val: string) => true | string;
  requiredText?: string;
}

export const FormInput: FC<Props> = ({
  className,
  placeholder,
  error,
  register,
  autofocus,
  disabled = false,
  control,
  type = "text",
  floatActionElement,
  label,
  validate,
  requiredText,
}): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);

  const id = useMemo(() => {
    return label ? `formInput-${label}` : "";
  }, [label]);

  return (
    <Box
      mb={2}
      className={classNames("FormInput", className, { error: !!error })}
    >
      {label && (
        <InputLabel htmlFor={id} className="FormInputLabel">
          {label}
        </InputLabel>
      )}
      {floatActionElement && floatActionElement}
      {type === "phone" ? (
        <Controller
          name="phoneNumber"
          control={control}
          rules={{
            required: requiredText || "",
            validate: validate,
          }}
          render={({ field }) => (
            <PhoneInput
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-expect-error
              className={classNames("SignUpPhoneInput", { error: !!error })}
              country={"il"}
              value={field.value}
              onChange={(value: any) => {
                const nationalNumber = value?.number || value || "";
                const phone = nationalNumber?.startsWith("+")
                  ? nationalNumber
                  : `+${nationalNumber}`;
                field.onChange(phone);
              }}
            />
          )}
        />
      ) : (
        <input
          placeholder={placeholder}
          autoFocus={autofocus}
          disabled={disabled}
          type={showPassword ? "text" : type}
          id={id}
          {...register}
        />
      )}

      {type === "password" && (
        <PasswordReveal
          showPassword={showPassword}
          setShowPassword={setShowPassword}
        />
      )}
      {error && <div className="inputError">{error}</div>}
    </Box>
  );
};

export default FormInput;
