import React, { useCallback, useMemo } from "react";
import { Box, Button, Stack, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import classNames from "classnames";
import { Tooltip } from "../../../../index";
import { Direction } from "@sumit-platforms/types";

import "./CardsToggleGroup.scss";

export interface CardItem {
  id: string;
  label: string;
  subTitle?: string;
  icon: IconProp;
  tooltip?: string;
  style?: React.CSSProperties;
  hide?: boolean;
}

interface CardToggleGroupProps {
  items: CardItem[];
  selectedTab: string;
  onTabChange: (tabId: string) => void;
  className?: string;
  label?: string;
  cardSize?: "small" | "large" | { height: string; width: string };
  dir?: Direction;
}

export const CardToggleGroup = ({
  items = [],
  selectedTab,
  onTabChange,
  className,
  cardSize = "small",
  label,
  dir,
}: CardToggleGroupProps) => {
  const handleTabClick = useCallback((cardId: string) => {
    if (onTabChange) {
      onTabChange(cardId);
    }
  }, []);

  const cardDimentions = useMemo(() => {
    if (typeof cardSize === "object") {
      return cardSize;
    }
    const width = {
      small: "135px",
      large: "280px",
    };
    const height = {
      small: "80px",
      large: "100px",
    };
    return { width: width[cardSize], height: height[cardSize] };
  }, [cardSize]);

  return (
    <Box className={"BazarCardsToggleGroup"} dir={dir}>
      {label && (
        <Typography variant={"subtitle1"} mb={0.5}>
          {label}
        </Typography>
      )}
      <Stack
        direction="row"
        gap={2}
        className={classNames("cardsToggleGroupStack", className)}
        width={"100%"}
        dir={dir}
      >
        {items
          .filter((t) => !t.hide)
          .map((item) => {
            const selected = selectedTab === item.id;
            return (
              <React.Fragment key={item.id}>
                <Button
                  className={classNames("cardButton", {
                    selected,
                  })}
                  onClick={() => handleTabClick(item.id)}
                  sx={{
                    ...item.style,
                    width: cardDimentions.width,
                    height: cardDimentions.height,
                  }}
                >
                  {item.tooltip && (
                    <Tooltip title={item.tooltip}>
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className={classNames("tooltipInfoIcon", dir)}
                      />
                    </Tooltip>
                  )}
                  <FontAwesomeIcon
                    icon={item.icon}
                    size={"xl"}
                    className={classNames("cardButtonIcon")}
                  />
                  <Typography
                    variant="body2"
                    color={"text.secondary"}
                    textTransform={"none"}
                    fontWeight={selected ? 600 : 500}
                  >
                    {item.label}
                  </Typography>
                  {item.subTitle && (
                    <Typography
                      variant="body2"
                      color={"text.secondary"}
                      textTransform={"none"}
                      fontWeight={400}
                      fontSize={12}
                    >
                      {item.subTitle}
                    </Typography>
                  )}
                </Button>
              </React.Fragment>
            );
          })}
      </Stack>
    </Box>
  );
};
