import { MAX_GAP_FOR_MERGE, VIDEO_FORMATS } from "../constants/globals";
import { Job, Media, videoMimeTypes } from "@sumit-platforms/types";

export const loadMedia = (
  file: File,
  mediaType: "video" | "audio" = "video"
): Promise<{ duration: number } | null> =>
  new Promise((resolve, reject) => {
    try {
      const media = document.createElement(mediaType);
      media.preload = "metadata";
      media.onloadedmetadata = function () {
        if (media.duration === Infinity) {
          media.currentTime = 1e101;
          media.addEventListener("timeupdate", function getDuration() {
            if (media.duration !== Infinity) {
              media.removeEventListener("timeupdate", getDuration);
              media.remove();
              resolve(media);
            }
          });
        } else {
          media.remove();
          resolve(media);
        }
      };
      media.onerror = function () {
        resolve(null);
      };
      media.src = window.URL.createObjectURL(file);
    } catch (e) {
      resolve(null);
    }
  });

export const checkMediaGap = (lengths: number[]): boolean =>
  Math.max(...lengths) - Math.min(...lengths) < MAX_GAP_FOR_MERGE;

export const isVideoFormat = (format: string) => VIDEO_FORMATS.includes(format);

export const isVideo = (mimetype: Media["mimetype"]) => {
  const isVideo = videoMimeTypes.includes(mimetype);
  return isVideo;
};

export const getMainMedia = (medias?: Media[]) => {
  if (!medias?.length) {
    throw "Media not found";
  }
  const mainMedia = medias.find((media) => media.isMain);
  if (mainMedia) return mainMedia;

  const videoMedia = medias.find((media) => isVideo(media.mimetype));
  return mainMedia || videoMedia || medias[0];
};
