import { Languages } from "../constants/languages";
import { JobTypes } from "../entities/JobTypes";

export class STTConfigPage {
  sttModelId = "multilang_gen";
  splitSubtitles = false;
  priority?: number = SttPriority.DEFAULT;
  express?: boolean = false;
  constructor(args?: Partial<STTConfigPage>) {
    if (args && typeof args !== "undefined") {
      Object.assign(this, args);
    }
  }
}

export type SttModelProps = {
  sttModelId: string;
  splitSubtitles?: boolean;
  priority?: number;
  express?: boolean;
};

export enum SttPriority {
  DEFAULT = 0,
  LOW = 1,
  NORMAL = 2,
  HIGH = 3,
  VERY_HIGH = 4,
  URGENT = 5,
}

export type STTModel = Partial<Record<Languages, SttModelProps>>;

export type ClientSTT = Partial<Record<keyof JobTypes, STTModel>>;

// TODO use language table
export const defaultModelLangSettings = {
  sttModelId: "multilang_gen",
  splitSubtitles: false,
  priority: SttPriority.DEFAULT,
  express: false,
};

const allModelLangSettings: STTModel = {
  "he-IL": defaultModelLangSettings,
  "ar-IL": defaultModelLangSettings,
  "en-US": defaultModelLangSettings,
  "es-ES": defaultModelLangSettings,
  "fr-FR": defaultModelLangSettings,
  "hi-IN": defaultModelLangSettings,
  "pt-BR": defaultModelLangSettings,
  "ru-RU": defaultModelLangSettings,
  "de-DE": defaultModelLangSettings,
  "pt-PT": defaultModelLangSettings,
  "zh-CN": defaultModelLangSettings,
  "cs-CZ": defaultModelLangSettings,
  "nl-NL": defaultModelLangSettings,
  "fi-FI": defaultModelLangSettings,
  "id-ID": defaultModelLangSettings,
  "it-IT": defaultModelLangSettings,
  "ja-JP": defaultModelLangSettings,
  "ko-KR": defaultModelLangSettings,
  "ms-MY": defaultModelLangSettings,
  "no-NO": defaultModelLangSettings,
  "pl-PL": defaultModelLangSettings,
  "es-419": defaultModelLangSettings,
  "sv-SE": defaultModelLangSettings,
  "tr-TR": defaultModelLangSettings,
  "ro-RO": defaultModelLangSettings,
  "am-ET": defaultModelLangSettings,
  "uk-UA": defaultModelLangSettings,
};

const defaultEntertainmentSTTModel: STTModel = {
  ...allModelLangSettings,
  "he-IL": {
    sttModelId: "he_gen_v4",
    splitSubtitles: false,
    priority: SttPriority.DEFAULT,
    express: false,
  },
};

const defaultLegalSTTModel: STTModel = {
  ...allModelLangSettings,
  "he-IL": {
    sttModelId: "hg2_legal",
    splitSubtitles: false,
    priority: SttPriority.DEFAULT,
    express: false,
  },
};

export const defaultClientSttSettings: ClientSTT = {
  protocol: defaultLegalSTTModel,
  interview: defaultEntertainmentSTTModel,
  subtitles: defaultEntertainmentSTTModel,
  brief: defaultEntertainmentSTTModel,
};

export const sttPriorityOptions = [
  SttPriority.URGENT,
  SttPriority.VERY_HIGH,
  SttPriority.HIGH,
  SttPriority.NORMAL,
  SttPriority.LOW,
  SttPriority.DEFAULT,
];
