import React from "react";
import Box from "@mui/material/Box";
import { UploadRow } from "./UploadRow";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { ActiveUpload, Upload, UploadProgress } from "@sumit-platforms/types";

interface Props {
  key: string | number;
  id: string | number;
  upload: Upload | ActiveUpload;
  handleRemoveUpload: (upload: Upload) => Promise<void>;
  progress?: UploadProgress | null;
  onUploadNameEdit: (text: string, idUpload: number) => void;
  onOrderChange?: (uploads: (Upload | ActiveUpload)[]) => void;
  loading: boolean;
  isGlobalTranscriptAttached: boolean;
  isGlobalAafAttached: boolean;
  showReorderingHint: boolean;
  disableDrag: boolean;
}

export const SortableUploadRow = ({
  upload,
  onUploadNameEdit,
  handleRemoveUpload,
  progress,
  loading,
  isGlobalTranscriptAttached,
  isGlobalAafAttached,
  id,
  showReorderingHint,
  disableDrag,
}: Props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id, disabled: disableDrag });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Box style={style}>
      <UploadRow
        isDragging={isDragging}
        showReorderingHint={showReorderingHint}
        upload={upload}
        onUploadNameEdit={onUploadNameEdit}
        handleRemoveUpload={handleRemoveUpload}
        progress={progress}
        loading={loading}
        isGlobalTranscriptAttached={isGlobalTranscriptAttached}
        isGlobalAafAttached={isGlobalAafAttached}
        dragHandleProps={{
          ref: setNodeRef,
          ...attributes,
          ...listeners,
        }}
      />
    </Box>
  );
};
