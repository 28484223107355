import React, { useMemo } from "react";
import { SelectOption } from "./Select";
import { useTranslation } from "react-i18next";
import { Tooltip } from "../../../../index";

interface Props {
  options: SelectOption[];
  values: (string | number)[];
  numberLabelsToRender?: number;
}

export const SelectValueLabels = ({
  options,
  values,
  numberLabelsToRender = 2,
}: Props) => {
  if (!values) return null;
  const { t } = useTranslation();

  const selectedLabels = useMemo(() => {
    const optionsString = options
      ?.filter((op) => values.includes(op.value))
      ?.map((o) => o.label);

    return optionsString;
  }, [options, values]);

  const labelsToDisplay = useMemo(() => {
    const optionsString = selectedLabels?.slice(0, numberLabelsToRender);

    return optionsString;
  }, [selectedLabels]);

  const plusMoreSuffix = useMemo(() => {
    if (values.length > 2) {
      return `, +${values.length - 2}`;
    }
    return "";
  }, [t, values]);

  return (
    <>
      {labelsToDisplay.map((option, index) => (
        <span key={option}>
          {index > 0 && ", "}
          {option}
        </span>
      ))}
      {plusMoreSuffix && (
        <Tooltip title={selectedLabels.join(", ")}>
          <span>{plusMoreSuffix}</span>
        </Tooltip>
      )}
    </>
  );
};
