import { AuthMap, UserRole } from "@sumit-platforms/types";

const all = [UserRole.ADMIN, UserRole.CLIENT_USER, UserRole.CLIENT_ADMIN];
export const authMap: AuthMap = {
  "/": {
    roles: all,
  },
  "upload-files": {
    roles: all,
  },
  extract: {
    roles: all,
  },
  extract2: {
    roles: all,
  },
  projects: {
    roles: all,
  },
  record: {
    roles: all,
  },
};
