import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { AutoTypeGroup } from "./AutoDeliveryGroup";
import { Direction, UploadFileForm } from "@sumit-platforms/types";
import type { UpdateClientUploadsSettings } from "../NewUploads";
import { useTranslation } from "react-i18next";
import React from "react";

interface SubmissionContentProps {
  isSubmitLoading: boolean;
  isUploadSuccess: boolean;
  uploadState: UploadFileForm;
  onUploadsUpdate: UpdateClientUploadsSettings;
  uploadError?: { message?: string };
  dir?: Direction;
}

export const SubmissionContent = ({
  isSubmitLoading,
  isUploadSuccess,
  uploadState,
  onUploadsUpdate,
  uploadError,
  dir,
}: SubmissionContentProps) => {
  const { t } = useTranslation();

  if (!isSubmitLoading && !isUploadSuccess && !uploadError?.message) {
    return (
      <AutoTypeGroup
        onUploadsUpdate={onUploadsUpdate}
        uploadState={uploadState}
        dir={dir}
      />
    );
  }

  return (
    <Box>
      <Typography variant="h6" color="text.primary" fontWeight={550}>
        {isSubmitLoading
          ? t("uploading_your_files")
          : uploadError?.message
          ? t("fail_to_upload")
          : t("upload_files_success")}
      </Typography>
      {isUploadSuccess && (
        <Typography variant="body2" color="text.secondary">
          {t("upload_files_success_subtitle")}
        </Typography>
      )}
      {uploadError?.message && (
        <Typography variant="body2" color="text.secondary">
          {uploadError?.message}
        </Typography>
      )}
    </Box>
  );
};
