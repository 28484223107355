import React, { useCallback } from "react";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import Input from "../../../../../core/Input/Input";
import { recordStore } from "../../../../../../store";
import { Direction } from "@sumit-platforms/types";

export const SaveRecordInput = ({ dir }: { dir: Direction }) => {
  const { t } = useTranslation();
  const { uploadSettings, updateUploadSettings } = recordStore();

  const onInputChange = useCallback(
    (e: any) => {
      const name = e.target.value;
      updateUploadSettings({
        data: name,
        field: "name",
      });
    },
    [updateUploadSettings]
  );
  return (
    <Box className={"saveRecord"} dir={dir}>
      <Typography variant={"h3"} pb={1}>
        {t("save_record")}:
      </Typography>
      <Typography variant={"subtitle1"} py={0.5}>
        {t("save_record_subtitle")}
      </Typography>
      <Box py={2}>
        <Input
          label={`${t("name")}:`}
          value={uploadSettings.name}
          onChange={onInputChange}
          autoDir
        />
      </Box>
    </Box>
  );
};
