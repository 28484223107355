import React, { useState, useCallback, useMemo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, ClickAwayListener, Fab } from "@mui/material";
import { Direction } from "@sumit-platforms/types";
import classNames from "classnames";
import { recordStore } from "../../../../store";
import { useShallow } from "zustand/react/shallow";
import { faMicrophone } from "@fortawesome/pro-light-svg-icons";
import { RecordPopper } from "./RecordPopper";

import "./RecordBackgroundProcess.scss";

interface Props {
  recordUtils: any;
  dir: Direction;
}

export const RecordBackgroundProcess = ({ recordUtils, dir }: Props) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { isPaused } = recordStore(
    useShallow((state) => ({
      isPaused: state.isPaused,
    }))
  );

  const togglePopper = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen((previousOpen) => !previousOpen);
  }, []);

  const closePopper = useCallback(() => {
    if (anchorEl || open) {
      setAnchorEl(null);
      setOpen(false);
    }
  }, [anchorEl, open]);

  const canBeOpen = useMemo(() => open && Boolean(anchorEl), [open, anchorEl]);
  const id = useMemo(
    () => (canBeOpen ? "transition-popper" : undefined),
    [canBeOpen]
  );

  return (
    <ClickAwayListener onClickAway={closePopper}>
      <Box className={"RecordBackgroundProcessWrapper"}>
        <Fab
          className={classNames("BackgroundProcess", dir)}
          onClick={togglePopper}
          aria-describedby={id}
        >
          <FontAwesomeIcon
            icon={faMicrophone}
            size={"2x"}
            className={"BackgroundProcessIcon"}
          />
          <Box className={classNames("modeTip", dir, { isPaused })}></Box>
        </Fab>
        <RecordPopper
          id={id}
          open={open}
          anchorEl={anchorEl}
          recordUtils={recordUtils}
          dir={dir}
        />
      </Box>
    </ClickAwayListener>
  );
};
