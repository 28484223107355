import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Tooltip } from "../Tooltip/Tooltip";

import "./InfoTooltipIcon.scss";

interface Props {
  tooltipText?: string;
  icon?: IconProp;
  children?: React.ReactNode;
  size?: SizeProp;
}

export const InfoTooltipIcon = ({
  tooltipText,
  children,
  icon = faInfoCircle,
  size,
}: Props) => {
  return tooltipText?.length ? (
    <div className="InfoTooltipIconWrapper">
      {children}
      <Tooltip title={tooltipText}>
        <FontAwesomeIcon
          className={"InfoTooltipIcon"}
          icon={icon}
          size={size}
        />
      </Tooltip>
    </div>
  ) : (
    children
  );
};
