enum JobStatus {
  pending = "PENDING", // processing
  stt = "STT", // processing
  ready = "READY", // in work
  transcribe = "TRANSCRIBE", // in work
  pending_review = "PENDING_REVIEW", // in work
  review = "REVIEW", // under review

  aligning = "ALIGNING",
  splitting = "SPLITTING",

  pending_source_split = "PENDING_SOURCE_SPLIT", // job is pending to source job to be splitted
  pending_source_transcription = "PENDING_SOURCE_TRANSCRIPTION", // job is pending to source job to be manually transcript after auto stt rejection
  pending_splits_transcription = "PENDING_SPLITS_TRANSCRIPTION", // job is pending to child splits to finish

  done = "DONE",
  archive = "ARCHIVE",

  kit_recording = "KIT_RECORDING",
  stt_fail = "STT_FAIL",
  kit_fail = "KIT_FAIL",

  create_wav_fail = "CREATE_WAV_FAIL",
  import_source_fail = "IMPORT_SOURCE_FAIL",

  inactive = "INACTIVE",
  deleted = "DELETED",
}

enum UploadStatus {
  success = "SUCCESS",
  attaching_files = "ATTACHING_FILES",
  pending = "PENDING",
  uploading = "UPLOADING",
  error = "ERROR",
  canceled = "CANCELED",
  merging = "MERGING",
  mergeSuccess = "MERGE_SUCCESS",
  mergeFail = "MERGE_FAIL",
  convert = "CONVERT",
  convertFail = "CONVERT_FAIL",
}

enum FileStatus {
  resize = "RESIZE",
}

enum JobTranslationType {
  SOURCE_HIDDEN = "SOURCE_HIDDEN", //The source job is hidden. --NOT VISIBLE--
  SOURCE_FOR_SEED = "SOURCE_FOR_SEED", //The source job to work on before sending it to translation. --VISIBLE IN OPERA AND NOT VISIBLE IN MUST--
  SOURCE_REQUESTED = "SOURCE_REQUESTED", //The source job which the client requested to get access to. --VISIBLE IN OPERA AND MUST--
  TARGET = "TARGET", //The target job to translate to. --VISIBLE IN OPERA AND MUST--
}

type StatusPercents = { status: JobStatus; percents: number; duration: number };

const ResolvedJobStatuses = [JobStatus.done, JobStatus.archive];

const RerunJobStatuses = [
  JobStatus.done,
  JobStatus.ready,
  JobStatus.transcribe,
  JobStatus.archive,
  JobStatus.stt_fail,
];

export type { StatusPercents };
export {
  JobStatus,
  UploadStatus,
  FileStatus,
  JobTranslationType,
  ResolvedJobStatuses,
  RerunJobStatuses,
};
