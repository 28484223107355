import _ from "lodash";
import { CallbackDoc } from "react-google-drive-picker/dist/typeDefs";
import {
  AssignmentTask,
  InvoiceStatus,
  Job,
  JobAutoTranscriptDeliveryOptions,
  JobSplitType,
  JobStatus,
  JobTranslation,
  JobTranslationType,
  jobTypesOptions,
  JobWithData,
  MediaProvider,
  MyAssignment,
  Platform,
  ResolvedJobStatuses,
  TaskStatus,
  UserStatus,
} from "@sumit-platforms/types";
import {
  activeColor,
  aligningColor,
  archiveColor,
  atWorkColor,
  b2bProcessingColor,
  disabledColor,
  doneColor,
  kitRecordingColor,
  newMemberColor,
  noStatusColor,
  openColor,
  pendingColor,
  pendingReviewColor,
  processingColor,
  qualityCheckColor,
  rejectedColor,
  reviewingColor,
  splittingColor,
  trainingColor,
  workingOnItColor,
} from "./../constants/colors";
import { warningColor } from "../bazar-theme";
import { emailRegex } from "./regex";

export const getOperaStatusVisual = ({
  status,
  isTrainingUser,
}: {
  status: JobStatus;
  isTrainingUser?: boolean;
}): { statusColor: string; statusTitle: string } => {
  switch (status) {
    case JobStatus.pending:
    case JobStatus.pending_source_transcription:
    case JobStatus.pending_splits_transcription:
    case JobStatus.pending_source_split:
      return { statusColor: pendingColor, statusTitle: "pending" };
    case JobStatus.stt_fail:
      return { statusColor: warningColor, statusTitle: "stt_failed" };
    case JobStatus.stt:
      return { statusColor: processingColor, statusTitle: "processing" };
    case JobStatus.aligning:
      return { statusColor: aligningColor, statusTitle: "aligning" };
    case JobStatus.splitting:
      return { statusColor: splittingColor, statusTitle: "splitting" };
    case JobStatus.kit_recording:
      return { statusColor: kitRecordingColor, statusTitle: "kit_recording" };
    case JobStatus.ready: {
      return { statusColor: openColor, statusTitle: "open" };
    }
    case JobStatus.transcribe:
      return { statusColor: atWorkColor, statusTitle: "at_work" };
    case JobStatus.pending_review: {
      if (isTrainingUser) {
        return { statusColor: reviewingColor, statusTitle: "waiting_client" };
      } else {
        return {
          statusColor: pendingReviewColor,
          statusTitle: "pending_review",
        };
      }
    }
    case JobStatus.review: {
      if (isTrainingUser) {
        return { statusColor: reviewingColor, statusTitle: "waiting_client" };
      } else {
        return {
          statusColor: reviewingColor,
          statusTitle: "reviewing",
        };
      }
    }
    case JobStatus.done: {
      return { statusColor: doneColor, statusTitle: "done" };
    }
    case JobStatus.archive: {
      return { statusColor: archiveColor, statusTitle: "archive" };
    }
    default:
      return { statusColor: noStatusColor, statusTitle: "no_status" };
  }
};

export const getMustStatusVisual = (
  status: JobStatus
): { statusColor: string; statusTitle: string } => {
  switch (status) {
    case JobStatus.pending:
    case JobStatus.pending_source_split:
    case JobStatus.stt:
    case JobStatus.stt_fail:
      return {
        statusTitle: "processing",
        statusColor: b2bProcessingColor,
      };
    case JobStatus.ready:
    case JobStatus.transcribe:
    case JobStatus.pending_source_transcription:
    case JobStatus.pending_splits_transcription:
    case JobStatus.aligning:
    case JobStatus.splitting:
      return {
        statusTitle: "working_on_it",
        statusColor: workingOnItColor,
      };
    case JobStatus.review:
    case JobStatus.pending_review:
      return {
        statusTitle: "quality_check",
        statusColor: reviewingColor,
      };
    case JobStatus.kit_recording:
      return { statusColor: kitRecordingColor, statusTitle: "kit_recording" };
    case JobStatus.archive:
    case JobStatus.done:
      return { statusColor: doneColor, statusTitle: "done" };
    default:
      return { statusColor: b2bProcessingColor, statusTitle: "no_status" };
  }
};

export const getJobStatusVisual = (
  status: JobStatus
): { statusColor: string; statusTitle: string } => {
  switch (status) {
    case JobStatus.ready:
      return { statusColor: openColor, statusTitle: "open" };
    case JobStatus.transcribe:
      return { statusColor: atWorkColor, statusTitle: "at_work" };
    case JobStatus.pending_review:
      return { statusColor: reviewingColor, statusTitle: "pending_review" };
    case JobStatus.aligning:
      return { statusColor: aligningColor, statusTitle: "aligning" };
    case JobStatus.splitting:
      return { statusColor: splittingColor, statusTitle: "splitting" };
    case JobStatus.review:
      return { statusColor: reviewingColor, statusTitle: "reviewing" };
    case JobStatus.done:
      return { statusColor: doneColor, statusTitle: "done" };
    case JobStatus.archive:
      return { statusColor: doneColor, statusTitle: "archive" };
    default: {
      return { statusColor: noStatusColor, statusTitle: "no_status" };
    }
  }
};

export const getUserStatusVisual = (
  status: UserStatus
): { statusColor: string; statusTitle: string } => {
  switch (status) {
    case UserStatus.ACTIVE:
      return { statusColor: activeColor, statusTitle: "active" };
    case UserStatus.TRAINING:
      return { statusColor: trainingColor, statusTitle: "training" };
    case UserStatus.NEW_MEMBER:
      return { statusColor: newMemberColor, statusTitle: "new_member" };
    case UserStatus.DISABLED:
      return { statusColor: disabledColor, statusTitle: "disabled" };
    case UserStatus.REJECTED:
      return { statusColor: rejectedColor, statusTitle: "rejected" };
    default: {
      return { statusColor: noStatusColor, statusTitle: "no_status" };
    }
  }
};
export const getInvoiceStatusVisual = (
  status: InvoiceStatus
): { statusColor: string; statusTitle: string } => {
  switch (status) {
    case InvoiceStatus.AWAITING: {
      return { statusColor: pendingColor, statusTitle: "awaiting" };
    }
    case InvoiceStatus.PAID: {
      return { statusColor: doneColor, statusTitle: "paid" };
    }
    case InvoiceStatus.WRITE_OFF: {
      return { statusColor: kitRecordingColor, statusTitle: "write_off" };
    }
    default: {
      return { statusColor: noStatusColor, statusTitle: "no_status" };
    }
  }
};

export const manageMustJobStatusesForQuery = (statuses?: JobStatus[]) => {
  if (
    statuses?.includes(JobStatus.stt) ||
    statuses?.includes(JobStatus.stt_fail) ||
    statuses?.includes(JobStatus.pending) ||
    statuses?.includes(JobStatus.pending_source_split)
  ) {
    statuses = _.uniq([
      ...statuses,
      JobStatus.stt,
      JobStatus.stt_fail,
      JobStatus.pending,
      JobStatus.pending_source_split,
    ]);
  }

  if (
    statuses?.includes(JobStatus.ready) ||
    statuses?.includes(JobStatus.transcribe) ||
    statuses?.includes(JobStatus.pending_source_transcription) ||
    statuses?.includes(JobStatus.pending_splits_transcription) ||
    statuses?.includes(JobStatus.aligning) ||
    statuses?.includes(JobStatus.splitting)
  ) {
    statuses = _.uniq([
      ...statuses,
      JobStatus.ready,
      JobStatus.transcribe,
      JobStatus.pending_source_transcription,
      JobStatus.pending_splits_transcription,
      JobStatus.aligning,
      JobStatus.splitting,
    ]);
  }

  if (
    statuses?.includes(JobStatus.review) ||
    statuses?.includes(JobStatus.pending_review)
  ) {
    statuses = _.uniq([
      ...statuses,
      JobStatus.review,
      JobStatus.pending_review,
    ]);
  }

  if (
    statuses?.includes(JobStatus.archive) ||
    statuses?.includes(JobStatus.done)
  ) {
    statuses = _.uniq([...statuses, JobStatus.archive, JobStatus.done]);
  }
  return statuses;
};

export const manageOperaJobStatusesForQuery = (statuses?: JobStatus[]) => {
  if (
    statuses?.includes(JobStatus.stt) ||
    statuses?.includes(JobStatus.stt_fail)
  ) {
    statuses = _.uniq([...statuses, JobStatus.stt, JobStatus.stt_fail]);
  }
  if (
    statuses?.includes(JobStatus.pending) ||
    statuses?.includes(JobStatus.pending_source_split) ||
    statuses?.includes(JobStatus.pending_source_transcription) ||
    statuses?.includes(JobStatus.pending_splits_transcription)
  ) {
    statuses = _.uniq([
      ...statuses,
      JobStatus.pending,
      JobStatus.pending_source_split,
      JobStatus.pending_source_transcription,
      JobStatus.pending_splits_transcription,
    ]);
  }
  return statuses;
};

export const getMediaProviderByFile = (file: File | CallbackDoc) => {
  if ("id" in file) {
    return MediaProvider.googleDrive;
  }
  return MediaProvider.fileInput;
};

export const isTranscribeDone = (job: Job) => {
  return job.status === JobStatus.pending_review;
};

export const getTaskByJobProgress = (job: Job) => {
  return isTranscribeDone(job) ? AssignmentTask.qc : AssignmentTask.transcriber;
};

export const isJobEditorAccessible = (
  status: JobStatus,
  jobTranslation?: JobTranslation
) => {
  const statusValidation = ![
    JobStatus.stt,
    JobStatus.stt_fail,
    JobStatus.pending,
    JobStatus.pending_splits_transcription,
    JobStatus.splitting,
  ].includes(status);
  if (status === JobStatus.pending_source_transcription) {
    return jobTranslation?.autoSttRetake !== null;
  } else {
    return statusValidation;
  }
};

export const isJobEditorAccessibleForClient = (status: JobStatus) => {
  return [JobStatus.done, JobStatus.archive, JobStatus.kit_recording].includes(
    status
  );
};

export const isJobReadyForQc = (assignmentRequest: MyAssignment) => {
  return (
    assignmentRequest.assignment.task === AssignmentTask.qc &&
    assignmentRequest.job.status === JobStatus.pending_review
  );
};

export const isJobReadyForTranscribe = (assignmentRequest: MyAssignment) => {
  return (
    assignmentRequest.assignment.task === AssignmentTask.transcriber &&
    assignmentRequest.job.status === JobStatus.ready
  );
};

export const isUnassignAllowed = ({
  jobStatus,
  task,
  taskStatus,
}: {
  jobStatus?: JobStatus;
  task: AssignmentTask;
  taskStatus: TaskStatus;
}) => {
  if (!jobStatus) return false;
  //CM is not actively working on job
  if (taskStatus === TaskStatus.pending) return true;

  //CM is a working transcriber and the job is in Transcribe
  if (jobStatus === JobStatus.transcribe && task === AssignmentTask.transcriber)
    return true;

  //CM is a working qc and the job is in Review
  if (jobStatus === JobStatus.review && task === AssignmentTask.qc) return true;
  return false;
};

export const isTranslationTarget = (job?: Job) =>
  job?.translation === JobTranslationType.TARGET || false;

export const getJobTypesOption = (
  job: JobWithData | null,
  platform?: Platform
) => {
  if (!job) return null;
  let typeName = job.type.typeName;
  const isSplittedTranslationTarget =
    typeName === "subtitles" && isTranslationTarget(job.jobSplit?.sourceJob);
  if (
    (platform !== Platform.MUST && isTranslationTarget(job)) ||
    isSplittedTranslationTarget
  ) {
    typeName =
      job.type.typeName === "subtitles" ? "subtitlesTranslation" : typeName;
  }
  return jobTypesOptions[typeName || "subtitles"];
};

export const hasCommonItem = (arrayA: any[], arrayB: any[]) => {
  const intersection = _.intersection(arrayA, arrayB);
  return intersection.length > 0;
};

export const freeze = (timeInMilliseconds: number) =>
  new Promise((resolve: any) => {
    setTimeout(() => {
      resolve();
    }, timeInMilliseconds);
  });

export const isJobReadyForTranslation = (job: Job): boolean => {
  const isDone = ResolvedJobStatuses.includes(job.status);
  const isSplit = job.splitType === JobSplitType.SPLIT;
  return isDone && !isSplit;
};

export const isJobReadyForSummarizeOperation = (
  job?: Job | JobWithData | null
) => {
  if (!job) return;
  const jobTypeAllowed = ["protocol", "interview", "brief"].includes(
    job.type.typeName
  );
  const statusAllowed = [
    JobStatus.done,
    JobStatus.ready,
    JobStatus.transcribe,
    JobStatus.review,
    JobStatus.pending_review,
  ].includes(job.status);
  return jobTypeAllowed && statusAllowed;
};

export const isJobReadyForSummarizeClients = (job: Job) => {
  const jobTypeAllowed = ["protocol", "interview"].includes(job.type.typeName);
  const statusAllowed = [JobStatus.done].includes(job.status);
  return jobTypeAllowed && statusAllowed;
};

export const getInitials = (text: string) => {
  const words = text.split(/[\s-]+/);
  if (words.length === 1) {
    return words[0].charAt(0).toUpperCase();
  }

  return (words[0].charAt(0) + words[words.length - 1].charAt(0)).toUpperCase();
};

export const validateEmail = (email: string): boolean => {
  if (!email || typeof email !== "string") {
    return false;
  }
  const trimmedEmail = email.trim().toLowerCase();

  const isValid = emailRegex.test(trimmedEmail);
  return isValid;
};
