import React, { useCallback, useMemo } from "react";
import { indexDB } from "@sumit-platforms/ui-bazar/services";
import { useLiveQuery } from "dexie-react-hooks";
import { List, ListItemProps } from "@sumit-platforms/ui-bazar/v3";
import { faSave, faTrash } from "@fortawesome/pro-light-svg-icons";
import { neutralsN1, neutralsN6 } from "@sumit-platforms/ui-bazar";
import { useTranslation } from "react-i18next";
import { Direction, RecordsIDBModel } from "@sumit-platforms/types";
import { secondsToTimeString } from "@sumit-platforms/ui-bazar/utils";
import _ from "lodash";

export const RecordsHistory = ({
  currentRecordId,
  onSaveIDBRecordClick,
  dir,
}: {
  currentRecordId: string;
  onSaveIDBRecordClick: (record: RecordsIDBModel) => void;
  dir?: Direction;
}) => {
  const records = useLiveQuery(() => indexDB.records.toArray());
  const { t } = useTranslation();

  const removeRecordFromIDB = useCallback((record: RecordsIDBModel) => {
    indexDB.records.delete(record.id);
  }, []);

  const saveRecordFromIDB = useCallback(
    (record: RecordsIDBModel) => {
      onSaveIDBRecordClick(record);
    },
    [onSaveIDBRecordClick]
  );

  const listItems = useMemo(() => {
    const list: ListItemProps[] =
      records
        ?.filter((r) => r.id !== currentRecordId)
        .map((record) => {
          return {
            actions: [
              {
                action: () => removeRecordFromIDB(record),
                icon: faTrash,
              },
              {
                action: () => saveRecordFromIDB(record),
                iconColor: neutralsN1,
                bgColor: neutralsN6,
                icon: faSave,
                closeOnClick: true,
              },
            ],
            description: _.isNumber(record.duration)
              ? secondsToTimeString(record.duration)
              : "",
            text: record.name,
          } as ListItemProps;
        }) || [];
    return list;
  }, [currentRecordId, records, removeRecordFromIDB, saveRecordFromIDB]);

  return listItems?.length ? (
    <List
      items={listItems}
      collapsible
      collapseTitle={t("record_history_list_title", {
        recordsNumber: listItems?.length,
      })}
      dir={dir}
    />
  ) : null;
};
