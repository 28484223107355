import React, { useCallback, useMemo } from "react";
import {
  ActiveUpload,
  PendingUpload,
  Upload,
  UploadProgress,
  UploadStatus,
} from "@sumit-platforms/types";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { EditableText, IconButton, ProgressBar } from "../../../../index";
import { useTranslation } from "react-i18next";
import { faGripDotsVertical, faTrash } from "@fortawesome/pro-light-svg-icons";
import { formatSecondsToDisplayString } from "../../../../utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  upload: Upload | ActiveUpload | PendingUpload;
  onUploadNameEdit: (text: string, idUpload: number) => void;
  handleRemoveUpload: (upload: Upload) => void;
  progress?: UploadProgress | null;
  loading: boolean;
  isGlobalTranscriptAttached: boolean;
  isGlobalAafAttached: boolean;
  showReorderingHint: boolean;
  isDragging: boolean;
  dragHandleProps?: any;
}

export const UploadRow = ({
  upload,
  onUploadNameEdit,
  progress,
  handleRemoveUpload,
  loading,
  isGlobalTranscriptAttached,
  isGlobalAafAttached,
  showReorderingHint,
  isDragging,
  dragHandleProps,
}: Props) => {
  const { t } = useTranslation();

  const getStatusString = useCallback((status: Upload["status"]) => {
    switch (status) {
      case UploadStatus.error: {
        return t("upload_failed");
      }
      case UploadStatus.convert: {
        return t("convert_media");
      }
      case UploadStatus.convertFail: {
        return t("upload_convert_failed");
      }
      case UploadStatus.canceled: {
        return t("upload_canceled");
      }
      default: {
        return "";
      }
    }
  }, []);

  const isError = useMemo(
    () => [UploadStatus.error].includes(upload.status),
    [upload.status]
  );
  const isCanceled = useMemo(
    () => [UploadStatus.canceled].includes(upload.status),
    [upload.status]
  );
  const isPending = useMemo(
    () => [UploadStatus.pending].includes(upload.status),
    [upload.status]
  );
  const isUploading = useMemo(
    () => [UploadStatus.uploading].includes(upload.status),
    [upload.status]
  );

  const isTranscriptAttached = useMemo(() => {
    return (upload as Upload)?.srcFile || isGlobalTranscriptAttached;
  }, [upload, isGlobalTranscriptAttached]);

  const isAafAttached = useMemo(() => {
    return (upload as Upload)?.settings?.aaf || isGlobalAafAttached;
  }, [upload, isGlobalAafAttached]);

  const handleOnUploadNameEdit = useCallback(
    (text: string) => {
      onUploadNameEdit(text, upload.idUpload as number);
    },
    [onUploadNameEdit]
  );

  return (
    <Box className={"UploadRow"} p={2}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        {showReorderingHint && (
          <Box
            className={"gripRowIcon"}
            p={0.5}
            mr={1.5}
            sx={{
              cursor: isDragging ? "grabbing" : "grab",
            }}
            ref={dragHandleProps.ref || null}
            {...dragHandleProps}
          >
            <FontAwesomeIcon icon={faGripDotsVertical} />
          </Box>
        )}
        <Box
          className="UploadRowText"
          display="flex"
          justifyContent="space-between"
          flexDirection={"column"}
          width={"100%"}
        >
          <Box
            display={"flex"}
            justifyContent="space-between"
            alignItems={"center"}
            width={"100%"}
          >
            <Box display={"flex"} alignItems={"center"} gap={1}>
              <EditableText
                text={upload.name}
                onSave={handleOnUploadNameEdit}
                disabled={upload.status === UploadStatus.uploading}
              />
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              gap={1}
              className={"progressAndStatusText"}
            >
              {upload.status !== UploadStatus.error && (
                <Typography
                  className={"progressString"}
                  variant="body2"
                  color={"text.secondary"}
                  sx={{ width: "fit-content" }}
                >
                  {progress?.progress && !isError && !isCanceled
                    ? `${Math.floor(progress.progress).toString()}%`
                    : isPending || isUploading
                    ? "0%"
                    : ""}
                </Typography>
              )}

              <Typography
                variant="body2"
                color={"text.secondary"}
                className={"uploadFailed"}
              >
                {getStatusString(upload.status)}
              </Typography>
            </Box>
          </Box>
          <Box
            className={"uploadSubtitle"}
            display={"flex"}
            alignItems={"center"}
          >
            {"media" in upload && upload.media?.length && (
              <Typography variant="body2">
                {formatSecondsToDisplayString(upload.media[0]?.duration)}
              </Typography>
            )}
            {isAafAttached && (
              <Typography ml={0.5} variant="body2">
                {(upload as any).media?.length
                  ? (upload as any).media[0]?.duration
                    ? "|"
                    : ""
                  : ""}{" "}
                {t("aaf_attached")}
              </Typography>
            )}
            {isTranscriptAttached && (
              <Typography ml={0.5} variant="body2">
                {(upload as any).media?.length
                  ? (upload as any).media[0]?.duration
                    ? "|"
                    : ""
                  : ""}{" "}
                {t("transcript_attached")}
              </Typography>
            )}
          </Box>
        </Box>
        <Box className="UploadRowActions">
          {handleRemoveUpload && !isError && (
            <IconButton
              icon={faTrash}
              onClick={handleRemoveUpload}
              disabled={loading}
              tooltipText={t("remove")}
            ></IconButton>
          )}
        </Box>
      </Box>
      <Box className={"UploadRowProgressBar"} mt={0.5}>
        {!isCanceled && (
          <ProgressBar
            error={upload.status === UploadStatus.error}
            progress={isPending ? 0.1 : progress?.progress}
          />
        )}
      </Box>
    </Box>
  );
};
