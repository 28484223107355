enum DiarizeTypes {
  UNSUPRTVISED = "unsupervised",
  BANK_DIARIZE = "bank_diarize",
  MULTICHANNEL = "multichannel",
}

interface Diarize {
  diarize?: DiarizeTypes;
  diarize_param?: {
    min_speakers?: number;
    max_speakers?: number;
    alg?: string;
    samples?: any[];
  };
}
export { DiarizeTypes };
export type { Diarize };
