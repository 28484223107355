import { useTranslation } from "react-i18next";
import {
  Button,
  IconButton,
  type UpdateClientUploadsSettings,
} from "../../../../../index";
import {
  aafExtensions,
  fileInputAccept,
  srtExtensions,
  UploadFileForm,
} from "@sumit-platforms/types";
import { ChangeEvent, useCallback, useRef } from "react";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMemoCircleInfo, faTrash } from "@fortawesome/pro-light-svg-icons";
import Typography from "@mui/material/Typography";

const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB in bytes

export const ImportAAFInput = ({
  onUploadsUpdate,
  uploadState,
}: {
  uploadState: UploadFileForm;
  onUploadsUpdate: UpdateClientUploadsSettings;
}) => {
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnFileChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0] || null;
      if (file) {
        if (file.size > MAX_FILE_SIZE) {
          if (inputRef.current) {
            inputRef.current.value = "";
          }
          return;
        }

        onUploadsUpdate({
          data: file,
          field: "tempAAFFile",
        });
      }
    },
    [onUploadsUpdate, t]
  );

  const handleClearFile = useCallback(async () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }

    onUploadsUpdate({
      data: null,
      field: "tempAAFFile",
    });
  }, [onUploadsUpdate]);

  const browseFile = useCallback(() => {
    inputRef.current?.click();
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={1}
      className={"ImportTranscriptionInput"}
      width={"100%"}
      py={2}
    >
      <>
        <Typography variant={"subtitle2"}>
          {t("attach_aaf")} ({t("optional")}):
        </Typography>
        <input
          className="hiddenInput"
          ref={inputRef}
          accept={fileInputAccept(aafExtensions)}
          type="file"
          onChange={handleOnFileChange}
          style={{ display: "none" }}
        />
        <Box display="flex" alignItems="center" gap={2}>
          <Button
            className={"importAafFileInputButton"}
            sx={{ width: "fit-content" }}
            variant="outlined"
            onClick={browseFile}
            startIcon={<FontAwesomeIcon icon={faMemoCircleInfo} />}
          >
            <Typography variant={"body2"}>{t("attach_aaf")}</Typography>
          </Button>
          {uploadState?.tempAAFFile && (
            <>
              <Box component="span">{uploadState?.tempAAFFile?.name}</Box>
              <IconButton
                onClick={handleClearFile}
                tooltipText={t("remove_uploaded_aaf")}
                icon={faTrash}
                size="small"
              />
            </>
          )}
        </Box>
      </>
    </Box>
  );
};
