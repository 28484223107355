import React, { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useTags } from "../../../../hooks";
import { tagService } from "../../../../services";
import { Direction, Tag } from "@sumit-platforms/types";
import { Select, SelectOption } from "../../../../v3";
import { SelectOptionItem } from "../Select/SelectOptionItem";

interface Props {
  config: any;
  value: Tag[];
  onTagChange: (newTags: Tag[]) => void;
  onTagCreate: (newTag: Tag) => void;
  idClient?: number;
  label?: string;
  placeholder?: string;
  dir?: Direction;
}

export const TagsSelectInput = ({
  config,
  value,
  onTagChange,
  onTagCreate,
  idClient,
  label,
  placeholder,
  dir,
}: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const tagsService = useMemo(
    () => tagService({ endpoint: config.server }),
    []
  );
  const {
    tags: _tags,
    submitTag,
    fetchAndUpdateTags,
  } = useTags({
    entity: "job",
    tagService: tagsService,
  });

  const _onTagChange = useCallback(
    (tags: (string | number)[]) => {
      const updatedTags = _tags.filter((tag) => tags.includes(tag.idTag));
      onTagChange(updatedTags);
    },
    [onTagChange, _tags]
  );

  const _onTagCreate = useCallback(
    async (tagData: SelectOption) => {
      const tag = Array.isArray(tagData) ? tagData[0] : tagData;
      setLoading(true);
      if (!idClient) return;
      const newTag = await submitTag(tag.value as string, idClient);
      if (newTag) {
        fetchAndUpdateTags();
        onTagCreate(newTag);
      }
      setLoading(false);
    },
    [onTagChange, submitTag]
  );

  const tagsOptions = useMemo(() => {
    return _tags.map((tag) => {
      return {
        value: tag.idTag,
        label: tag.name,
      };
    });
  }, [_tags]);

  const tagsSelected = useMemo(() => {
    return (
      value?.map((tag) => {
        return tag.idTag;
      }) || []
    );
  }, [value]);

  return (
    <Select
      className={"TagsPicker"}
      displayTags={true}
      options={tagsOptions}
      value={tagsSelected}
      onChange={_onTagChange}
      multiple={true}
      onNewValueTrigger={_onTagCreate}
      label={label || `${t("tags")}:`}
      placeholder={placeholder || t("add_tags")}
      loading={loading}
      dir={dir}
    />
  );
};
