import { type UpdateClientUploadsSettings } from "../../../../../index";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import Box from "@mui/material/Box";
import {
  Direction,
  UploadFileForm,
  ValidationPreset,
} from "@sumit-platforms/types";
import { Select } from "../../../../../v3";

interface Props {
  uploadState: UploadFileForm;
  onUploadsUpdate: UpdateClientUploadsSettings;
  validations: ValidationPreset[];
  dir?: Direction;
}

export const ValidationsInput = ({
  onUploadsUpdate,
  uploadState,
  validations,
  dir,
}: Props) => {
  const { t } = useTranslation();

  const validationsOptions = useMemo(
    () =>
      validations.map((v) => ({
        label: v?.settings?.general_lang?.presetName,
        value: v?.idValidationPreset,
      })),
    [validations]
  );

  const handleOnChange = useCallback(
    (idPreset: (number | string)[]) => {
      onUploadsUpdate({
        data: idPreset?.length ? (idPreset[0] as number) : null,
        field: "idValidationPreset",
      });
    },
    [onUploadsUpdate]
  );

  return (
    <Select
      options={validationsOptions}
      value={
        uploadState?.idValidationPreset ? [uploadState.idValidationPreset] : []
      }
      onChange={handleOnChange}
      label={t("validations")}
      className={"ValidationsInputMultiselect"}
      placeholder={t("select_validation")}
      dir={dir}
      optional
    />
  );
};
