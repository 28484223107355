/* eslint @typescript-eslint/no-var-requires: "off" */
// line above is for ignoring no-require at line 24-27.
import _ from "lodash";

import {
  Annotation,
  EditorMode,
  Job,
  JobData,
  JobRange,
  JobTypes,
  SpeakerRange,
  SubtitlesRange,
  SubtitlesTranslationRange,
  ValidationsConfigData,
  Word,
} from "@sumit-platforms/types";

import {
  AnnotationRangeElement,
  CustomEditor,
  CustomElement,
  CustomLeafProperties,
  ElementType,
  MarkWordMetadata,
  SpeakerRangeElement,
  SubtitlePlaceholderRangeElement,
  SubtitleRangeElement,
} from "../types";
import rangeValidations from "../validations/rangeValidations";
import MediaService from "./MediaService";
import { generateId } from "../utils/generateId";

import * as Diff from "diff";
import {
  Descendant,
  Editor,
  Element,
  Location,
  MergeNodeOperation,
  Node,
  Path,
  Range,
  SplitNodeOperation,
  Text,
  Transforms,
} from "@sumit-platforms/slate";
import { ReactEditor } from "@sumit-platforms/slate-react";
import React from "react";
import { freeze } from "@sumit-platforms/ui-bazar/utils";
import TimeService from "./TimeService";
import { createEmptyRange, getWordsByRangeTimes } from "../utils/range";
import { BaseRange } from "@sumit-platforms/slate";

const getTempSpeakerName = (): string => {
  // const tempSpeakerName = i18n.t("unidentified_speaker");
  const tempSpeakerName = "unidentified_speaker";
  return `${tempSpeakerName}-${(Math.random() * 10000).toFixed(0)}`;
};

const getRangeWordsFromString = (
  rangeWords: Word[],
  newInputString: string,
  oldInputString: string,
  rangesCount: number
): Word[] => {
  const fullWordsArray = rangeWords.filter((i) => i.word !== "\n"); // Removing linebreaks
  const rangeNewWords = newInputString
    .trim()
    .split(" ")
    .filter((words) => words); // Removing whitespace
  const rangeOldWords = oldInputString
    .trim()
    .split(" ")
    .filter((word) => word); // Removing whitespace

  const lastNewWordIndex = rangeNewWords.length - 1;
  const lastOldWordIndex = rangeOldWords.length - 1;

  let startI = 0;
  while (
    rangeNewWords[startI] === rangeOldWords[startI] &&
    !!rangeNewWords[startI]
  ) {
    startI++;
  }
  let endI = 0;
  while (
    rangeNewWords[lastNewWordIndex - endI] ===
      rangeOldWords[lastOldWordIndex - endI] && // Last new word == last old word
    !!rangeNewWords[lastNewWordIndex - endI] && // Last new word exists
    lastOldWordIndex - endI > startI && // Current iteration last word is the last new word
    lastNewWordIndex - endI > startI
  ) {
    endI++;
  }

  let indexToCopy = startI;
  if (startI > lastOldWordIndex && indexToCopy > 0) indexToCopy--;

  const newChangedWords = calculateNewWordsTimes(
    rangeWords,
    rangeNewWords
  ).slice(startI, rangeNewWords.length - endI);

  const updatedRangeWords: Word[] = fullWordsArray.slice(0, startI);
  updatedRangeWords.push(...newChangedWords);
  let lastWord = fullWordsArray;
  lastWord = lastWord.slice(
    rangeOldWords.length - (endI === 0 && rangesCount === 0 ? -1 : endI)
  ); // When single speaker (rangesCount === 0) removing also last word (-1)
  updatedRangeWords.push(...lastWord);

  return updatedRangeWords;
};

const preventCut = (e: React.KeyboardEvent) => {
  // prevent cut from user
  e.preventDefault();
  e.stopPropagation();
  return;
};

const getRangeWordsFromMultilineString = (
  range: JobRange,
  newInputString: string,
  oldInputString: string,
  rangesCount: number
): Word[] => {
  const rangeWords = range.words || [];
  const fullWordsArray = rangeWords.filter((i) => i.word !== "\n"); // Removing linebreaks

  let wordCount = 0;
  const lineBreaks =
    newInputString
      ?.trim()
      .split("\n")
      .slice(0, -1)
      .map((line) => {
        const lineLength = line.trim().split(" ").length;
        wordCount = wordCount + lineLength;
        return wordCount;
      }) || "";
  lineBreaks.unshift(0);

  const rangeNewWords = newInputString
    .replace(/\n/g, " ")
    .trim()
    .split(" ")
    .filter((w) => w); // Removing whitespace
  const rangeOldWords = oldInputString
    .replace(/\n/g, " ")
    .trim()
    .split(" ")
    .filter((w) => w); // Removing whitespace

  const lastNewWordIndex = rangeNewWords.length - 1;
  const lastOldWordIndex = rangeOldWords.length - 1;

  let startI = 0;
  while (
    rangeNewWords[startI] === rangeOldWords[startI] &&
    !!rangeNewWords[startI]
  ) {
    startI++;
  }
  let endI = 0;
  while (
    rangeNewWords[lastNewWordIndex - endI] ===
      rangeOldWords[lastOldWordIndex - endI] && // Last new word == last old word
    !!rangeNewWords[lastNewWordIndex - endI] && // Last new word exists
    lastOldWordIndex - endI > startI && // Current iteration last word is the last new word
    lastNewWordIndex - endI > startI
  ) {
    endI++;
  }

  let indexToCopy = startI;
  if (startI > lastOldWordIndex && indexToCopy > 0) indexToCopy--;

  const newChangedWords = calculateNewWordsTimes(
    rangeWords,
    rangeNewWords,
    range
  ).slice(startI, rangeNewWords.length - endI);

  const updatedRangeWords: Word[] = fullWordsArray.slice(0, startI);
  updatedRangeWords.push(...newChangedWords);
  let lastWord = fullWordsArray;
  lastWord = lastWord.slice(
    rangeOldWords.length - (endI === 0 && rangesCount === 0 ? -1 : endI)
  ); // When single speaker (rangesCount === 0) removing also last word (-1)
  updatedRangeWords.push(...lastWord);

  // Add line index to words
  for (const [lineIndex, lineBreak] of lineBreaks.entries()) {
    const nextLineBreak = lineBreaks[lineIndex + 1]
      ? lineBreaks[lineIndex + 1]
      : updatedRangeWords.length;
    for (let i = lineBreak; i < nextLineBreak; i++) {
      // if (!_.isNumber(updatedRangeWords[i]?.line_ix)) continue;
      updatedRangeWords[i] = { ...updatedRangeWords[i], line_ix: lineIndex };
    }
  }

  return updatedRangeWords;
};

// const saveUserLastPosition = ({
//   jobId,
//   cursorPosition,
//   rangeIx,
//   playbackPosition,
//   scrollOffsetTop,
// }: {
//   jobId: string;
//   cursorPosition: number;
//   rangeIx: number;
//   playbackPosition: number;
//   scrollOffsetTop: number;
// }) => {
//   const newLastPosition = {
//     cursorPosition,
//     rangeIx,
//     playbackPosition,
//     scrollOffsetTop,
//   };
//   try {
//     localStorage.setItem(
//       `${jobId}/editorLastPosition`,
//       JSON.stringify(newLastPosition)
//     );
//   } catch (err) {
//     logger.error(err, "saveUserLastPosition");
//     clearLocalStorage({ preserveSettings: true });
//     saveUserLastPosition({ ...newLastPosition, jobId });
//   }
// };

const getLastPosition = (jobId: string | number) => {
  const defaultPosition = {
    cursorPosition: 0,
    rangeIx: 0,
    playbackPosition: 0,
    scrollOffsetTop: 0,
  };
  const lastPositionRaw = localStorage.getItem(`${jobId}/editorLastPosition`);
  const lastPosition = lastPositionRaw
    ? JSON.parse(lastPositionRaw)
    : defaultPosition;

  return lastPosition;
};

const calculateNewWordsTimes = (
  oldWords: Word[],
  newWords: string[],
  range?: JobRange
): Word[] => {
  const words = [];
  const wordsDiff = Diff.diffArrays(
    oldWords.map((w) => w.word),
    newWords
  );

  let wordIndex = 0;
  for (let i = 0; i < wordsDiff.length; i++) {
    const diff: any = wordsDiff[i];

    if (diff.removed) {
      if (!_.get(wordsDiff, `[${i + 1}].added`)) {
        wordIndex = wordIndex + diff.value.length;
      } else if (diff[i + 1]) {
        wordIndex = wordIndex + (diff.value.length - diff[i + 1].value.length);
      }
      continue;
    }

    for (const word of diff.value) {
      const oldWordObj =
        wordIndex > oldWords.length - 1
          ? _.last(oldWords)
          : oldWords[wordIndex];
      if (!oldWordObj) {
        console.error(
          "old word didnt found, it can harm on v3 editor. slate will be ok"
        );
        if (!range) {
          throw new Error("NOT GOOD!");
        }
      }
      const wordObj = {
        ...oldWordObj,
        id: generateId("w_"),
        speaker: oldWordObj?.speaker || null,
        word: word,
        text: word,
      };

      if (!diff.removed && !diff.added) {
        wordObj.start_time = oldWordObj?.start_time || range?.st;
        wordObj.end_time = oldWordObj?.end_time || range?.et;
        wordObj.range_ix = _.isNumber(oldWordObj?.range_ix)
          ? oldWordObj?.range_ix
          : -1;
        wordIndex++;
        words.push(wordObj);
      }

      if (diff.added) {
        wordObj.start_time = oldWordObj?.start_time || range?.st;
        wordObj.end_time = oldWordObj?.end_time || range?.et;
        wordObj.range_ix = _.isNumber(oldWordObj?.range_ix)
          ? oldWordObj?.range_ix
          : -1;
        wordIndex++;
        words.push(wordObj);
      }
    }
  }

  return words as Word[];
};

// const getSelectedWordsIndex = (
//   plainWords: string,
//   selectionStart: number,
//   selectionEnd: number
// ): { startWordIndex: number; endWordIndex: number } => {
//   const editedWords = plainWords.split(" ");
//   const lengths = editedWords.map((word) => word.length);

//   let start = selectionStart;

//   let startWordIndex = -1;
//   while (start > 0) {
//     startWordIndex++;
//     start = start - lengths[startWordIndex] - 1;
//   }
//   if (start === 0) startWordIndex++;

//   let end = selectionEnd;

//   let endWordIndex = -1;
//   while (end > 0) {
//     endWordIndex++;
//     end = end - lengths[endWordIndex] - 1;
//   }
//   if (end === 0) endWordIndex++;

//   return { startWordIndex, endWordIndex };
// };

const generateRangesByTimeIntervals = (
  words: Word[],
  interval: number
): number[] => {
  const intervalRanges = [0];
  let currentInterval = interval;
  for (let i = 0; i < words.length; i++) {
    const word = words[i];

    if (word.start_time <= currentInterval) {
      continue;
    }

    while (word.start_time >= currentInterval) {
      currentInterval = currentInterval + interval;
    }

    intervalRanges.push(i);
  }

  return intervalRanges;
};

const getWordsFromRanges = (ranges: JobRange[]) => {
  let undefindSpeakerCount = 1;
  const rangesWords = _.map(ranges, (r, i) => {
    const rangeSpeaker =
      r.speakerName || `Unidentified speaker ${undefindSpeakerCount}`;
    if (!r.speakerName) {
      undefindSpeakerCount++;
    }
    return _.map(r.words, (w) => ({
      ...w,
      range_ix: i,
      speaker: rangeSpeaker,
    }));
  });
  const words = _.flatten(rangesWords);
  return words;
};

const _getExportedRangeString = (
  words: Word[],
  injectSpeakerName: boolean,
  previousSpeaker: string | null
): string => {
  let currentSpeaker = previousSpeaker;
  return words
    .map((w, i) => {
      if (
        previousSpeaker &&
        injectSpeakerName &&
        w.speaker !== currentSpeaker
      ) {
        currentSpeaker = w.speaker || null;
        return `${i !== 0 ? "\r\n" : ""}${currentSpeaker}: ${w.word}`;
      } else {
        return w.word;
      }
    })
    .filter((w) => !!w && !!w.trim())
    .join(" ");
};

const reorderWordsRangeIndex = (words: Word[]): Word[] => {
  let runningIndex = 0;
  let currentIndex = words[0].range_ix;

  return words.map((word) => {
    if (word.range_ix > currentIndex) {
      currentIndex = word.range_ix;
      runningIndex++;
    }

    return { ...word, range_ix: runningIndex };
  });
};

const getJobLangKey = (type: keyof JobTypes): "input" | "output" => {
  const defaultLangKey = "output";
  const jobLangKey = defaultLangKey;
  // const jobLangKey = jobTypes[type].lang || defaultLangKey;
  return jobLangKey;
};

const getLangDirection = (lang: string[] | string): "rtl" | "ltr" => {
  const rtlLangs = ["he-IL", "iw-IL", "ar"];
  if (_.isArray(lang)) {
    return _.some(rtlLangs, (l) => lang[0].startsWith(l)) ? "rtl" : "ltr";
  } else {
    return _.some(rtlLangs, (l) => lang.startsWith(l)) ? "rtl" : "ltr";
  }
};

const getSpeakersFromWords = (words: Word[]): string[] => {
  return _.uniqBy(words, (word) => word.speaker).map(
    (word) => word.speaker || ""
  );
};

const getSplitMeetingWords = (
  words: Word[],
  wordIndex: number,
  wordCharIndex: number
): Word[] => {
  if (wordCharIndex === 0 || wordCharIndex === words[wordIndex].word.length)
    return _.clone(words);

  const updatedMeetingWords: Word[] = _.clone(words);
  const word = updatedMeetingWords[wordIndex];
  const newWord = _.clone(word);
  word.word = word.word.slice(0, wordCharIndex);
  newWord.word = newWord.word.slice(wordCharIndex, newWord.word.length);
  updatedMeetingWords.splice(wordIndex + 1, 0, newWord);

  return updatedMeetingWords;
};

const resetSubtitlesRanges = (words: Word[]) => {
  return _.map(words, (w) => ({ ...w, range_ix: 0 }));
};

const createNewSpeakerRange = ({
  words,
  st,
  et,
  speakerId = null,
  speakerName = null,
  speakerNameEdit,
  annotations = [],
  text_edit,
  time_edit,
}: {
  words: Word[];
  six?: number;
  eix?: number;
  st?: number;
  et?: number;
  speakerId?: null | string;
  speakerName?: null | string;
  speakerNameEdit?: boolean;
  annotations?: Annotation[];
  time_edit?: boolean;
  text_edit?: boolean;
}) => {
  const _startTime = st || words[0]?.start_time;
  const _endTime = et || words[words.length - 1]?.end_time;

  const startTime = _.isNumber(_startTime) ? _startTime : null;
  const endTime = _.isNumber(_endTime) ? _endTime : null;

  const newSpeakerRange = {
    id: generateId("r_"),
    six: 0,
    eix: 0,
    words: words,
    st: startTime,
    et: endTime,
    speakerName,
    speakerId,
    type: "speaker",
    annotations: annotations,
    time_edit,
    text_edit,
  } as SpeakerRange;
  if (speakerNameEdit) {
    newSpeakerRange.speakerNameEdit = speakerNameEdit;
  }
  return newSpeakerRange;
};

const createNewSubtitleRange = (
  range: Omit<SubtitlesRange, "id">,
  type: ElementType
) => {
  const { words, st, et } = range;
  const _startTime = st || words[0]?.start_time;
  const _endTime = et || words[words.length - 1]?.end_time;

  const startTime = _.isNumber(_startTime) ? _startTime : null;
  const endTime = _.isNumber(_endTime) ? _endTime : null;
  const newSubtitleRange = {
    ...range,
    type: type === "subtitleRange" ? "subtitles" : "",
    id: generateId("r_"),
    words: words,
    st: startTime,
    et: endTime,
  } as SubtitlesRange;

  return newSubtitleRange;
};

const createNewAnnotation = (rangeIndex: number, annotationIndex?: number) => {
  const newAnnotation = {
    id: generateId(),
    type: "note",
    text: "",
    range_ix: rangeIndex,
    temp: true,
  } as Annotation;

  return newAnnotation;
};

// In Psique, use this function via runValidation only
const validateJobRanges = (
  _ranges: JobRange[],
  validationConfig?: ValidationsConfigData | null
): JobRange[] => {
  // this function is mutate the ranges, so we should iterate the mutated ranges and update the nodes
  const ranges = _ranges.filter((range) => range.type === "subtitles");

  if (_.isEmpty(ranges) || _.isEmpty(validationConfig) || !validationConfig) {
    return ranges;
  }

  const validationTests = _.intersection(
    _.keys(validationConfig),
    _.keys(rangeValidations)
  );

  const validationResults = _.map(validationTests, (validationTestName) => {
    if (!_.has(rangeValidations, validationTestName)) return;

    const validationTest: any = _.get(rangeValidations, validationTestName);
    const clientValidationOptions = _.get(validationConfig, validationTestName);

    const jobParams = {
      jobType: validationConfig.jobType,
      lang: validationConfig.lang,
      frameLength: MediaService.frameLength,
      duration: MediaService.media?.duration,
    };
    const validationTestOptions = _.isObject(clientValidationOptions)
      ? {
          ...clientValidationOptions,
          ...jobParams,
        }
      : false;

    return validationTest(ranges, validationTestOptions);
  });

  return validationResults;
};

export const reIndexWords = (words: Word[], rangeIndex: number) => {
  let lineIndex = 0;

  return words.map((word, i) => {
    if (words[i + 1] && words[i + 1].line_ix === word.line_ix) {
      word.line_ix = lineIndex;
    } else {
      word.line_ix = lineIndex;
      lineIndex++;
    }
    word.range_ix = rangeIndex;
    return word;
  });
};

const addRangeIds = (ranges: JobRange[]) => {
  const _ranges = [...ranges];
  const rangesWithIDs = _ranges.map((range: JobRange) => {
    return { ...range, id: generateId("r_") };
  });
  return rangesWithIDs;
};

const jumpToWord = (
  e: React.MouseEvent<any, MouseEvent> | React.KeyboardEvent,
  plainWords: string,
  range: SubtitlesRange | SpeakerRange | SubtitlesTranslationRange
) => {
  let { selectionStart } = e.target as HTMLTextAreaElement;

  const editedWords = plainWords.split(" ");
  const lengths = editedWords.map((word) => word.length);

  let clickedWord = -1;
  while (selectionStart > 0) {
    clickedWord++;
    selectionStart = selectionStart - lengths[clickedWord] - 1;
  }
  if (selectionStart === 0) clickedWord++;

  if (range.words && range.words[clickedWord]) {
    MediaService.setOffset(range.words[clickedWord].start_time);
  }
};

const getCursorPosition = (editableDiv: HTMLDivElement) => {
  const selection = window.getSelection();
  let selectionStart = null;
  let selectionEnd = null;
  if (selection?.rangeCount) {
    const range = selection.getRangeAt(0);
    const preCaretRangeStart = range?.cloneRange();
    const preCaretRangeEnd = range?.cloneRange();

    preCaretRangeStart.selectNodeContents(editableDiv);
    preCaretRangeEnd.selectNodeContents(editableDiv);

    preCaretRangeStart.setEnd(range?.startContainer, range?.startOffset);
    preCaretRangeEnd.setEnd(range?.endContainer, range?.endOffset);

    selectionStart = preCaretRangeStart?.toString()?.length;
    selectionEnd = preCaretRangeEnd?.toString()?.length;
  }

  return {
    selectionStart,
    selectionEnd,
    textLength: editableDiv.innerText.length,
  };
};

const formatRangeWordsToString = (words: Word[]) => {
  return words.map((word) => word.word).join(" ");
};

const formatAnnotationDataToSlateAnnotation = (
  ann: Annotation,
  focusOnInit?: boolean
) => {
  const annotationRangeElement: AnnotationRangeElement = {
    type: "annotationRange" as ElementType,
    annotationType: ann.type,
    temp: ann.temp,
    annotation: ann,
    focusOnInit: !!focusOnInit,
    children: [{ text: ann.text }],
  };

  return annotationRangeElement;
};

const formatSlateChildrenFromRange = (range: JobRange) => {
  const children: any[] = [];
  let currentText = "";

  range.words.forEach((word, index) => {
    const isLastWord = index === range.words.length - 1;
    const isLastInLine = range.words[index + 1]?.line_ix !== word.line_ix;

    if (word?.style) {
      // Insert styled text into slate children (persist style)
      // TODO: not work 100% yet, last styled word in chain should get space
      // if (currentText) {
      //   children.push({ text: currentText });
      //   currentText = "";
      // }
      //
      // const styledText = word.word + (isLastWord ? "" : " ");
      //
      // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // // @ts-expect-error
      // children.push({ text: styledText, ...word.style });
    } else {
      // Accumulate unstyled text with spaces between words
      currentText += word.word + (isLastWord || isLastInLine ? "" : " ");
    }
    if (isLastInLine && !isLastWord) {
      currentText += "\n";
    }
  });

  children.push({ text: currentText });

  return children;
};

const formatSpeakerRangeToSlateElement = ({
  range,
  job,
}: {
  range: SpeakerRange;
  job: Job;
}): CustomElement[] => {
  const elements = [];

  const children = formatSlateChildrenFromRange(range);
  const speakerRangeElement: SpeakerRangeElement = {
    type: "speakersRange" as ElementType,
    children,
    range,
    tcOffsets: job.tcOffsets || [],
    annotations: (range as SpeakerRange).annotations,
  };
  elements.push(speakerRangeElement);

  if ((range as SpeakerRange)?.annotations?.length > 0) {
    const annotationElements = (range as SpeakerRange).annotations.map(
      (ann, idx) => formatAnnotationDataToSlateAnnotation(ann)
    );
    elements.push(...annotationElements);
  }
  return elements;
};

const createPlaceholderRange = (times: { st: number; et: number }) => {
  const range: SubtitlePlaceholderRangeElement = {
    type: "subtitlePlaceholder",
    children: [{ text: "-" }],
    range: {
      ...times,
    },
  };
  return range;
};

const formatSubtitleRangeToSlateElement = ({
  range,
  job,
}: {
  range: JobRange;
  job: Job;
}): CustomElement[] => {
  const elements = [];
  const children = formatSlateChildrenFromRange(range);
  const speakerRangeElement: SubtitleRangeElement = {
    type: "subtitleRange",
    children,
    range: range as SubtitlesRange,
    tcOffsets: job.tcOffsets || [],
  };
  elements.push(speakerRangeElement);

  return elements;
};

const formatSlateElementByMode = ({
  range,
  job,
  mode,
}: {
  range: JobRange;
  job: Job;
  mode: EditorMode;
}) =>
  mode === "transcript"
    ? formatSpeakerRangeToSlateElement({ range: range as SpeakerRange, job })
    : formatSubtitleRangeToSlateElement({ range, job });

const createEmptyJobData = (mode: EditorMode) => {
  const jobData: JobData = {
    ranges: [createEmptyRange()],
    speakers: [],
    version: 3,
    jobType: mode as JobData["jobType"],
  };
  return jobData;
};

const formatJobDataToEditorValue = (
  job: Job,
  mode: EditorMode
): Descendant[] => {
  let jobData = job.data;
  if (!jobData?.ranges?.length) {
    jobData = createEmptyJobData(mode);
  }

  const res: Descendant[] = [];
  const ranges = (jobData as JobData).ranges;

  ranges.forEach((range) => {
    const elements = formatSlateElementByMode({ range, job, mode });
    res.push(...elements);
  });
  if (mode === "subtitles") {
    const placeholderTimes = {
      st: 0,
      et: 0,
    };

    const rangePlaceholder = createPlaceholderRange(placeholderTimes);
    const mediaPlaceholder = createPlaceholderRange(placeholderTimes);
    const endMediaPlaceholder = createPlaceholderRange({
      st: ranges[ranges.length - 1].et,
      et: job.duration,
    });

    const startPlaceholders = [rangePlaceholder, mediaPlaceholder].filter(
      Boolean
    ) as CustomElement[];
    res.unshift(...startPlaceholders);
    res.push(endMediaPlaceholder);
  }
  return res.flat();
};

const formatEditorValueToJobData = (
  values: CustomElement[],
  mode: EditorMode
) => {
  const parsedSpeakerRanges = formatEditorSpeakerRangeToJobData(values, mode);

  return parsedSpeakerRanges;
};

const getAssociatedAnnotations = (ranges: CustomElement[]) => {
  if (!ranges.length) return [];

  const annotations: AnnotationRangeElement[] = [];
  let isNextRangeAnnotation = true;
  let i = 0;

  while (isNextRangeAnnotation) {
    if (ranges[i] && ranges[i].type === "annotationRange") {
      annotations.push(ranges[i] as AnnotationRangeElement);
      i++;
    } else {
      isNextRangeAnnotation = false;
    }
  }
  return annotations;
};

const getRangesWithAnnotationFromSlate = (speakerRanges: Descendant[]) => {
  const rangesWithAnnotations = [] as SpeakerRangeElement[];
  speakerRanges.forEach((slateNode, idx) => {
    if ((slateNode as CustomElement).type === "annotationRange") {
      return;
    } else {
      const nextRanges = speakerRanges.slice(idx + 1) as CustomElement[];
      const annotations = getAssociatedAnnotations(nextRanges);
      rangesWithAnnotations.push({
        ...slateNode,
        annotations,
      } as SpeakerRangeElement);
    }
  });
  return rangesWithAnnotations;
};

const formatEditorSpeakerRangeToJobData = (
  speakerRanges: Descendant[],
  mode: EditorMode
) => {
  const rangesWithAnnotations = getRangesWithAnnotationFromSlate(speakerRanges);

  const newRanges = rangesWithAnnotations
    .filter((node) => node.type !== "subtitlePlaceholder")
    .map((slateNode, idx) => {
      const element = _.clone(slateNode) as SpeakerRangeElement;
      const plainWords = Node.string(element);
      const oldPlainWords = element.range.words
        .map((w: Word) => w.word)
        .join(" ");
      const updatedRangeWords = getRangeWordsFromMultilineString(
        element.range,
        plainWords,
        oldPlainWords,
        speakerRanges.length
      );

      const newRange: SpeakerRange | SubtitlesRange = createNewRangeByType({
        type: mode === "transcript" ? "speakersRange" : "subtitleRange",
        range: {
          ...element.range,
          st: element.range.st,
          et: element.range.et,
          speakerId: null, // ?
          speakerName: element.range.speakerName || null,
          type: mode === "transcript" ? "speaker" : "subtitles",
          annotations: formatEditorAnnotationToJobData(element.annotations),
          words: updatedRangeWords,
        },
      });
      return newRange;
    });
  return newRanges;
};

const formatEditorAnnotationToJobData = (annotationRanges: Descendant[]) => {
  if (!annotationRanges.length) return [];
  const newRanges = annotationRanges.map((slateNode) => {
    const element = _.clone(slateNode) as AnnotationRangeElement;
    const text = Node.string(element);
    const newAnnotation: Annotation = {
      text,
      type: element.annotationType,
    };
    return newAnnotation;
  });
  return newRanges;
};

const getCurrentSelectionBlockPath = (editor: CustomEditor) => {
  //! Bug here. the next will be next of last element, not of the last cursored element
  //! editor.selection is null when refreshing the page & clicking one of the speakers then tab
  if (!editor || !editor.selection) return null;
  const currentBlock = Editor.above(editor);

  const currentPath = currentBlock ? currentBlock[1] : null;
  if (!currentPath) return null;
  return currentPath;
};

// Get the index of the current selection/cursor-on range.
const getCurrentSelectionNodeIndex = (editor: CustomEditor) => {
  if (!editor || !editor.selection) return null;
  const [index] = Editor.path(editor, editor.selection);
  return index;
};

// Get the element witch selection/cursor-on that range.
const getLastCursoredElement = (editor: CustomEditor) => {
  if (!editor || !editor.selection) return null;
  const currentBlock = Editor.above(editor);
  if (!currentBlock) return null;
  return {
    element: currentBlock[0] as CustomElement,
    path: currentBlock[1][0],
  };
};

// Get the index of the element range, including annotations!!
const getCurrentIndexByElement = (
  editor: CustomEditor,
  element: CustomElement
) => {
  if (!editor || !element) return;
  const idx = editor.children.indexOf(element);
  if (_.isNumber(idx) && idx !== -1) return idx;
  const [index] = ReactEditor.findPath(editor, element);
  return index;
};

const getIsLastElement = (
  editor: CustomEditor,
  element?: CustomElement
): boolean => {
  if (!editor || !element) return false;
  const index = getCurrentIndexByElement(editor, element);
  if (!index) return false;
  if (element.type === "subtitleRange") {
    const isBeforePlaceholders = editor.children
      .slice(index + 1)
      .every(
        (child) => (child as CustomElement).type === "subtitlePlaceholder"
      );
    if (isBeforePlaceholders) return true;
  }
  return editor.children.length - 1 === index;
};

// Set focus by path or element
const focusByPathOrElement = (
  editor: CustomEditor,
  options: { path?: number[]; element?: Element }
) => {
  if (!editor) return;

  const { path, element } = options;
  if (!path && !element) return;

  const pathToFocus =
    path || ReactEditor.findPath(editor as ReactEditor, element as Element);

  setTimeout(() => {
    Transforms.select(editor, {
      anchor: Editor.start(editor, pathToFocus),
      focus: Editor.start(editor, pathToFocus),
    } as Location);
    ReactEditor.focus(editor as ReactEditor);
  }, 0);
};

//Won't work if there is no selection
const getFocusedRenderIndex = (editor: CustomEditor) => {
  const focusedRangeIndex = editor.selection?.anchor.path[0];
  if (!_.isNumber(focusedRangeIndex) || !_.isNumber(editor.startIndex)) return;
  const renderIndex = focusedRangeIndex - editor.startIndex;
  return renderIndex;
};

const paginateSubtitle = ({
  editor,
  to,
  preventCursorManipulation,
}: {
  editor?: CustomEditor;
  to: "next" | "prev";
  preventCursorManipulation?: boolean;
}) => {
  if (!editor || !_.isNumber(editor.startIndex)) return;

  let newStartIndex = editor.startIndex;

  if (to === "prev") {
    newStartIndex = editor.isBetweenRanges ? newStartIndex + 1 : newStartIndex;
  } else {
    newStartIndex += editor.startIndex === 2 && !editor.isBetweenRanges ? 3 : 2;
  }

  const newRange = editor.children[newStartIndex] as CustomElement;
  if (!("range" in newRange)) return;

  const newSt = newRange.range.st;

  navigateToSubtitle({ editor, st: newSt, preventCursorManipulation });

  return { st: newSt, newIndex: newStartIndex };
};

const focusToNextOrPrevSubtitle = (
  editor: CustomEditor,
  to: "next" | "prev"
) => {
  const renderIndex = getFocusedRenderIndex(editor);

  if (
    (renderIndex === 0 && to === "prev") ||
    (renderIndex === 2 && to === "next")
  ) {
    paginateSubtitle({ editor, to });
  }

  const startIndex = editor.startIndex;
  if (!_.isNumber(renderIndex) || !_.isNumber(startIndex)) return;
  const focusedIndex = startIndex + renderIndex;
  const destinationPath = to === "next" ? focusedIndex + 1 : focusedIndex - 1;
  if (!editor.children[destinationPath]) return;
  Transforms.select(editor, { path: [destinationPath], offset: 0 });
};

const onSkipSubtitle = ({ editor }: { editor?: CustomEditor }) => {
  if (!editor) return;
  const result = paginateSubtitle({
    editor,
    to: "next",
  });
  if (MediaService.isRepeat) {
    MediaService.setCurrentRepeatedRange(MediaService.currentRepeatedRange + 1);
  }
  return result;
};
const onPrevSubtitle = ({ editor }: { editor?: CustomEditor }) => {
  if (!editor) return;
  const result = paginateSubtitle({
    editor,
    to: "prev",
  });
  if (MediaService.isRepeat) {
    MediaService.setCurrentRepeatedRange(MediaService.currentRepeatedRange - 1);
  }
  return result;
};

const focusToNextSpeakerTextRange = (editor: CustomEditor): any => {
  const currentPath = getCurrentSelectionBlockPath(editor);
  if (!currentPath) return null;
  const nextPath = Path.next(currentPath);
  if (!editor.children[nextPath[0]]) return;

  focusByPathOrElement(editor, { path: nextPath });

  //TODO: comment the code below to jump the cursor to the speakers.
  // im not doing it now because the tab click afterwards is not working as expected.
  // also, when uncomment implement same logic at focusToPrevSpeakerTextRange.

  // const nextBlock = getElementByPath(editor, Path.next(currentPath));
  // if (nextBlock && nextBlock[0]?.type !== "annotationRange") {
  //   Transforms.setNodes(
  //     editor,
  //     { focusOnInit: true },
  //     { at: Path.next(currentPath) }
  //   );
  // } else {
  //   focusByPathOrElement(editor, { path: Path.next(currentPath) });
  // }
};

const focusToPrevSpeakerTextRange = (editor: CustomEditor): any => {
  const currentPath = getCurrentSelectionBlockPath(editor);
  if (!currentPath) return null;
  if (currentPath[0] === 0) return;
  const prevPath = Path.previous(currentPath);
  if (!editor.children[prevPath[0]]) {
    console.error(`Trying to focus to prev speaker text range failed.`);
  }
  focusByPathOrElement(editor, { path: prevPath });
};

const updateNodeData = ({
  data,
  editor,
  element,
}: {
  element: Element;
  data: Partial<CustomElement>;
  editor: CustomEditor;
}) => {
  const idx = editor?.children?.indexOf(element);

  if (_.isNumber(idx) && idx >= 0) {
    Transforms.setNodes(editor, data, { at: [idx] });
  } else {
    try {
      const path = ReactEditor.findPath(editor as ReactEditor, element); // findPath method isn't stable on subtitle editor
      Transforms.setNodes(editor, data, { at: path });
    } catch (e) {
      console.error("Unable to update node data", e);
    }
  }
};

const insertNode = (editor: CustomEditor, node: Node, path: number[]) => {
  Transforms.insertNodes(editor, node, { at: path });
};

const createSlateAnnotation = (editor: CustomEditor) => {
  const node = getLastCursoredElement(editor);
  const textLength = node && Node.string(node?.element)?.length;

  const isCursorInStart = editor.selection?.anchor.offset === 0;
  const isCursorInEnd = editor.selection?.anchor.offset === textLength;

  if (!isCursorInStart && !isCursorInEnd) editor.splitNodes({ always: true });
  const path = getCurrentSelectionBlockPath(editor);
  if (path)
    Transforms.setNodes(editor, { focusOnInit: false } as any, { at: path });

  const rangeIndex = getCurrentSelectionNodeIndex(editor);
  if (!path || _.isNull(rangeIndex)) return;
  const newAnnotation = createNewAnnotation(rangeIndex);
  const slateAnnotation = formatAnnotationDataToSlateAnnotation(
    newAnnotation,
    true
  );

  const annotationPath =
    isCursorInEnd || rangeIndex === 0 ? Path.next(path) : path;
  insertNode(editor, slateAnnotation, annotationPath);
};

const removeNode = (editor: CustomEditor, element: Element) => {
  const path = ReactEditor.findPath(editor as ReactEditor, element);
  Transforms.removeNodes(editor, { at: path });
  return path;
};

// Reset slate state will cause the whole editor to be removed & re-render. use it only if you know what you are doing
const expensivelyResetEditorState = (
  editor: CustomEditor,
  newSlateState: Descendant[]
) => {
  editor.children.forEach((c) => Transforms.removeNodes(editor, { at: [0] }));
  Transforms.insertNodes(editor, newSlateState, { at: [0] });
};

const getRangesCount = (editor: CustomEditor) => {
  return editor.children?.filter(
    (n) => (n as CustomElement).type !== "annotationRange"
  )?.length;
};

const getPlainWordsFromRange = (range: JobRange) => {
  const plainWords =
    range?.words
      ?.map((w) => w.word)
      .join(" ")
      .trim() || "";
  return plainWords;
};

const getFirstWordAfterRangesBreak = ({
  oldPlainWords,
  rangesCount,
  plainWords,
  newRangeWords,
  oldRange,
}: {
  oldRange: JobRange;
  newRangeWords: Word[];
  oldPlainWords: string;
  plainWords: string;
  rangesCount: number;
}) => {
  const breakIndex = oldRange.words.length - newRangeWords.length;
  const oldRangeWords = getRangeWordsFromMultilineString(
    oldRange,
    oldPlainWords,
    plainWords,
    rangesCount
  );

  const firstNewRangeWord = oldRange.words[breakIndex];
  const firstWordCopy = { ...newRangeWords[0] }; // Create a shallow copy of the first word object to modify its properties
  if (!_.isEmpty(firstWordCopy)) {
    firstWordCopy.start_time = firstNewRangeWord.start_time;
    firstWordCopy.end_time = firstNewRangeWord.end_time;
  }

  return { oldRangeWords, firstWordCopy };
};

const getNextRange = (editor: CustomEditor, path: number) => {
  let index = path;
  let range = editor.children[index] as CustomElement | null;
  if (!range) return null;

  while (range?.type === "annotationRange") {
    index++;
    range = editor.children[index] as CustomElement | null;
  }
  return range as SpeakerRangeElement | null;
};

const isHiddenCharacter = (code: number, nextCode?: number): boolean => {
  return (
    (code >= 0x200b && code <= 0x200f) || // Zero-width characters
    (code >= 0x202a && code <= 0x202e) || // Directional formatting
    code === 0xfeff || // Byte order mark
    code === 0x200c ||
    code === 0x200d || // Zero-width joiner/non-joiner
    (code === 0x0d && nextCode === 0x0a) // Only CR when followed by LF
  );
};

const createLocationRange = (
  nodeIndex: number,
  start: number,
  end: number
): BaseRange => {
  return {
    anchor: { path: [nodeIndex, 0], offset: start },
    focus: { path: [nodeIndex, 0], offset: end },
  };
};

const deleteRanges = (editor: CustomEditor, ranges: BaseRange[]) => {
  ranges.forEach((at) => {
    Transforms.delete(editor, { at });
  });
};

const trimNodeText = ({
  editor,
  nodeIndex,
}: {
  editor: CustomEditor;
  nodeIndex: number;
}) => {
  const node = editor.children[nodeIndex];
  const text = Node.string(node);
  const trimmedText = text.trim();

  if (text === trimmedText) return;

  const ranges: BaseRange[] = [];

  const startTrimLength = text.length - text.trimStart().length;
  if (startTrimLength > 0) {
    ranges.push(createLocationRange(nodeIndex, 0, startTrimLength));
  }

  const endTrimLength = text.length - text.trimEnd().length;
  if (endTrimLength > 0) {
    const endTrimStartIndex = text.length - endTrimLength;
    ranges.push(createLocationRange(nodeIndex, endTrimStartIndex, text.length));
  }

  deleteRanges(editor, ranges);
};

const removeHiddenCharacters = ({
  editor,
  nodeIndex,
}: {
  editor: CustomEditor;
  nodeIndex: number;
}) => {
  const node = editor.children[nodeIndex];
  const text = Node.string(node);
  const ranges: BaseRange[] = [];
  let rangeStart: number | null = null;

  for (let i = 0; i < text.length; i++) {
    const code = text.charCodeAt(i);
    const nextCode = i < text.length - 1 ? text.charCodeAt(i + 1) : undefined;

    if (isHiddenCharacter(code, nextCode) && rangeStart === null) {
      rangeStart = i;
    } else if (!isHiddenCharacter(code, nextCode) && rangeStart !== null) {
      ranges.push(createLocationRange(nodeIndex, rangeStart, i));
      rangeStart = null;
    }
  }

  if (rangeStart !== null) {
    ranges.push(createLocationRange(nodeIndex, rangeStart, text.length));
  }

  deleteRanges(editor, ranges);
};

const serializeRange = ({
  editor,
  nodeIndex,
}: {
  editor: CustomEditor;
  nodeIndex: number;
}) => {
  if (!editor.children[nodeIndex]) return;

  trimNodeText({
    editor,
    nodeIndex,
  });
  removeHiddenCharacters({
    editor,
    nodeIndex,
  });
};

const onBreakRange = (
  path: SplitNodeOperation["path"],
  editor: CustomEditor,
  entry?: {
    // second part
    element: CustomElement;
    path: number;
  } | null
) => {
  if (!entry?.element || !entry.path) entry = null;
  const currentNode = entry || getLastCursoredElement(editor);
  if (!currentNode?.element || !("range" in currentNode.element)) return;

  const rangesCount = getRangesCount(editor);
  const type = currentNode.element.type;

  const firstPartNode = editor.children[currentNode.path - 1];

  serializeRange({ editor, nodeIndex: currentNode.path - 1 });
  serializeRange({ editor, nodeIndex: currentNode.path });

  const secondPartPlainWords = Node.string(currentNode.element);
  const firstPartPlainWords = Node.string(firstPartNode);

  const oldFirstPartRange = currentNode.element.range as JobRange;
  const oldFirstPartWords = getPlainWordsFromRange(oldFirstPartRange);

  const nextElement = getNextRange(editor, path[0] + 2); // the element after the new range break
  const oldSecondPartRange = nextElement?.range;
  const secondPartRangePath = Path.next(path);

  const secondPartRangeWords = getRangeWordsFromMultilineString(
    oldFirstPartRange,
    secondPartPlainWords,
    oldFirstPartWords,
    rangesCount
  );

  const { oldRangeWords, firstWordCopy } = getFirstWordAfterRangesBreak({
    oldRange: oldFirstPartRange,
    newRangeWords: secondPartRangeWords,
    plainWords: oldFirstPartWords,
    oldPlainWords: firstPartPlainWords,
    rangesCount,
  });

  // We need to update the start time of the first new range word because the old words array is not updated.
  if (!_.isEmpty(firstWordCopy)) secondPartRangeWords[0] = firstWordCopy;
  const isCursorInStartOfRange = window.getSelection()?.focusOffset === 0;
  if (oldRangeWords.length === 0 && !isCursorInStartOfRange) {
    console.error("Trying to break empty range, operation prevented");
    Transforms.removeNodes(editor, { at: path });
    return;
  }

  const newFirstPartRange = createNewRangeByType({
    type: type,
    range: {
      ...oldFirstPartRange,
      words: oldRangeWords,
      speakerName: oldFirstPartRange.speakerName || null,
      time_edit: true,
      text_edit: true,
      st: oldFirstPartRange.st,
      et:
        oldRangeWords[oldRangeWords.length - 1]?.end_time ||
        oldFirstPartRange.st + 0.01, // In case of cursor is in the start of the range
    },
  });

  const newSecondPartRangeObject = {
    ...oldSecondPartRange,
    words: secondPartRangeWords,
    speakerName: oldFirstPartRange.speakerName || null,
    time_edit: true,
    text_edit: true,
    st: newFirstPartRange.et,
    et:
      secondPartRangeWords[secondPartRangeWords.length - 1]?.end_time ||
      nextElement?.range?.st ||
      newFirstPartRange.et,
  };
  if (type === "subtitleRange") {
    (newSecondPartRangeObject as SubtitlesRange).sourceWords =
      (oldFirstPartRange as SubtitlesRange).sourceWords || [];
  }
  const newSecondPartRange = createNewRangeByType({
    type: type,
    range: newSecondPartRangeObject,
  });

  Transforms.setNodes(
    editor,
    {
      range: newFirstPartRange,
      focusOnInit: type === "speakersRange" && isCursorInStartOfRange,
    } as CustomElement,
    { at: path }
  );

  Transforms.setNodes(
    editor,
    {
      range: newSecondPartRange,
      focusOnInit: type === "speakersRange" && !isCursorInStartOfRange,
    } as CustomElement,
    { at: secondPartRangePath }
  );

  createWaveformRanges(editor, false);
};

const isMergeNodeAllowed = (
  editor: CustomEditor,
  operation: MergeNodeOperation,
  oldElements: CustomElement[]
) => {
  const currentNode = getLastCursoredElement(editor);
  const oldIndex = operation.path[0];
  const oldNode = oldElements[oldIndex];

  const isCursorAtStart = window.getSelection()?.focusOffset === 0;
  const isFirstRange = getIsFirstRange(editor, oldIndex);

  const isMergingBackwardsFirstRange = isCursorAtStart && isFirstRange;

  if (
    oldNode?.type === "subtitlePlaceholder" ||
    currentNode?.element?.type === "subtitlePlaceholder" ||
    isMergingBackwardsFirstRange
  )
    return false;

  return true;
};

const getUpdatedMergedRange = (
  newRange: SpeakerRange | SubtitlesRange | SubtitlesTranslationRange,
  oldRange: SpeakerRange | SubtitlesRange | SubtitlesTranslationRange,
  isSubtitles: boolean
) => {
  const words = [...newRange.words, ...oldRange.words];

  const newUpdatedRangeObject = {
    ...newRange,
    et: oldRange.et,
    words,
    time_edit: true,
    text_edit: true,
  };
  if (isSubtitles && "sourceWords" in oldRange) {
    (newUpdatedRangeObject as SubtitlesRange).sourceWords = [
      ...((newUpdatedRangeObject as SubtitlesRange)?.sourceWords || []),
      ...((oldRange as SubtitlesRange)?.sourceWords || []),
    ];
  }
  return newUpdatedRangeObject;
};

const onMergeRange = (
  operation: MergeNodeOperation,
  editor: CustomEditor,
  oldElements: CustomElement[]
) => {
  const currentNode = getLastCursoredElement(editor);
  const oldIndex = operation.path[0];
  const oldNode = oldElements[oldIndex];

  if (
    !currentNode?.element ||
    !("range" in currentNode.element) ||
    !("range" in oldNode)
  )
    return;

  const newRange = currentNode.element.range as JobRange;
  const oldRange = oldNode.range as JobRange;
  const type = currentNode.element.type;
  const isSubtitles = currentNode.element.type === "subtitleRange";
  if (isSubtitles) {
    const isLastElement = getIsLastElement(editor, oldNode);
    if (isLastElement) {
      navigateToPrevSubtitle({
        editor,
        currentIdx: oldIndex,
        preventCursorManipulation: true,
      });
    }
  }
  const newUpdatedRangeObject = getUpdatedMergedRange(
    newRange,
    oldRange,
    isSubtitles
  );
  const newUpdatedRange = createNewRangeByType({
    type: type,
    range: newUpdatedRangeObject,
  });

  Transforms.setNodes(editor, { range: newUpdatedRange } as CustomElement, {
    at: [currentNode.path],
  });

  createWaveformRanges(editor, false);
};

const createNewRangeByType = ({
  type,
  range,
}: {
  type: ElementType;
  range: Partial<JobRange>;
}) => {
  return type === "speakersRange"
    ? createNewSpeakerRange(range as SpeakerRange)
    : createNewSubtitleRange(range as SubtitlesRange, type);
};

const getShouldReorderRangeWords = (words: Word[]) => {
  if (!words) return false;
  if (words.length < 2) return false;

  const isFirstWordOutOfRange = words[1]?.start_time < words[0]?.start_time;
  const isLastWordOutOfRange =
    words[words?.length - 2]?.end_time > words[words.length - 1]?.end_time;

  if (isFirstWordOutOfRange || isLastWordOutOfRange) {
    return true;
  }
  return false;
};

const calculateRangeHeight = ({
  elementInnerHeight,
  visibleRanges,
  gap = 8,
}: {
  elementInnerHeight?: number;
  visibleRanges: number;
  gap?: number;
}) => {
  if (!_.isNumber(elementInnerHeight)) {
    return { mediaHeight: 600, subtitleHeight: 75 };
  }
  //!IMPORTANT : the mediaHeight must be a product of subtitleHeight for the virtualize and scroll works smooth and as expected

  const subtitleHeight = 80;

  const totalSubtitleHeight = subtitleHeight * (visibleRanges - 1);
  const mediaHeight = Math.max(
    0,
    elementInnerHeight - totalSubtitleHeight - gap
  );

  const adjustedMediaHeight =
    Math.floor(mediaHeight / subtitleHeight) * subtitleHeight; // make sure media height is product of subtitle height

  return { subtitleHeight, mediaHeight: Math.floor(adjustedMediaHeight) };
};

const reorderRangeWords = (editor: CustomEditor, index?: number) => {
  if (!_.isNumber(index)) return;
  const element = editor?.children[index] as SubtitleRangeElement;
  if (!element || !("range" in element)) return;

  const range = (element.range || {}) as SubtitlesRange | SpeakerRange;
  const words = range.words || [];
  const { st: rangeSt, et: rangeEt } = element.range;

  const shouldReorderWords = getShouldReorderRangeWords(range.words);
  if (!shouldReorderWords) return;

  const newWords = getWordsByRangeTimes(words, rangeSt, rangeEt);
  const newRange = {
    ...range,
    words: newWords,
  };

  updateNodeData({
    editor,
    data: {
      range: newRange,
    },
    element,
  });
};

const debouncedReorderRangeWords = _.debounce(reorderRangeWords, 500);

//Updating the range words & times of the last selected element. Important for double click and break range timings.
const updateElementRange = ({
  entry,
  editor,
  updateRangeTimesByWords,
  overrideTimes,
}: {
  editor: CustomEditor;
  entry?: { element: CustomElement; path: number } | null;
  updateRangeTimesByWords?: boolean;
  overrideTimes?: {
    st: number;
    et: number;
  };
}) => {
  const node = entry || getLastCursoredElement(editor);
  if (!node?.element || _.isNil(node?.path)) return;
  const element = node.element as CustomElement;
  if (!("range" in element)) return;
  let range = element.range as JobRange;
  const newInputString = Node.string(element).trim();
  const oldInputString = getPlainWordsFromRange(range);
  const isEqual = _.isEqual(newInputString, oldInputString);
  if (isEqual && !range.time_edit) return;

  if (overrideTimes) {
    range = { ...range, st: overrideTimes.st, et: overrideTimes.et };
  }

  const rangesCount = getRangesCount(editor);
  const updateWords = getRangeWordsFromMultilineString(
    range,
    newInputString,
    oldInputString,
    rangesCount
  );

  const newRange = createNewRangeByType({
    type: element.type,
    range: { ...range, words: updateWords },
  });
  if (updateRangeTimesByWords) {
    const st = newRange.words[0].start_time;
    const et = newRange.words[newRange.words.length - 1].end_time;
    newRange.st = st;
    newRange.et = et;
  }
  Transforms.setNodes(editor, { range: newRange } as CustomElement, {
    at: [node.path],
  });

  if (editor.mode === "subtitles" || editor.mode === "subtitles-translation") {
    runValidations({ editor, rangeIndex: node.path });
  }

  serializeRange({ editor, nodeIndex: node.path });
};

const updateRangeOnFrameChange = (
  element: SubtitleRangeElement | SpeakerRangeElement,
  position: "start" | "end",
  updatedTimeInSecs: number
) => {
  const newRange = structuredClone(element.range);
  newRange[position === "start" ? "st" : "et"] = updatedTimeInSecs;
  newRange.time_edit = true;

  if (position === "start") {
    if (newRange.words[0]) {
      newRange.words[0].start_time = updatedTimeInSecs;
      newRange.words[0].time_edit = true;
    }
  }

  if (position === "end") {
    if (newRange.words[newRange.words.length - 1]) {
      newRange.words[newRange.words.length - 1].end_time = updatedTimeInSecs;
      newRange.words[newRange.words.length - 1].time_edit = true;
    }
  }
  return newRange;
};

const handleAddFrames = ({
  editor,
  framesToAdd,
  position,
  element,
  disabled,
  rangeIndex,
  limit,
}: {
  editor: CustomEditor;
  framesToAdd: number;
  position: "start" | "end";
  disabled: boolean;
  element: SubtitleRangeElement;
  rangeIndex?: number;
  limit?: {
    min?: number;
    max?: number;
  };
}) => {
  if (element.type !== "subtitleRange") return;
  const updatedTimeInSecs = TimeService.addFramesToTime({
    time: element.range[position === "start" ? "st" : "et"],
    framesToAdd: framesToAdd,
    frameLength: MediaService.frameLength,
  });

  if (updatedTimeInSecs < 0) return;
  if (_.isNumber(limit?.min) && updatedTimeInSecs < limit?.min) return;
  if (_.isNumber(limit?.max) && updatedTimeInSecs > limit?.max) return;

  const newRange = updateRangeOnFrameChange(
    element,
    position,
    updatedTimeInSecs
  );

  updateNodeData({
    editor,
    data: { range: newRange },
    element,
  });

  if (!_.isNumber(rangeIndex)) return;

  debouncedReorderRangeWords(editor, rangeIndex);

  debouncedValidation({
    editor,
    rangeIndex,
  });

  debouncedCreateWaveformRanges(editor, disabled);
};
const updateRangeTime = ({
  editor,
  time,
  position,
  element,
  rangeIndex,
}: {
  editor: CustomEditor;
  time: number;
  position: "start" | "end";
  element: SubtitleRangeElement;
  rangeIndex?: number;
}) => {
  if (element.type !== "subtitleRange") return;

  const newRange = updateRangeOnFrameChange(element, position, time);

  updateNodeData({
    editor,
    data: { range: newRange },
    element,
  });

  runValidations({
    editor,
    rangeIndex,
  });

  if (!_.isNumber(rangeIndex)) return;

  reorderRangeWords(editor, rangeIndex);
  createWaveformRanges(editor, false);
};

const runValidations = ({
  editor,
  rangeIndex,
  validationsConfig,
}: {
  editor: CustomEditor;
  rangeIndex?: number;
  validationsConfig?: ValidationsConfigData;
}) => {
  if (!_.isNumber(rangeIndex)) return;

  const firstRangeIndex = Math.max(rangeIndex - 1, 0);
  const lastRangesIndex = rangeIndex + 1;

  const rangesToValidate: JobRange[] = structuredClone(
    editor.children
      .slice(firstRangeIndex, lastRangesIndex + 1)
      .map((child) => (child as any).range)
  );

  validateJobRanges(
    rangesToValidate,
    validationsConfig || editor.jobSettings?.subtitlesValidation
  );

  rangesToValidate.forEach((range, idx) => {
    if (range.type !== "subtitles") return;
    const path = firstRangeIndex + idx;
    if (!_.isNumber(path)) return;
    const nodeRange = editor.children[path] as CustomElement;
    if (!("range" in nodeRange)) return;
    Transforms.setNodes(
      editor,
      {
        range: {
          ...nodeRange.range,
          validation: range.validation,
        },
      } as CustomElement,
      { at: [path] }
    );
  });
};

const debouncedValidation = _.debounce(runValidations, 500);

const updateNodeFocus = (
  editor: CustomEditor,
  node: { path: number; element: CustomElement },
  focusOnInit: boolean
) => {
  updateNodeData({
    data: { focusOnInit },
    editor: editor,
    element: node.element,
  });
};

const handleOpenSpeakers = async (editor: CustomEditor) => {
  const node = getLastCursoredElement(editor);
  if (!node?.element) return;
  if ((node.element as SpeakerRangeElement).focusOnInit) {
    // if the element is already focused, we want to unfocus it first
    updateNodeFocus(editor, node, false);
    await freeze(0);
  }
  updateNodeData({
    data: { focusOnInit: true },
    editor: editor,
    element: node.element,
  });
  setTimeout(
    () =>
      updateNodeData({
        data: { focusOnInit: false },
        editor: editor,
        element: node.element,
      }),
    100
  );
};

// Jump to the current selected word, that won't work if there is no selection.
const jumpToSlateWord = (editor: CustomEditor) => {
  const currentNode = getLastCursoredElement(editor);
  if (!currentNode || !editor.selection) return;

  const { element, path } = currentNode;
  if (!element || !("range" in element)) return;

  let selectionStart = editor.selection.anchor.offset;

  const range = element.range as JobRange;
  const plainWords = getPlainWordsFromRange(range);
  const editedWords = plainWords.split(" ");

  const lengths = editedWords.map((word) => word.length);

  let clickedWord = -1;
  while (selectionStart > 0) {
    clickedWord++;
    selectionStart = selectionStart - lengths[clickedWord] - 1;
  }
  if (selectionStart === 0) clickedWord++;

  if (range.words && range.words[clickedWord]) {
    MediaService.setOffset(range.words[clickedWord].start_time);
  }
};

const findWordIndexAfterOffset = (wordsArray: Word[], offset: number) => {
  let cumulativeLength = 0;

  for (let i = 0; i < wordsArray.length; i++) {
    const wordLength = wordsArray[i].word.length;

    // Include space between words, except before the first word
    if (i > 0) {
      cumulativeLength += 1; // for the space
    }

    cumulativeLength += wordLength;

    if (cumulativeLength > offset) {
      return i;
    }
  }

  // If the offset exceeds the total number of characters, return -1 or the last index
  return -1; // or return wordsArray.length - 1;
};

const getHighlightWordsByText = ({
  search,
  path,
  node,
}: {
  search: string;
  node: CustomElement;
  path: number[];
}) => {
  const ranges: any = [];
  if (!search) return ranges;

  if (Array.isArray(node.children) && node.children.every(Text.isText)) {
    const texts = node.children.map((it) => it.text);
    const str = texts.join("");
    const length = search.length;
    let start = str.indexOf(search);
    let index = 0;
    let iterated = 0;

    // Track occurrences per path
    const occurrencesInPath: { [key: string]: number } = {};

    while (start !== -1) {
      // Skip already iterated strings
      while (index < texts.length && start >= iterated + texts[index].length) {
        iterated = iterated + texts[index].length;
        index++;
      }

      // Find the index of array and relative position
      let offset = start - iterated;
      let remaining = length;

      while (index < texts.length && remaining > 0) {
        const currentText = texts[index];
        const currentPath = [...path, index];
        const pathKey = currentPath.join("-"); // String key to track occurrences
        const taken = Math.min(remaining, currentText.length - offset);

        const wordIx = findWordIndexAfterOffset(
          (node as any).range?.words || [],
          offset
        );

        const subtext =
          (node as any)?.range?.words[wordIx]?.start_time ||
          (node as any)?.annotationType ||
          "";

        // Increment occurrences for the current path
        if (!occurrencesInPath[pathKey]) {
          occurrencesInPath[pathKey] = 1;
        } else {
          occurrencesInPath[pathKey]++;
        }

        ranges.push({
          anchor: { path: currentPath, offset },
          focus: { path: currentPath, offset: offset + taken },
          highlightGreen: true,
          subtext,
          place: occurrencesInPath[pathKey], // Add place property
          node,
        });

        remaining = remaining - taken;
        if (remaining > 0) {
          iterated = iterated + currentText.length;
          offset = 0;
          index++;
        }
      }

      // Looking for next search block
      start = str.indexOf(search, start + search.length);
    }
  }

  return ranges;
};

// Calculate the offset for highlighting the word
const calculateOffset = (words: any[], index: number): number => {
  let offset = 0;
  for (let i = 0; i < index; i++) {
    offset += words[i].word.length + 1; // +1 for space between words
  }
  return offset;
};

const getLastWordHighlight = ({
  path,
  currentTime,
  lastWordIndex,
  words,
  rangeEndTime,
}: {
  words: any[];
  currentTime: number;
  path: number[];
  lastWordIndex: number;
  rangeEndTime: number;
}): any | null => {
  if (lastWordIndex !== -1 && currentTime < rangeEndTime) {
    const lastWord = words[lastWordIndex];
    const offset = calculateOffset(words, lastWordIndex);
    return {
      anchor: { path: [...path, 0], offset },
      focus: { path: [...path, 0], offset: offset + lastWord.word.length },
      highlightLightblue: true,
    };
  }
  return null;
};

// Karaoke mode.
const getHighlightWordsByTime = ({
  currentTime,
  path,
  node,
}: {
  node: SpeakerRangeElement | AnnotationRangeElement;
  currentTime?: number | null;
  path: number[];
}) => {
  const ranges: any[] = [];
  if (!Array.isArray(node.children) || !Element.isElement(node)) return ranges;
  if (!_.isNumber(currentTime)) return ranges;
  if (!("range" in node) || !node.range) return ranges;
  if (currentTime < node.range.st || currentTime > node.range.et) return ranges;

  const { words } = node.range;

  let lastWordIndex = -1;

  for (const word of words) {
    const index: number = words.indexOf(word);
    const wordStart = word.start_time;
    const wordEnd = word.end_time;
    if (currentTime > wordStart) {
      // last word index is the index of the last word that has been active
      lastWordIndex = index;
    }

    if (currentTime >= wordStart && currentTime < wordEnd) {
      // Highlight word that is currently playing
      if (currentTime < wordEnd || index === words.length - 1) {
        const offset = calculateOffset(words, index);

        ranges.push({
          anchor: { path: [...path, 0], offset },
          focus: { path: [...path, 0], offset: offset + word.word.length },
          highlightLightblue: true,
        });
        return ranges;
      }
    }
  }

  // If no word is currently active, keep the last word highlighted.
  const lastWordHighlightRange = getLastWordHighlight({
    words,
    currentTime,
    path,
    lastWordIndex,
    rangeEndTime: node.range.et,
  });

  if (lastWordHighlightRange) {
    ranges.push(lastWordHighlightRange);
  }

  return ranges;
};

const getHighlightWords = ({
  search,
  currentTime,
  path,
  node,
}: {
  search: string;
  currentTime?: number | null;
  node: SpeakerRangeElement | AnnotationRangeElement;
  path: number[];
}) => {
  if (!search && !currentTime) return [];

  const highlightsBySearchTerm = search
    ? getHighlightWordsByText({ node, search, path })
    : [];

  const highlightsByCurrentTime = currentTime
    ? getHighlightWordsByTime({ node, currentTime, path })
    : [];

  return [...highlightsBySearchTerm, ...highlightsByCurrentTime];
};

const groupHighlightsByNode = (highlights: MarkWordMetadata[]) => {
  const grouped = new Map();

  highlights.forEach((highlight) => {
    const pathString = JSON.stringify(highlight.anchor.path); // Use path as a key
    if (!grouped.has(pathString)) {
      grouped.set(pathString, []);
    }
    grouped.get(pathString).push(highlight);
  });

  // Convert the Map to an array of arrays
  return Array.from(grouped.values()) as MarkWordMetadata[][];
};

// Replace selected word with new text
const replaceOne = ({
  editor,
  markedWord,
  newText,
  shouldUpdateRangeWords = true,
  mode,
  marks,
}: {
  editor: CustomEditor;
  markedWord: MarkWordMetadata | null;
  newText: string;
  shouldUpdateRangeWords?: boolean;
  mode: EditorMode;
  marks?: MarkWordMetadata[] | null;
}) => {
  if (!editor || !markedWord) return;

  const replaceTextBySelection = () => {
    Transforms.select(editor, {
      anchor: markedWord.anchor,
      focus: markedWord.focus,
    });
    Transforms.insertText(editor, newText);
    if (mode === "subtitles") {
      Transforms.unsetNodes(editor, "highlightGreen", {
        at: {
          anchor: markedWord.anchor,
          focus: markedWord.focus,
        },
        match: (n: any) => Text.isText(n) && (n as any)?.highlightGreen,
      });
    }
  };

  // if more than 1 occurrence on range
  if (mode === "transcript") {
    replaceTextBySelection();
    updateElementRange({ editor });
  }

  if (mode === "subtitles") {
    const nodeIdx = markedWord.anchor.path[0];
    let occurrence = 0;
    const isOccurTwice = marks?.find((mark) => {
      if (mark.anchor.path[0] === nodeIdx) {
        occurrence += 1;
      }
      return occurrence === 2;
    });
    if (isOccurTwice) {
      replaceTextBySelection();
      updateElementRange({ editor });
    } else {
      replaceTextBySelection();
      updateElementRange({ editor });

      // Transforms.delete(editor, {
      //   at: {
      //     path: markedWord.anchor.path,
      //     offset: markedWord.anchor.offset,
      //   },
      //   distance: markedWord.focus.offset - markedWord.anchor.offset,
      // });
      // Transforms.insertText(editor, newText, {
      //   at: {
      //     path: markedWord.anchor.path,
      //     offset: markedWord.anchor.offset,
      //   },
      // });
    }
  }

  ReactEditor.deselect(editor);
};

// Replacing all text occurrences with new text
const replaceAll = ({
  editor,
  highlights,
  replaceInput,
  findInput,
  mode,
}: {
  editor: CustomEditor;
  highlights: MarkWordMetadata[];
  replaceInput: string;
  findInput: string;
  mode: EditorMode;
}) => {
  if (!editor) return false;

  const groupedHighlights = groupHighlightsByNode(highlights);

  try {
    for (const rangeHighlights of groupedHighlights) {
      let updatedRangeHighlights = [...rangeHighlights];
      while (updatedRangeHighlights.length > 0) {
        const highlight = updatedRangeHighlights[0];
        const shouldUpdateRangeWords = updatedRangeHighlights.length === 1;
        replaceOne({
          editor,
          markedWord: highlight,
          newText: replaceInput,
          shouldUpdateRangeWords,
          mode,
        });
        const newHighlights = getHighlightWordsByText({
          search: findInput,
          node: editor.children[highlight.anchor.path[0]] as CustomElement,
          path: [highlight.anchor.path[0]],
        });
        updatedRangeHighlights = newHighlights;
      }
    }
    clearClassname("highlightFocused");
    return true;
  } catch (e) {
    console.error(`Fail to replace all`, e);
    return false;
  }
};

const clearClassname = (className: string) => {
  const allElements = document.querySelectorAll(`.${className}`);

  allElements?.forEach((element) => {
    element.classList.remove("highlightFocused");
  });
};

const getHighlightedLeafElement = (
  editor: CustomEditor,
  word: MarkWordMetadata,
  search: string
) => {
  const _nodes = Array.from(
    editor.nodes({
      at: {
        anchor: word.anchor,
        focus: word.focus,
      },
    })
  );
  const ranges: MarkWordMetadata[] = [];
  _nodes.forEach(([node, path]) => {
    const nodeHighlights = getHighlightWordsByText({
      search,
      node: node as CustomElement,
      path,
    });
    ranges.push(...nodeHighlights);
  });
  return ranges;
};

const highlightEditorWord = ({
  editor,
  word,
  scrollIntoView,
}: {
  editor: CustomEditor;
  word: MarkWordMetadata;
  scrollIntoView: boolean;
}) => {
  const element = ReactEditor.toDOMRange(editor, {
    focus: word.focus,
    anchor: word.anchor,
  });
  if (!element) return;
  const textHtmlElement = element.endContainer
    ?.parentElement as HTMLSpanElement;
  const sameLength =
    textHtmlElement.innerText.length === word.focus.offset - word.anchor.offset;

  if (textHtmlElement && sameLength) {
    if (scrollIntoView) {
      textHtmlElement?.scrollIntoView({
        behavior: "instant",
        block: "center",
      });
    }
    textHtmlElement?.classList?.add("highlightFocused");
  }
};

const highlightTranscriptEditorWord = ({
  editor,
  word,
  shouldScroll,
}: {
  editor: CustomEditor;
  word: MarkWordMetadata;
  shouldScroll?: boolean;
}) => {
  highlightEditorWord({
    editor,
    word,
    scrollIntoView: _.isNil(shouldScroll) ? true : shouldScroll,
  });
};

const highlightSubtitleEditorWord = async ({
  editor,
  word,
  search,
  shouldScroll,
}: {
  editor: CustomEditor;
  word: MarkWordMetadata;
  search?: string; // search is required here
  shouldScroll?: boolean;
}): Promise<any> => {
  if (!search) return null;

  ReactEditor.deselect(editor);
  const updatedWords = getHighlightedLeafElement(editor, word, search); // Re calculate the word from leaf path (index of the element)
  setMediaOffsetByNode({ editor, word: updatedWords[0] });
  await freeze(1); // Freezing to ensure the element is in the DOM
  highlightEditorWord({
    editor,
    word: updatedWords[word.place - 1],
    scrollIntoView: _.isNil(shouldScroll) ? false : shouldScroll,
  });
};

const setMediaOffsetByNode = ({
  editor,
  word,
}: {
  editor: CustomEditor;
  word: MarkWordMetadata;
}) => {
  const { st } = (word.node as SubtitleRangeElement).range;
  navigateToSubtitle({ editor, st });
};

const scrollAndHighlightWord = ({
  editor,
  word,
  search,
  shouldScroll,
}: {
  editor: CustomEditor;
  word?: MarkWordMetadata;
  search?: string; // search is only needed in subtitles editor for re-calculate the highlight position
  shouldScroll?: boolean;
}) => {
  try {
    clearClassname("highlightFocused");

    if (!word) return;

    const isSubtitle = word.node.type === "subtitleRange";
    const isTranscript = ["speakersRange", "annotationRange"].includes(
      word.node.type
    );

    if (isSubtitle)
      highlightSubtitleEditorWord({ editor, word, search, shouldScroll });
    if (isTranscript)
      highlightTranscriptEditorWord({ editor, word, shouldScroll });
  } catch (e) {
    console.error("Cannot scroll, focus or select highlight element.", e);
  }
};

const glitchTextToNextRange = (
  editor: CustomEditor,
  entry: { element: CustomElement; path: number }
) => {
  const { selection } = editor;
  const isLastElement = getIsLastElement(editor, entry?.element);
  if (!selection?.anchor || !entry || isLastElement) return;

  const cursorOffset = selection.anchor.offset;
  const textToGlitch = Node.string(entry.element).slice(cursorOffset) + " ";
  const isCursorAtEnd = cursorOffset === Node.string(entry.element).length;
  if (isCursorAtEnd) return;

  Transforms.insertText(editor, textToGlitch, {
    at: {
      path: [entry.path + 1, 0],
      offset: 0,
    },
  });

  Transforms.delete(editor, {
    at: {
      anchor: { path: [entry.path, 0], offset: cursorOffset },
      focus: {
        path: [entry.path, 0],
        offset: textToGlitch.length + cursorOffset,
      },
    },
  });

  updateElementRange({
    editor,
    entry: {
      element: editor.children[entry.path] as CustomElement,
      path: entry.path,
    },
    updateRangeTimesByWords: true,
  });
  const overrideTimes = {
    st: (editor.children[entry.path] as SubtitleRangeElement).range.et,
    et: (editor.children[entry.path + 1] as SubtitleRangeElement).range.et,
  };
  updateElementRange({
    editor,
    entry: {
      element: editor.children[entry.path + 1] as CustomElement,
      path: entry.path + 1,
    },
    overrideTimes: overrideTimes,
  });
  createWaveformRanges(editor, false);
};

const deleteEntireRange = (editor: CustomEditor) => {
  const { selection } = editor;
  const entry = getLastCursoredElement(editor);
  if (!selection?.anchor || !entry) return;
  const isSubtitles = entry.element.type === "subtitleRange";
  removeNode(editor, entry.element);
  createWaveformRanges(editor, false);
  if (isSubtitles) {
    //Prevent invalid value used as weak map key crash error cause by un achievable element in slate
    navigateToPrevSubtitle({ editor, currentIdx: entry.path });
  }
};

const getRanges = (editor: CustomEditor) => {
  return editor.children
    .filter((child) => (child as CustomElement).type !== "annotationRange")
    .map(
      (child) => (child as SubtitleRangeElement | SpeakerRangeElement).range
    );
};

const getRangeIndex = ({
  editor,
  element,
  subtractPlaceholders = false,
}: {
  editor: CustomEditor;
  subtractPlaceholders?: boolean;
  element: CustomElement;
}) => {
  const idx = editor?.children?.indexOf(element);
  if (_.isNumber(idx)) {
    return idx;
  }

  const path = ReactEditor.findPath(editor, element);
  const rangeIndex = _.isNumber(path)
    ? path
    : Array.isArray(path)
    ? path[0]
    : null;
  if (!_.isNumber(rangeIndex)) {
    console.error("Range index not found");
    return;
  }
  if (subtractPlaceholders) {
    if (element?.type !== "subtitleRange") return rangeIndex;
    const placeholders = getPlaceholderRangesCount(editor, rangeIndex);
    return rangeIndex - placeholders;
  }
  return rangeIndex;
};

const isRangeChanged = ({ element }: { element: SubtitleRangeElement }) => {
  const newInputString = Node.string(element);
  const oldInputString = getPlainWordsFromRange(element.range);
  const isEqual = _.isEqual(newInputString, oldInputString);
  if (isEqual && !element.range.time_edit) return false;
  return true;
};

const getIsSubtitlesEditor = (editor: CustomEditor) => {
  if (!editor.mode) return false;
  const subtitlesEditorTypes: EditorMode[] = [
    "subtitles",
    "subtitles-translation",
  ];
  return subtitlesEditorTypes.includes(editor.mode);
};

const createWaveformRanges = (editor: CustomEditor, disabled: boolean) => {
  if (!editor || !editor.children?.length) return;

  const isSubtitlesEditor = getIsSubtitlesEditor(editor);

  if (!isSubtitlesEditor) return;

  const waveformRanges = editor.children.reduce((acc, child, index) => {
    const element = child as CustomElement;
    const { range } = element as SpeakerRangeElement;
    acc.push({
      rangeIndex: index,
      start: range.st,
      end: range.et,
      type: range.type,
    });
    return acc;
  }, [] as Array<{ rangeIndex: number; start: number; end: number; type: string }>);

  if (waveformRanges.length > 0) {
    MediaService.createWaveformRanges({ ranges: waveformRanges, disabled });
  }
};

const debouncedCreateWaveformRanges = _.debounce(createWaveformRanges, 500);

const updateWaveformRange = ({
  editor,
  indexes,
}: {
  editor: CustomEditor;
  indexes: number[];
}) => {
  if (!editor || !indexes || _.isEmpty(indexes)) return;
  const isSubtitlesEditor = editor.children.some(
    (children) => (children as CustomElement).type === "subtitleRange"
  );
  if (!isSubtitlesEditor) return;
  indexes.forEach((rangeIndex) => {
    if (!_.isNumber(rangeIndex)) return;
    const element = editor.children[rangeIndex];
    if (!("range" in element)) return;
    console.log("updatewaveformrange editorservice");
    MediaService.updateWaveformRange(element.range as JobRange, rangeIndex);
  });
};

const navigateToPrevSubtitle = ({
  editor,
  preventCursorManipulation,
  currentIdx,
}: {
  editor: CustomEditor;
  currentIdx: number;
  preventCursorManipulation?: boolean;
}) => {
  const prevElem = editor.children[currentIdx - 1] as SubtitleRangeElement;
  if (!editor.handleTimeChange || !("range" in prevElem)) {
    return;
  }
  const prevSubtitleSt = prevElem.range.st;
  if (!_.isNumber(prevSubtitleSt)) return;
  navigateToSubtitle({ editor, st: prevSubtitleSt, preventCursorManipulation });
};

const navigateToSubtitle = ({
  editor,
  st,
  preventCursorManipulation,
}: {
  editor: CustomEditor;
  st: number;
  preventCursorManipulation?: boolean;
}) => {
  if (editor.handleTimeChange) {
    editor.handleTimeChange(st, preventCursorManipulation);
  }
  MediaService.setOffset(st);
};

const getSubtitleRangeStartTime = (editor: CustomEditor, index: number) => {
  if (!editor) return -1;
  const st = (editor.children[index] as any)?.range?.st;
  return st;
};

const getCurrentSubtitleRangePlaying = ({
  editor,
  currentTime,
}: {
  editor?: CustomEditor;
  currentTime: number;
}) => {
  if (!editor) return -1;

  const rangesStartTimes = editor.children.map((children) => {
    return {
      st: (children as any)?.range?.st,
      et: (children as any)?.range?.et,
    };
  });
  const currentRangeIndex = _.findIndex(
    rangesStartTimes,
    ({ et, st }: { st: number; et: number }) => {
      return currentTime >= st && currentTime <= et;
    }
  );
  if (
    (editor.children[currentRangeIndex] as CustomElement)?.type !==
    "subtitleRange"
  )
    return -1;
  return currentRangeIndex;
};

const isRangeIntersecting = (
  element: SubtitleRangeElement,
  time?: number | null
) => {
  if (!element.range || !_.isNumber(time)) return false;
  const intersecting = element.range.st <= time && time <= element.range.et;
  return intersecting;
};

const getPlaceholderRangesCount = (
  editor: CustomEditor,
  untilIndex?: number | null
) => {
  if (!_.isNumber(untilIndex)) {
    untilIndex = editor.children.length - 1;
  }
  const children = _.isNumber(untilIndex)
    ? editor.children.slice(0, untilIndex)
    : editor.children;
  return children.filter(
    (child) => (child as CustomElement).type === "subtitlePlaceholder"
  ).length;
};

const navigateToCurrentPlayingRange = ({
  editor,
  markedWord,
  element,
  threshold = 50, // Threshold in pixels to define reasonable scroll range
  offset = 100, // Offset in pixels to keep the word comfortably in view
}: {
  editor: CustomEditor;
  element?: HTMLElement | null;
  markedWord?: MarkWordMetadata;
  threshold?: number;
  offset?: number;
}) => {
  if (!markedWord || !element) return;

  const isRange = Range.isRange(markedWord);
  if (!isRange) return;

  const domRange = ReactEditor.toDOMRange(editor, markedWord);
  const wordRect = domRange.getBoundingClientRect();

  if (!wordRect) return;

  const elementRect = element.getBoundingClientRect();
  const wordScrollFromTop = wordRect.top - elementRect.top + element.scrollTop;

  const isInView =
    wordScrollFromTop >= element.scrollTop - threshold &&
    wordScrollFromTop <= element.scrollTop + element.clientHeight + threshold;

  if (!isInView) {
    const newScrollTop = Math.max(
      wordScrollFromTop - offset,
      0 // Ensure we don't scroll to a negative value
    );

    element.scrollTo({ top: newScrollTop, behavior: "smooth" });
  }
};

const isCursorAtBoundaryLine = (
  editor: CustomEditor,
  direction: "next" | "prev"
) => {
  const { selection } = editor;

  if (selection && Range.isCollapsed(selection)) {
    const domSelection = window.getSelection();
    const domRange = domSelection?.getRangeAt(0);
    if (!domRange) return;

    const startRect = domRange.getClientRects()[0];

    const parentElement = domRange.startContainer.parentElement;
    if (!parentElement) return;
    const parentRect = parentElement.getBoundingClientRect();

    if (direction === "prev") {
      return startRect?.top === parentRect?.top;
    } else if (direction === "next") {
      return Math.round(startRect.bottom) === Math.round(parentRect.bottom);
    }
  }

  return false;
};
const isCursorAtBoundaryRange = (
  editor: CustomEditor,
  direction: "next" | "prev"
) => {
  const { selection } = editor;

  if (selection && Range.isCollapsed(selection)) {
    const [start] = Range.edges(selection);

    const path = selection.anchor.path;

    if (direction === "next") return Editor.isEnd(editor, start, path);
    else return Editor.isStart(editor, start, path);
  }

  return false;
};

const onArrowUpAndDown = (editor: CustomEditor, to: "next" | "prev") => {
  if (!editor) return;
  const selection = editor.selection;
  if (!selection) return;
  const renderIndex = getFocusedRenderIndex(editor);
  if (renderIndex === 0 && to === "prev") {
    const isCursorAtFirstLine = isCursorAtBoundaryLine(editor, to);
    if (!isCursorAtFirstLine) return;
    paginateSubtitle({ editor, to });
    return;
  }
  if (renderIndex === 2 && to === "next") {
    const isCursorAtLastLine = isCursorAtBoundaryLine(editor, to);

    if (!isCursorAtLastLine) return;
    paginateSubtitle({ editor, to });
    return;
  }
};

const onArrowLeftAndRight = (editor: CustomEditor, to: "next" | "prev") => {
  if (!editor) return;
  const selection = editor.selection;
  if (!selection) return;
  const renderIndex = getFocusedRenderIndex(editor);
  if (renderIndex === 0 && to === "prev") {
    const isCursorAtFirstLine = isCursorAtBoundaryRange(editor, to);
    if (!isCursorAtFirstLine) return;
    paginateSubtitle({ editor, to });
    return;
  }
  if (renderIndex === 2 && to === "next") {
    const isCursorAtLastLine = isCursorAtBoundaryRange(editor, to);
    if (!isCursorAtLastLine) return;
    paginateSubtitle({ editor, to });
    return;
  }
};

const onBackspace = (editor: CustomEditor, event: React.KeyboardEvent) => {
  const isEditorFocused = ReactEditor.isFocused(editor);
  if (!isEditorFocused) return;
  const renderIndex = getFocusedRenderIndex(editor);
  if (renderIndex !== 0) return;
  const isAtStart = isCursorAtBoundaryRange(editor, "prev");
  if (isAtStart) {
    // preventing backspace merge when render index is 0.
    event.preventDefault();
  }
};

const addMarks = async ({
  editor,
  marks,
  search,
  markKey,
  markValue,
}: {
  editor: CustomEditor;
  marks: MarkWordMetadata[] | null;
  markKey: keyof CustomLeafProperties;
  search: string;
  markValue?: boolean;
}) => {
  if (!marks) return;

  // Object to store the number of occurrences of each path
  const pathOccurrences: Record<string, number> = {};

  for (const mark of marks) {
    const pathIdx = mark.anchor.path[0];

    if (!pathOccurrences[pathIdx]) {
      pathOccurrences[pathIdx] = 0;
    }
    const occurrenceInRange = pathOccurrences[pathIdx];
    // Check if the current range is already marked
    if (pathOccurrences[pathIdx] > 0) {
      // If current range is already marked, re-search the next occurrence
      const _nodes = Array.from(editor.nodes({ at: [pathIdx] }));
      const ranges: MarkWordMetadata[] = [];
      _nodes.forEach(([node, path]) => {
        const nodeHighlights = getHighlightWordsByText({
          search,
          node: node as CustomElement,
          path,
        });
        ranges.push(...nodeHighlights);
      });
      ranges.forEach((nodeHighlight) => {
        editor.select({
          anchor: nodeHighlight.anchor,
          focus: nodeHighlight.focus,
        });
        editor.addMark(markKey as string, markValue ?? true);
      });
      pathOccurrences[pathIdx] += 1;
    } else {
      // If current range is not marked, mark it
      editor.select({
        anchor: mark.anchor,
        focus: mark.focus,
      });
      editor.addMark(markKey as string, markValue ?? true);
      pathOccurrences[pathIdx] = occurrenceInRange + 1;
    }
  }
};

const removeMarks = ({
  editor,
  markKey,
}: {
  editor: CustomEditor;
  markKey: keyof CustomLeafProperties;
}) => {
  const markedNodes = Array.from(
    editor.nodes({
      at: [],
      match: (node: any) => Text.isText(node) || Element.isElement(node),
    })
  );

  markedNodes?.forEach(([node, path]) => {
    Transforms.unsetNodes(editor, "highlightGreen", {
      at: path,
      match: (n: any) =>
        (Text.isText(n) || Element.isElement(node)) && (n as any)[markKey],
    });
  });
};

const isSpeakerRange = (node: Descendant) => {
  const isSpeakerRange =
    (node as CustomElement).type === "speakersRange" && "range" in node;
  return isSpeakerRange;
};

const renameSpeaker = ({
  editor,
  newSpeaker,
  oldSpeaker,
}: {
  editor: CustomEditor;
  oldSpeaker: string;
  newSpeaker: string;
}) => {
  editor.children.forEach((child) => {
    if (!isSpeakerRange(child)) return;
    const currentSpeaker = (child as SpeakerRangeElement).range.speakerName;
    if (currentSpeaker === oldSpeaker) {
      updateNodeData({
        editor,
        data: {
          range: {
            ...((child as SpeakerRangeElement).range as SpeakerRange),
            speakerName: newSpeaker,
          },
        },
        element: child as CustomElement,
      });
    }
  });
};

const getSpeakers = (editor: CustomEditor) => {
  const speakersMap: Record<string, any> = {};
  editor.children.forEach((child) => {
    const range = (child as SpeakerRangeElement).range;
    if (!range?.speakerName) return;
    if (!speakersMap[range.speakerName]) {
      speakersMap[range.speakerName] = true;
    }
  });
  const speakers = _.keys(speakersMap).map((speaker) => ({ name: speaker }));
  return speakers;
};

const getIsFirstRange = (editor: CustomEditor, index: number) => {
  const placeholdersCount = getPlaceholderRangesCount(editor, index);
  const isOnlyPlaceholderBeforeIndex = index === placeholdersCount;
  return isOnlyPlaceholderBeforeIndex;
};

export default {
  getTempSpeakerName,
  getRangeWordsFromString,
  getRangeWordsFromMultilineString,
  reorderWordsRangeIndex,
  getLangDirection,
  getJobLangKey,
  getSpeakersFromWords,
  getSplitMeetingWords,
  resetSubtitlesRanges,
  createNewSpeakerRange,
  createNewAnnotation,
  getWordsFromRanges,
  getLastPosition,
  validateJobRanges,
  reIndexWords,
  preventCut,
  addRangeIds,
  jumpToWord,
  getCursorPosition,
  formatJobDataToEditorValue,
  formatEditorValueToJobData,
  formatSpeakerRangeToSlateElement,
  formatEditorSpeakerRangeToJobData,
  formatRangeWordsToString,
  getCurrentSelectionNodeIndex,
  getLastCursoredElement,
  focusToNextSpeakerTextRange,
  focusToPrevSpeakerTextRange,
  updateNodeData,
  createSlateAnnotation,
  removeNode,
  expensivelyResetEditorState,
  getCurrentIndexByElement,
  getRangesCount,
  getRanges,
  getRangeIndex,
  isRangeChanged,
  getPlainWordsFromRange,
  getFirstWordAfterRangesBreak,
  onBreakRange,
  focusByPathOrElement,
  updateElementRange,
  onMergeRange,
  isMergeNodeAllowed,
  handleOpenSpeakers,
  jumpToSlateWord,
  getHighlightWordsByText,
  getHighlightWordsByTime,
  getHighlightWords,
  replaceOne,
  replaceAll,
  scrollAndHighlightWord,
  highlightTranscriptEditorWord,
  highlightSubtitleEditorWord,
  clearClassname,
  navigateToCurrentPlayingRange,
  glitchTextToNextRange,
  deleteEntireRange,
  createWaveformRanges,
  updateWaveformRange,
  getCurrentSubtitleRangePlaying,
  isRangeIntersecting,
  getPlaceholderRangesCount,
  focusToNextOrPrevSubtitle,
  onArrowUpAndDown,
  onArrowLeftAndRight,
  getFocusedRenderIndex,
  paginateSubtitle,
  onSkipSubtitle,
  onPrevSubtitle,
  onBackspace,
  runValidations,
  handleAddFrames,
  updateRangeTime,
  addMarks,
  removeMarks,
  formatSlateChildrenFromRange,
  updateRangeOnFrameChange,
  navigateToSubtitle,
  getSubtitleRangeStartTime,
  getSpeakers,
  renameSpeaker,
  reorderRangeWords,
  calculateRangeHeight,
};
