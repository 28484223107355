import _ from "lodash";
import { version } from "../../project.json";
import config from "../config";
import { Trans, useTranslation } from "react-i18next";
import { ErrorBoundary } from "@sentry/react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClipboardList,
  faEnvelope,
  faProjectDiagram,
  faRightFromBracket,
  faTelescope,
  faUpload,
  faCalendar,
  faMicrophone,
} from "@fortawesome/pro-light-svg-icons";
import { faSpinnerThird } from "@fortawesome/pro-duotone-svg-icons";
import { useShallow } from "zustand/react/shallow";

import {
  defaultAvatar,
  sumitLogoShort,
  sumitLogoWhite,
} from "@sumit-platforms/common-assets/images";
import {
  Layout,
  MenuItem,
  Modal,
  Navigation,
  Profile,
  Toaster,
  ContactUsModal,
  UploadManager,
  ErrorPage,
  ContextSelect,
  DisabledClientModal,
  ReAuthModal,
  Toast,
} from "@sumit-platforms/ui-bazar";
import { useAuth, useToast } from "@sumit-platforms/ui-bazar/hooks";
import { recordStore, useModal } from "@sumit-platforms/ui-bazar/store";
import { uploadStore } from "@sumit-platforms/ui-bazar/store";
import {
  getDayPeriod,
  getLanguageDirection,
} from "@sumit-platforms/ui-bazar/utils";
import { Client } from "@sumit-platforms/types";
import authService from "../services/authService";

import { useNavigationBlocker, useGlobalData } from "../store";
import { clientStore } from "../store/client";
import {
  useAttachFFContext,
  useErrorInterceptor,
  useFeatureFlag,
} from "@sumit-platforms/ui-bazar/hooks";
import { RecordBackgroundProcess } from "@sumit-platforms/ui-bazar/v3";

const Dashboard = ({ recordUtils }: { recordUtils: any }) => {
  const { t } = useTranslation();
  const {
    modalContent,
    pushModalContent,
    popModalContent,
    clearModalContent,
    modalType,
    setModalType,
    setModalContent,
  } = useModal();
  const { client, setClient } = clientStore();
  const attachFFContext = useAttachFFContext();
  const {
    getPendingUploads,
    getActiveUploads,
    getSucceedUploads,
    getProgress,
    getUploadInfoText,
    clearSucceedUploads,
  } = uploadStore((state) => state);
  const { fetchGlobalData, toast, setToast, direction, setDirection } =
    useGlobalData();
  const { isRecording, isPaused } = recordStore(
    useShallow((state) => ({
      isRecording: state.isRecording,
      isPaused: state.isPaused,
    }))
  );
  const location = useLocation();
  const { toastSuccess, toastError } = useToast({ setToast });
  const { blockNavModal } = useNavigationBlocker();
  const oldExtractFF = useFeatureFlag("oldExtract");
  const reAuth498FF = useFeatureFlag("must_498_re_auth");
  const forceLogin401FF = useFeatureFlag("must_401_force_login");
  const newUploadsFF = useFeatureFlag("must_new_upload_files");
  const newExtract = useFeatureFlag("newExtract");
  const languageSwitchFF = useFeatureFlag("languageSwitch");
  const showProjectPageFF = useFeatureFlag("must_projectPage");
  const showRecordPageFF = useFeatureFlag("must_recordPage");
  const homepageFF = useFeatureFlag("must_homepage");
  const [isSignoutLoading, setIsSignoutLoading] = useState(false);

  const { user, signOut, signInWithEmailAndPassword, resetPassword } = useAuth({
    config,
    getUserDetails: authService.getUser,
  });

  const handleSignOut = async () => {
    try {
      setIsSignoutLoading(true);
      await signOut();
    } catch (err) {
      console.error(err);
      setIsSignoutLoading(false);
    }
  };

  useEffect(() => {
    if (user && !_.isEmpty(user?.clients)) {
      let currentClient: Client | null = null;
      if (client) {
        const currentClientId = client.idClient;
        currentClient =
          user?.clients.find((c) => c.idClient === currentClientId) || null;
      }
      const clientToSet = currentClient || user.clients[0] || null;
      setClient(clientToSet);
    }
  }, [user?.clients]);

  useEffect(() => {
    if (client) fetchGlobalData({ idClient: client?.idClient });
  }, [client]);

  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const closeModal = useCallback(() => {
    setModalType("info");
    popModalContent();
  }, [clearModalContent, setModalType]);

  useEffect(() => {
    if (!user) {
      console.log("navigate to login");
      console.log(user);
      navigate("/login");
    }
  }, [user]);

  useEffect(() => {
    attachFFContext({
      userId: user?.email || user?.uid || "",
      properties: {
        clientId: client?.idClient?.toString() || "",
      },
    });
    if (client?.disabled) {
      pushModalContent(<DisabledClientModal approve={clearModalContent} />);
    }
  }, [client, user]);

  const pendingUploads = getPendingUploads();
  const activeUploads = getActiveUploads();
  const succeedUploads = getSucceedUploads();
  const uploadProgress = getProgress();
  const uploadInfoText = getUploadInfoText();

  const onNavigationMenuItemClick = (to: string) => {
    if (blockNavModal) {
      return blockNavModal({ to });
    } else {
      return navigate(to);
    }
  };

  const menuItems: MenuItem[] = useMemo(() => {
    const menuItems: MenuItem[] = [
      {
        path: "",
        label: t("menu_calendar"),
        icon: <FontAwesomeIcon icon={faCalendar} />,
        hidden: !client || !homepageFF,
        render: (children: React.ReactNode) => children,
        onClick: () => onNavigationMenuItemClick("/"),
      },
      {
        path: "extract2",
        label: `${t("menu_extract")}`,
        icon: <FontAwesomeIcon icon={faTelescope} />,
        hidden: !client || !newExtract,
        render: (children: React.ReactNode) => children,
        onClick: () => onNavigationMenuItemClick("/extract2"),
        toolTipContent: (
          <Trans
            i18nKey="extract_tool_tip_content"
            components={{ br: <br /> }}
          />
        ),
      },
      {
        path: "deliveries",
        label: t("menu_deliveries"),
        icon: <FontAwesomeIcon icon={faClipboardList} />,
        hidden: !client,
        render: (children: React.ReactNode) => children,
        onClick: () => onNavigationMenuItemClick("/deliveries"),
        toolTipContent: (
          <Trans
            i18nKey="deliveries_tool_tip_content"
            components={{ br: <br /> }}
          />
        ),
      },
      {
        path: "upload-files",
        label: t("menu_upload_files"),
        icon: <FontAwesomeIcon icon={faUpload} />,
        hidden: !client,
        render: (children: React.ReactNode) => children,
        onClick: () => onNavigationMenuItemClick("/upload-files"),
      },
      {
        path: "projects",
        label: t("menu_projects"),
        icon: <FontAwesomeIcon icon={faProjectDiagram} />,
        hidden: !client || !showProjectPageFF,
        render: (children: React.ReactNode) => children,
        onClick: () => onNavigationMenuItemClick("/projects"),
      },
      {
        path: "record",
        label: t("record"),
        icon: <FontAwesomeIcon icon={faMicrophone} />,
        hidden: !client || !showRecordPageFF,
        render: (children: React.ReactNode) => children,
        onClick: () => onNavigationMenuItemClick("/record"),
        recordIndicator: { isRecording, isPaused },
      },
      {
        label: t("menu_contact_us"),
        preLabel: t("need_help"),
        icon: <FontAwesomeIcon icon={faEnvelope} />,
        render: (children: React.ReactNode) => children,
        onClick: () => {
          pushModalContent(<ContactUsModal />);
        },
        isBottom: true,
      },
      {
        label: t("menu_logout"),
        icon: isSignoutLoading ? (
          <FontAwesomeIcon icon={faSpinnerThird} spin color={"white"} />
        ) : (
          <FontAwesomeIcon icon={faRightFromBracket} />
        ),
        render: (children: React.ReactNode) => children,
        onClick: handleSignOut,
        disabled: isSignoutLoading,
        isBottom: true,
      },
    ];

    return menuItems;
  }, [
    t,
    client,
    homepageFF,
    newExtract,
    showProjectPageFF,
    showRecordPageFF,
    isRecording,
    isPaused,
    isSignoutLoading,
    handleSignOut,
    onNavigationMenuItemClick,
    pushModalContent,
  ]);

  const handleChangeClient = (idClient: any) => {
    const client = user?.clients.find((c) => c.idClient === idClient);
    if (client) {
      setClient(client);
    }
  };

  const hideRecordFloat = useMemo(() => {
    const isRecordPage = location.pathname.includes("record");
    return isRecordPage;
  }, [location]);

  const contextSelect = useMemo(() => {
    return !_.isEmpty(user?.clients) ? (
      <ContextSelect
        isOpen={isMenuOpen}
        options={
          user?.clients?.map((c) => ({
            id: c.idClient + c.name,
            label: c.name,
            value: c.idClient,
            image: c.image,
          })) || []
        }
        onChange={handleChangeClient}
        value={client?.idClient}
      />
    ) : undefined;
  }, [user?.clients, client?.idClient, isMenuOpen]);

  const onReAuthSuccess = useCallback(() => {
    toastSuccess(t("reauth_success"));
    clearModalContent();
  }, [toastSuccess]);

  const onReAuthFail = useCallback(() => {
    toastError(t("reauth_fail"));
    clearModalContent();
  }, [toastError]);

  const onResetPasswordSuccess = useCallback(() => {
    toastSuccess(t("reset_password_success"));
    setTimeout(() => {
      clearModalContent();
    }, 10000);
  }, [toastSuccess]);

  const openReauthModal = useCallback(() => {
    if (modalContent?.length) return;
    setModalType("info");
    setModalContent(
      <ReAuthModal
        signInWithEmailAndPassword={signInWithEmailAndPassword}
        userEmail={user?.email}
        config={config}
        onSuccess={onReAuthSuccess}
        onFail={onReAuthFail}
        resetPassword={resetPassword}
        onResetPasswordSuccess={onResetPasswordSuccess}
      />
    );
  }, [
    resetPassword,
    user,
    onResetPasswordSuccess,
    onReAuthSuccess,
    onReAuthFail,
    modalContent,
    setModalContent,
    setModalType,
    signInWithEmailAndPassword,
  ]);
  useErrorInterceptor({
    signOut,
    openReauthModal,
    featureFlags: {
      reAuth498: reAuth498FF,
      forceLogin401: forceLogin401FF,
    },
  });

  return (
    <div className="App">
      <Layout
        isOpen={isMenuOpen}
        navigation={
          <Navigation
            languageSwitchFF={user?.admin || languageSwitchFF}
            openStateLogo={sumitLogoWhite}
            closeStateLogo={sumitLogoShort}
            menuItems={menuItems}
            isOpen={isMenuOpen}
            toggleIsOpen={() => setIsMenuOpen((prevIsOpen) => !prevIsOpen)}
            version={version}
            versionHistoryLink={config.versionHistory}
            contextSelect={contextSelect}
            direction={direction}
            onLanguageChange={(lang: string) =>
              setDirection(getLanguageDirection(lang))
            }
            profile={
              <Profile
                greeting={t(`${getDayPeriod()}_greeting`)}
                showDetails={isMenuOpen}
                fullName={user?.firstName + " " + user?.lastName}
                avatarUrl={defaultAvatar}
              />
            }
          />
        }
      >
        <ErrorBoundary
          showDialog
          fallback={({ error, resetError }) => (
            <ErrorPage
              title={t("something_went_wrong")}
              text={error.message}
              redirectToOnClick={"/"}
            ></ErrorPage>
          )}
          onReset={() => navigate("/")}
        >
          <Outlet />
        </ErrorBoundary>
      </Layout>
      {modalContent.map((modal, i: number) => (
        <div key={i + "modal"}>
          <Modal closeModal={closeModal} type={modalType}>
            {modal}
          </Modal>
        </div>
      ))}
      {!_.isEmpty([...pendingUploads, ...activeUploads, ...succeedUploads]) && (
        <UploadManager
          pendingUploads={pendingUploads}
          activeUploads={activeUploads}
          succeedUploads={succeedUploads}
          clearSucceedUploads={clearSucceedUploads}
          progress={uploadProgress}
          uploadInfoText={uploadInfoText}
          featureFlags={{
            newUploads: newUploadsFF,
          }}
        />
      )}
      {isRecording && !hideRecordFloat && (
        <RecordBackgroundProcess dir={direction} recordUtils={recordUtils} />
      )}
      <Toaster />
      {toast && (
        <div className="Toaster">
          <Toast severity={toast.severity}>{toast.text}</Toast>
        </div>
      )}
    </div>
  );
};

export default Dashboard;
