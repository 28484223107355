import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { Button } from "../../../../index";
import React from "react";

interface SubmissionActionsProps {
  isUploadSuccess: boolean;
  submitDisabled: boolean;
  handleUpload: () => void;
  onUploadMoreClick: () => void;
  toDeliveriesPage: () => void;
  onEditUploadClick: () => void;
  uploadError?: { message?: string };
}

export const SubmissionActions = ({
  isUploadSuccess,
  submitDisabled,
  handleUpload,
  onUploadMoreClick,
  onEditUploadClick,
  toDeliveriesPage,
  uploadError,
}: SubmissionActionsProps) => {
  const { t } = useTranslation();

  return (
    <Box mt={1} display="flex" gap={4}>
      {isUploadSuccess ? (
        <>
          <Button onClick={onUploadMoreClick} variant="outlined">
            {t("upload_more")}
          </Button>
          <Button onClick={toDeliveriesPage}>{t("close")}</Button>
        </>
      ) : uploadError?.message ? (
        <>
          <Button onClick={onEditUploadClick} variant="outlined">
            {t("edit_upload")}
          </Button>
          <Button disabled={submitDisabled} onClick={handleUpload}>
            {uploadError ? t("try_again") : t("submit")}
          </Button>
        </>
      ) : (
        <Button disabled={submitDisabled} onClick={handleUpload}>
          {t("submit")}
        </Button>
      )}
    </Box>
  );
};
