import React, { useState, useRef, useEffect } from "react";
import _ from "lodash";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import { IconButton } from "../../../index";
import { faEdit } from "@fortawesome/pro-light-svg-icons";
import { SxProps, Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Variant } from "@mui/material/styles/createTypography";

import "./EditableText.scss";
import classNames from "classnames";

interface Props {
  text: string;
  onSave: (text: string) => void;
  variant?: Variant;
  editOnIconClickOnly?: boolean;
  disabled?: boolean;
  textStyle?: SxProps;
}

export const EditableText = ({
  text = "",
  onSave,
  variant = "body1",
  editOnIconClickOnly = true,
  textStyle,
  disabled,
}: Props) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [value, setValue] = useState(text);
  const inputRef = useRef<any>(null);
  const textRef = useRef<any>(null);

  useEffect(() => {
    setValue(text);
  }, [text]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [isEditing]);

  const handleClick = () => {
    if (!editOnIconClickOnly) {
      setIsEditing(true);
    }
  };

  const handleIconClick = (e: any) => {
    e.stopPropagation();
    setIsEditing(true);
  };

  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  const handleBlur = () => {
    if (value !== text) {
      onSave(value);
    }
    setIsEditing(false);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      if (value !== text) {
        onSave(value);
      }
      setIsEditing(false);
    } else if (e.key === "Escape") {
      setValue(text);
      setIsEditing(false);
    }
  };

  return (
    <Box
      className={classNames("BazarEditableText", { disableEdit: disabled })}
      onClick={handleClick}
    >
      <Typography
        variant={variant as any}
        ref={textRef}
        className="editableTextText"
        sx={textStyle}
      >
        {isEditing ? (
          <TextField
            className="editableTextInput"
            inputRef={inputRef}
            value={value}
            onChange={handleChange}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            variant="standard"
            autoFocus
            sx={{
              height: textRef.current?.clientHeight || 40,
              width: textRef.current?.clientWidth || 40,
            }}
            inputProps={{
              style: {
                padding: 0,
                margin: 0,
                ...((textStyle as any) || {}),
              },
            }}
          />
        ) : (
          text
        )}
        <span className="iconWrapper">
          <IconButton
            size="small"
            onClick={handleIconClick}
            icon={faEdit}
            tooltipText={t("rename")}
            className="editableTextIcon"
          />
        </span>
      </Typography>
    </Box>
  );
};
