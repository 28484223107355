import { create } from "zustand";
import _ from "lodash";
import { UploadFileForm } from "@sumit-platforms/types";

type UpdateUploadsSettings = <T extends keyof UploadFileForm>({
  field,
  data,
}: {
  field: T;
  data: UploadFileForm[T];
}) => void;

export type RecordStore = {
  isRecording: boolean;
  setIsRecording: (isRecording: boolean) => void;
  isPaused: boolean;
  mediaBlobUrl: string;
  setIsPaused: (isPaused: boolean) => void;
  setMediaBlobUrl: (mediaBlobUrl?: string) => void;
  uploadSettings: UploadFileForm;
  updateUploadSettings: UpdateUploadsSettings;
  setUploadSettings: (uploadSettings: UploadFileForm) => void;
};

export const recordStore = create<RecordStore>((set, get) => ({
  isRecording: false,
  isPaused: false,
  mediaBlobUrl: "",
  setIsRecording: (isRecording: boolean) => {
    set((state: RecordStore) => {
      return { ...state, isRecording };
    });
  },
  setIsPaused: (isPaused: boolean) => {
    set((state: RecordStore) => {
      return { ...state, isPaused };
    });
  },
  setMediaBlobUrl: (mediaBlobUrl?: string) => {
    set((state: RecordStore) => {
      return { ...state, mediaBlobUrl: mediaBlobUrl || "" };
    });
  },

  uploadSettings: {},
  setUploadSettings: (uploadSettings: UploadFileForm) => {
    set((state: RecordStore) => {
      return { ...state, uploadSettings };
    });
  },
  updateUploadSettings: ({ data, field }) => {
    set((state: RecordStore) => {
      const uploadSettings = _.set(state.uploadSettings, field, data);
      return { ...state, uploadSettings };
    });
  },
}));
