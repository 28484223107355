import React from "react";
import { DirectionProvider } from "@radix-ui/react-direction";
import { Root } from "@radix-ui/react-menubar";
import { MenubarCategories } from "./MenuBarCategories";
import { MenubarTitle } from "./MenuBarTitle";
import { MenuBarFeaturedButtons } from "./MenuBarFeaturedButtons";
import { MenuBarBadges } from "./MenuBarBadges";
import { Box } from "@mui/material";
import { MenuBar } from "@sumit-platforms/types";

import "./MenuBar.scss";

export const Menubar = ({ config }: { config: MenuBar }) => {
  return (
    <DirectionProvider dir={config.direction || "ltr"}>
      <Root className="MenubarRoot">
        <MenubarCategories categories={config.categories} />
        <Box className={"MenubarTitle"}>
          <MenubarTitle title={config.menuTitle} />
          <MenuBarBadges badges={config.badges} />
        </Box>
        <MenuBarFeaturedButtons buttons={config.featuredButtons} />
      </Root>
    </DirectionProvider>
  );
};
