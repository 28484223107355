import config from "../../config";
import { Button } from "@sumit-platforms/ui-bazar";
import { useAuth } from "@sumit-platforms/ui-bazar/hooks";
import { useTranslation } from "react-i18next";
import authService from "../../services/authService";
import { sumitLogoBlack } from "@sumit-platforms/common-assets/images";
import { useState } from "react";
import { useSearchParams, Link } from "react-router-dom";
import Box from "@mui/material/Box";

export default function PreLogin() {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const { user, signIn, signOut } = useAuth({
    config,
    getUserDetails: authService.getUser,
  });
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleSignIn = async () => {
    try {
      setIsLoading(true);
      await signIn(searchParams.get("callbackUrl"));
    } catch (err) {
      console.error(err);
      setError(t("signin_error"));
      setIsLoading(false);
    }
  };
  const handleSignOut = async () => {
    try {
      setIsLoading(true);
      await signOut();
    } catch (err) {
      console.error(err);
      setError(t("signout_error"));
      setIsLoading(false);
    }
  };

  return (
    <div className="PreLoginPage">
      <div className="authContainer">
        <div className="headerTitle">
          <img src={sumitLogoBlack} alt="Sumit-AI" className="headerLogo" />
        </div>
        <h1 className="preloginTitle">{t("prelogin_welcome_back")}</h1>
        <p className="preloginDescription">
          {t("prelogin_login_instructions")}
        </p>
        <Box mb={4}>
          <p className="preloginNote">{t("prelogin_files_unchanged")}</p>
        </Box>
        <div className="userPasswordContainer">
          {user ? (
            <Button onClick={handleSignOut} loading={isLoading}>
              {t("sign_out")}
            </Button>
          ) : (
            <Button onClick={handleSignIn} loading={isLoading}>
              {t("sign_in")}
            </Button>
          )}
          <span className="error">{error}</span>
        </div>
        <div className="sign_up">
          <span>{t("doenst_have_an_account")} </span>{" "}
          <Link to={"/signup"} className="sign_up_link">
            {t("sign_up_here")}{" "}
          </Link>
        </div>
      </div>
      {/* <Box className="footer" my={1}>
        <Link
          to={"https://sumit-ai.com/privacy-policy"}
          className="privacy-link"
        >
          {t("privacy_policy")}
        </Link>
      </Box> */}
    </div>
  );
}
