import React, {
  ChangeEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import _ from "lodash";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import { UploadsManagerWrapper } from "./UploadsManager/UploadsManagerWrapper";
import { Submission } from "./Submission";
import { useTranslation } from "react-i18next";
import { SetOrder } from "./SetOrder/SetOrder";
import { useAuth, useNewUploads } from "../../../hooks";
import {
  ActiveUpload,
  allowUploadTypes,
  Client,
  DEFAULT_UPLOAD_SETTINGS,
  DiarizeTypes,
  Direction,
  fileInputAccept,
  JobTranscriptionSource,
  LanguageData,
  mediaMimeTypes,
  Project,
  ProjectPage,
  Upload,
  UploadFileForm,
  UploadStatus,
} from "@sumit-platforms/types";
import {
  CallbackDoc,
  PickerCallback,
} from "react-google-drive-picker/dist/typeDefs";
import { JobService, ProjectService } from "../../../services";
import useDrivePicker from "react-google-drive-picker";
import { extractFileNameWithoutExtension, isVideo } from "../../../utils";
import { ThemeProvider } from "@mui/material";
import { bazarTheme } from "../../bazar-theme";

import "./NewUploadFiles.scss";

export type UpdateClientUploadsSettings = <T extends keyof UploadFileForm>({
  field,
  data,
}: {
  field: T;
  data: UploadFileForm[T];
}) => void;

export type UploadsPageFeatureFlags = {
  concatMedia?: boolean;
};

interface NewUploadsProps {
  config: any;
  client: Client;
  toast: {
    toastInfo: (text: string) => void;
    toastSuccess: (text: string) => void;
    toastError: (text: string, error?: unknown) => void;
    toastWarning: (text: string, error?: unknown) => void;
  };
  featureFlags?: {
    concatMedia?: boolean;
  };
  languages: LanguageData[];
  dir: Direction;
  onlyUserUploads?: boolean;
}

const MAX_SIZE_IN_GB = 4;
const MAX_SIZE_IN_BYTES = MAX_SIZE_IN_GB * 1024 * 1024 * 1024;
const MAX_MULTI_TRACK_SEC_GAP = 120; // 2 minutes in seconds

export const NewUploads = ({
  config,
  client,
  toast,
  featureFlags,
  languages,
  dir,
  onlyUserUploads = false,
}: NewUploadsProps) => {
  const { user } = useAuth({ config });
  const navigate = useNavigate();
  const [openPicker, authResponse] = useDrivePicker();
  const [projects, setProjects] = useState<ProjectPage[]>([]);
  const [inputKey, setInputKey] = useState(Date.now());
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isUploadSuccess, setIsUploadSuccess] = useState(false);
  const [uploadError, setUploadError] = useState<{ message?: string }>({});
  const inputFileRef = useRef<HTMLInputElement | null>(null);
  const projectService = useMemo(() => ProjectService({ config }), []);
  const jobService = useMemo(() => JobService({ config }), []);

  const { t } = useTranslation();
  const {
    handleCreateUpload,
    getUploads,
    uploadForm,
    updateUploadForm,
    pendingUploads,
    uppyInstances,
    uploads,
    activeUploads,
    progress,
    deleteUploads,
    startUploadQueue,
    handleUploadCancel,
    uploadSourceFile,
    uploadAAFFile,
    updateUploads,
  } = useNewUploads({
    config,
    user,
    client,
    defaultSettings: DEFAULT_UPLOAD_SETTINGS,
    onlyUserUploads,
  });

  const [idUploadOrder, setIdUploadOrder] = useState<(number | string)[]>([]);

  useEffect(() => {
    const allUploads = [...activeUploads, ...pendingUploads, ...uploads];
    const allIds = allUploads.map((upload) => upload.idUpload);

    // Only update the order if we have new ids not in our current order
    if (
      allIds.some((id) => !idUploadOrder.includes(id)) ||
      idUploadOrder.some((id) => !allIds.includes(id))
    ) {
      setIdUploadOrder(allIds);
    }
  }, [activeUploads, pendingUploads, uploads]);

  const sortedUploads: (Upload | ActiveUpload)[] = useMemo(() => {
    const allUploads = [...activeUploads, ...pendingUploads, ...uploads];

    const uploadsMap = new Map(
      allUploads.map((upload) => [upload.idUpload, upload])
    );

    const orderedUploadsIds = idUploadOrder.filter((id) => uploadsMap.has(id));

    const newUploadsIds = allUploads
      .filter((upload) => !orderedUploadsIds.includes(upload.idUpload as any))
      .map((upload) => upload.idUpload);

    const orderedIds = [...newUploadsIds, ...orderedUploadsIds];

    const orderedUploads = orderedIds.map((id) => uploadsMap.get(id));

    return orderedUploads.filter(Boolean) as (Upload | ActiveUpload)[];
  }, [activeUploads, pendingUploads, uploads, idUploadOrder]);

  const handleProjectCreate = useCallback(
    async (projectName: string): Promise<Project | null> => {
      try {
        if (!client?.idClient || !projectService) return null;
        const project = await projectService.createNewProject({
          idClient: client.idClient,
          name: projectName,
        });

        setProjects((prev) => [{ ...project, settings: {} }, ...prev]);
        toast.toastSuccess(t("project_creation_succeed"));
        return project;
      } catch (err) {
        toast.toastError(t("project_creation_failed"));
        return null;
      }
    },
    [client.idClient]
  );

  useEffect(() => {
    const getProjects = async () => {
      if (!client?.idClient || !projectService) return;
      const projects = await projectService?.getClientProjects(
        client.idClient,
        true // TODO: whats that?
      );
      setProjects(projects as ProjectPage[]);
    };
    getProjects();
  }, [client?.idClient]);

  const handleOnUpload = useCallback(
    ({
      files,
      attachedFiles,
      googleDriveToken,
    }: {
      files: File[] | CallbackDoc[];
      attachedFiles?: Record<string, File[]>;
      googleDriveToken?: string;
    }) => {
      handleCreateUpload({
        files,
        attachedFiles,
        googleDriveToken,
        onFail: () => toast.toastError(t("upload_failed")),
        client: client as Client,
      });
    },
    [client, user, handleCreateUpload, toast, t]
  );
  const handleGoogleDrivePick = useCallback(
    (data: PickerCallback) => {
      if (data?.docs) {
        const allowedFiles = data.docs.filter((d) =>
          allowUploadTypes.includes(d.mimeType)
        );
        if (data?.docs.length > allowedFiles.length) {
          toast.toastError(t("not_allowed_files_error"));
        }
        handleOnUpload({
          files: allowedFiles,
          googleDriveToken: authResponse?.access_token,
        });
      }
    },
    [authResponse, handleOnUpload]
  );

  const handleNewFiles = (newFiles: File[]) => {
    const { newAafs, newSrts, newMediaFiles } = _.groupBy(newFiles, (file) => {
      const lowerName = file.name.toLowerCase();
      if (lowerName.endsWith(".aaf")) return "newAafs";
      if (lowerName.endsWith(".srt")) return "newSrts";
      return "newMediaFiles";
    });

    const uniqFiles = _.uniqBy(newMediaFiles || [], "name");
    const uniqAafs = _.uniqBy(newAafs || [], "name");
    const uniqSrts = _.uniqBy(newSrts || [], "name");

    const attachedFiles = getAttachedFiles({
      files: uniqFiles,
      aafs: uniqAafs,
      srts: uniqSrts,
    });

    handleOnUpload({
      files: uniqFiles,
      attachedFiles,
    });
  };

  const getMatchedFileToExternal = ({
    files,
    externalFile,
  }: {
    files: File[];
    externalFile: File;
  }) => {
    const externalFileName = extractFileNameWithoutExtension(externalFile.name);
    const matchedFile = files.find((file) => {
      const fileName = extractFileNameWithoutExtension(file.name);
      return externalFileName === fileName;
    });
    return matchedFile;
  };

  const attachExternalFilesToMedia = ({
    files,
    externalFiles,
    type,
  }: {
    files: File[];
    externalFiles: File[];
    type: "aaf" | "srt";
  }) => {
    const attachedFiles: Record<string, File[]> = {};

    for (const externalFile of externalFiles) {
      const matchedFile = getMatchedFileToExternal({
        files,
        externalFile,
      });

      if (!matchedFile) continue;

      const fileName = matchedFile.name;
      if (_.isEmpty(attachedFiles[fileName])) {
        attachedFiles[fileName] = [];
      }

      const alreadyExists = attachedFiles[fileName].some((file) => {
        return file.name === externalFile.name;
      });

      if (!alreadyExists) {
        attachedFiles[fileName].push(externalFile);
      }
    }

    return attachedFiles;
  };

  const getAttachedFiles = ({
    files,
    aafs,
    srts,
  }: {
    files: File[];
    aafs: File[];
    srts: File[];
  }) => {
    const attachedAafs = attachExternalFilesToMedia({
      files,
      externalFiles: aafs,
      type: "aaf",
    });

    const attachedSrts = attachExternalFilesToMedia({
      files,
      externalFiles: srts,
      type: "srt",
    });

    // Merge AAF and SRT attachments for each media file
    const mergedAttachments: Record<string, File[]> = {};

    const allMediaFiles = [
      ...Object.keys(attachedAafs),
      ...Object.keys(attachedSrts),
    ];
    const uniqueMediaFiles = _.uniq(allMediaFiles);

    for (const mediaFile of uniqueMediaFiles) {
      mergedAttachments[mediaFile] = [
        ...(attachedAafs[mediaFile] || []),
        ...(attachedSrts[mediaFile] || []),
      ];
    }

    return mergedAttachments;
  };

  const handleOnFilesUpload = useCallback(
    (files: File[]) => {
      const allowedFiles = files.filter((file) => {
        if (file.size > MAX_SIZE_IN_BYTES) {
          toast.toastError(t("file_size_error"));
          return false;
        }
        return true;
      });
      if (isUploadSuccess) setIsUploadSuccess(false);

      handleNewFiles(allowedFiles);
    },
    [handleNewFiles, isUploadSuccess]
  );

  const handleRemoveUploads = useCallback(
    (uploadIds: number[]) => {
      return deleteUploads(uploadIds);
    },
    [deleteUploads]
  );

  const handleCancelUpload = useCallback(
    async (idUpload: number) => {
      handleUploadCancel(idUpload);
      startUploadQueue();
    },
    [handleRemoveUploads, uppyInstances]
  );

  const handleUploadTrash = useCallback(
    async (upload: Upload) => {
      try {
        if (upload.status === UploadStatus.uploading) {
          await handleCancelUpload(upload.idUpload);
        } else {
          await handleRemoveUploads([upload.idUpload]);
        }
        toast.toastSuccess(t("delete_done"));
      } catch (e) {
        console.error("Failed to remove upload", e);
        toast.toastError(t("delete_failed"));
      }
    },
    [handleRemoveUploads, uppyInstances, handleCancelUpload]
  );

  const handleBrowseFilesChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (!e.target.files) return;
      handleOnFilesUpload(Array.from(e.target.files));
      setInputKey(Date.now);
    },
    [handleOnFilesUpload, isUploadSuccess]
  );

  const openFilesBrowse = (e?: any) => {
    e?.preventDefault();
    e?.stopPropagation();
    if (inputFileRef.current) inputFileRef.current.click();
  };

  const openDrivePicker = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    openPicker({
      clientId: config.googleAuthProvider.clientId,
      developerKey: config.googleAuthProvider.apiKey,
      viewId: "DOCS",
      showUploadView: true,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      viewMimeTypes: mediaMimeTypes.join(","),
      callbackFunction: handleGoogleDrivePick,
    });
  };

  const onUploadMoreClick = useCallback(() => {
    setIsUploadSuccess(false);
    setUploadError({});
    setIsSubmitLoading(false);
    openFilesBrowse();
  }, []);

  const onUploadsUpdate: UpdateClientUploadsSettings = useCallback(
    async ({ field, data }) => {
      try {
        updateUploadForm({
          data,
          field,
        });
      } catch (e: any) {
        console.error(e);
        toast.toastError(t("failed_to_update_upload"));
      }
    },
    [updateUploadForm]
  );

  const isFormValid = useMemo(() => {
    const hasJobType = _.has(uploadForm, "jobType.typeName");
    const hasAutoDeliveryDecision = _.has(uploadForm, "jobType.autoTranscript");
    const hasInputLanguage = _.has(uploadForm, "inputLanguage");
    const successUploads = uploads.filter(
      (u) => u.status === UploadStatus.success
    );
    return (
      hasJobType &&
      hasAutoDeliveryDecision &&
      hasInputLanguage &&
      successUploads.length > 0
    );
  }, [uploadForm, uploads]);

  const attachTranscriptionOnSubmit = async (
    updatedClientUploadSettings: UploadFileForm
  ) => {
    const fileInfo = await uploadSourceFile(
      uploadForm.tempTranscriptionFile as File
    );
    return {
      ...updatedClientUploadSettings,
      srcFile: fileInfo,
      jobType: {
        ...(updatedClientUploadSettings.jobType || {}),
        transcriptionSource: JobTranscriptionSource.SOURCE_FILE,
      } as UploadFileForm["jobType"],
    };
  };

  const attachAAFOnSubmit = async (
    updatedClientUploadSettings: UploadFileForm
  ) => {
    const fileInfo = await uploadAAFFile(uploadForm.tempAAFFile as File);
    return {
      ...updatedClientUploadSettings,
      settings: {
        ...(updatedClientUploadSettings.settings || {}),
        aaf: fileInfo,
      },
    };
  };

  const getUpdatedUploadSettings = useCallback(() => {
    const updatedUploadSettings = _.clone(uploadForm);

    if (updatedUploadSettings.jobType?.typeName === "subtitles") {
      // In case of subtitles, we dont want the diarize params or unsupervised
      if (updatedUploadSettings.settings?.diarize?.diarize === "unsupervised") {
        delete updatedUploadSettings.settings?.diarize;
      }
      if (updatedUploadSettings.settings?.diarize?.diarize_param) {
        delete updatedUploadSettings.settings?.diarize?.diarize_param;
      }
    }
    return updatedUploadSettings;
  }, [uploadForm]);

  const submitUploads = async () => {
    try {
      setIsSubmitLoading(true);
      setUploadError({});
      const uploadIds = uploads.map((u) => u.idUpload);
      let updatedUploadForm = getUpdatedUploadSettings();

      if (uploadForm.tempTranscriptionFile) {
        updatedUploadForm = await attachTranscriptionOnSubmit(
          updatedUploadForm
        );
      }

      if (uploadForm.tempAAFFile) {
        updatedUploadForm = await attachAAFOnSubmit(updatedUploadForm);
      }

      await jobService.newCreate({
        uploadIds,
        uploadForm: updatedUploadForm,
        idUploadOrder: idUploadOrder as number[],
      });
      await getUploads();
      setIsUploadSuccess(true);
      toast.toastSuccess(t("job_submit_success"));
    } catch (err) {
      toast.toastError(t("job_submit_fail"));
      setUploadError({ message: t("job_submit_fail") });
    } finally {
      setIsSubmitLoading(false);
    }
  };

  const isAllMediasSameDuration = useMemo(() => {
    if (!uploads || uploads.length <= 1) return true;

    const durations = uploads.map((upload) => upload?.media[0]?.duration || 0);

    const minDuration = Math.min(...durations);
    const maxDuration = Math.max(...durations);

    return maxDuration - minDuration <= MAX_MULTI_TRACK_SEC_GAP;
  }, [uploads?.length]);

  const isAllMediasSameMediaType = useMemo(() => {
    if (!uploads || uploads.length <= 1) return true;

    const isAllVideo = uploads.every((upload) =>
      upload.media?.every((media) => isVideo(media.mimetype))
    );
    const isAllAudio = uploads.every((upload) =>
      upload.media?.every((media) => !isVideo(media.mimetype))
    );

    return isAllVideo || isAllAudio;
  }, [uploads?.length]);

  const isMultiTrackDisabled = useMemo(() => {
    const isSingleUpload = uploads?.length <= 1;
    return isSingleUpload || !isAllMediasSameDuration;
  }, [isAllMediasSameDuration, uploads]);

  const isConcatDisabled = useMemo(() => {
    const isSingleUpload = uploads?.length <= 1;
    return isSingleUpload || !isAllMediasSameMediaType;
  }, [isAllMediasSameDuration, uploads]);

  useEffect(() => {
    if (!isAllMediasSameDuration && uploadForm.multiTrackMedia) {
      onUploadsUpdate({
        field: "multiTrackMedia",
        data: false,
      });
    }
  }, [isAllMediasSameDuration]);

  const onEditUploadClick = useCallback(() => {
    setUploadError({});
  }, []);

  const navigateToDeliveries = useCallback(() => {
    navigate("/");
  }, [navigate]);

  const onUploadNameEdit = useCallback(
    (name: string, idUpload: number) => {
      updateUploads([idUpload], { name });
    },
    [updateUploads]
  );

  const onOrderChange = (uploads: (Upload | ActiveUpload)[]) => {
    const ids = uploads.map((upload) => upload.idUpload);
    setIdUploadOrder(ids);
  };

  return (
    <ThemeProvider theme={bazarTheme}>
      <Box className={"NewUploadsFilesContent"}>
        <input
          key={inputKey} // Changing the key as method to allow upload the exact same file twice
          type={"file"}
          className={"hiddenInput"}
          ref={inputFileRef}
          onChange={handleBrowseFilesChange}
          multiple
          accept={fileInputAccept(allowUploadTypes)}
          max={MAX_SIZE_IN_BYTES}
        />
        <UploadsManagerWrapper
          uploads={sortedUploads}
          onOrderChange={onOrderChange}
          progress={progress}
          handleOnUpload={handleOnFilesUpload}
          handleRemoveUpload={handleUploadTrash}
          openBrowseFiles={openFilesBrowse}
          openDrivePicker={openDrivePicker}
          uploadSizeLimitInGB={MAX_SIZE_IN_GB}
          uploadState={uploadForm}
          onUploadNameEdit={onUploadNameEdit}
          featureFlags={featureFlags}
        />
        <Box className={"OrderAndSubmissionWrapper"}>
          <SetOrder
            uploadState={uploadForm}
            onUploadsUpdate={onUploadsUpdate}
            config={config}
            projects={projects}
            handleProjectCreate={handleProjectCreate}
            idClient={client.idClient}
            validations={client.validations}
            defaultMultiTrackFileName={uploads ? uploads[0]?.name : ""}
            isAllMediasSameDuration={isAllMediasSameDuration}
            isMultiTrackDisabled={isMultiTrackDisabled}
            isConcatDisabled={isConcatDisabled}
            maxGapInMinForMultiTrack={MAX_MULTI_TRACK_SEC_GAP / 60}
            featureFlags={featureFlags}
            languages={languages}
            dir={dir}
          />
          <Submission
            uploadState={uploadForm}
            onUploadsUpdate={onUploadsUpdate}
            handleUpload={submitUploads}
            submitDisabled={!isFormValid || isSubmitLoading}
            isSubmitLoading={isSubmitLoading}
            uploadError={uploadError}
            isUploadSuccess={isUploadSuccess}
            onUploadMoreClick={onUploadMoreClick}
            toDeliveriesPage={navigateToDeliveries}
            onEditUploadClick={onEditUploadClick}
            dir={dir}
          />
        </Box>
      </Box>
    </ThemeProvider>
  );
};
