import React from "react";
import { bazarTheme, Button } from "../../../../../../index";
import { Box, ThemeProvider, Typography } from "@mui/material";

import "./DeleteRecordModal.scss";
import { useTranslation } from "react-i18next";

interface Props {
  onApprove: () => void;
  onCancel: () => void;
}

export const DeleteRecordModal = ({ onApprove, onCancel }: Props) => {
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={bazarTheme}>
      <Box
        className={"DeleteRecordModal"}
        p={3}
        height={"100%"}
        maxHeight={"100%"}
        width={"40vw"}
      >
        <Typography mb={2} variant={"h3"}>
          {t("delete_record_title")}
        </Typography>
        <Typography variant={"subtitle1"}>
          {t("delete_record_subtitle")}
        </Typography>
      </Box>
      <Box width={"100%"} p={2} display={"flex"} gap={2}>
        <Button
          variant={"outlined"}
          sx={{ marginInlineStart: "auto" }}
          onClick={onCancel}
        >
          {t("cancel")}
        </Button>
        <Button onClick={onApprove}>{t("remove")}</Button>
      </Box>
    </ThemeProvider>
  );
};
