import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { type UpdateClientUploadsSettings } from "../../../../../index";
import { Switch } from "../../../../../v3";
import { ProjectsDropdown } from "../../../ProjectsDropdown/ProjectsDropdown";
import { TagsSelectWrapper } from "./TagsSelectWrapper";
import {
  Direction,
  Project,
  Upload,
  UploadFileForm,
} from "@sumit-platforms/types";

interface OrganizeYourMediaProps {
  showRepresentative: boolean;
  config: any;
  uploadState: UploadFileForm;
  onUploadsUpdate: UpdateClientUploadsSettings;
  idClient: number;
  dir?: Direction;
}

export const OrganizeYourMedia = ({
  showRepresentative,
  config,
  onUploadsUpdate,
  uploadState,
  idClient,
  dir,
}: OrganizeYourMediaProps) => {
  const { t } = useTranslation();
  return (
    <Box className={"OrganizeYourMedia"} py={0.5}>
      <Typography variant={"h3"}>{t("organize_your_media")}:</Typography>
      <Typography variant={"subtitle2"} py={0.5}>
        {t("organize_your_media_subtitle")}:
      </Typography>
      <TagsSelectWrapper
        config={config}
        onUploadsUpdate={onUploadsUpdate}
        uploadState={uploadState}
        idClient={idClient}
        dir={dir}
      />
      {showRepresentative && (
        <Switch
          className={"representativeSwitch"}
          width={"450px"}
          label={t("representative_switch")}
          checked={uploadState.jobType?.representative}
          id={"representativeSwitch"}
          onChange={(e) =>
            onUploadsUpdate({
              data: e.target.checked,
              field: "jobType.representative" as any,
            })
          }
          reverse
        />
      )}
    </Box>
  );
};
