import React, { useCallback } from "react";
import { Tag } from "../../../../v3";
import Box from "@mui/material/Box";
import { Direction, Tag as ITag } from "@sumit-platforms/types";

interface Props {
  values: ITag[];
  letterLimit?: number;
  onDelete: (idTag: number) => void;
  dir?: Direction;
}

export const SelectTags = ({ letterLimit, onDelete, values, dir }: Props) => {
  if (!values) return null;

  const handleDelete = useCallback(
    (tag: ITag) => {
      onDelete(tag.idTag);
    },
    [onDelete]
  );

  return (
    <Box className={"NewSelectTagsWrapper"} py={0.5}>
      {values.map((tag) => (
        <Tag
          key={tag.idTag}
          label={tag.name}
          onDelete={() => handleDelete(tag)}
          letterLimit={letterLimit}
          dir={dir}
        />
      ))}
    </Box>
  );
};
