import React from "react";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import _ from "lodash";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronsLeft,
  faChevronsRight,
} from "@fortawesome/pro-solid-svg-icons";
import { Box, MenuItem, ThemeProvider, Tooltip } from "@mui/material";

import { bazarTheme } from "../../bazar-theme";
import { BadgeWrapper } from "../BadgeWrapper/BadgeWrapper";
import { UserRole } from "@sumit-platforms/types";
import { LanguageSelect } from "../LanguageSelect/LanguageSelect";
import { faPause } from "@fortawesome/pro-light-svg-icons";

import "./Navigation.scss";

export interface MenuItem {
  label: string;
  render: (children: React.ReactNode, index?: number) => React.ReactNode;
  path?: string;
  icon?: JSX.Element;
  hidden?: boolean;
  roles?: UserRole[] | ["*"];
  isBottom?: boolean;
  onClick?: any;
  recordIndicator?: {
    isRecording: boolean;
    isPaused: boolean;
  };
  preLabel?: string;
  badge?: {
    open: string;
    close?: string;
  };
  toolTipContent?: string | React.ReactNode;
  disabled?: boolean;
}

export interface NavigationProps {
  menuItems: MenuItem[];
  isOpen?: boolean;
  toggleIsOpen?: () => void;
  openStateLogo?: string;
  closeStateLogo?: string;
  profile?: JSX.Element;
  searchInput?: JSX.Element;
  contextSelect?: JSX.Element;
  direction?: "ltr" | "rtl";
  onLanguageChange?: (lang: string) => void;
  version?: string;
  versionHistoryLink?: string;
  languageSwitchFF?: boolean;
}

const MenuItemButton = ({
  menuItem,
  isOpen,
  disabled,
}: {
  menuItem: MenuItem;
  isOpen: boolean;
  disabled?: boolean;
}) => {
  const location = useLocation();
  const handleOnMenuItemClick = () => {
    menuItem.onClick && menuItem.onClick();
  };
  return (
    <>
      {menuItem.render(
        <Tooltip
          title={!isOpen ? menuItem.toolTipContent || menuItem.label : ""}
          arrow
          placement="right"
        >
          <div
            className={classNames("menuItem", {
              active: location.pathname.split("/")[1] === `${menuItem.path}`,
              disabled: menuItem.disabled,
            })}
            onClick={handleOnMenuItemClick}
          >
            {menuItem.icon && (
              <BadgeWrapper
                invisible={isOpen}
                className={"closeBadge"}
                badgeContent={menuItem.badge?.close}
              >
                <div className={classNames("menuItemIcon")}>
                  {menuItem.recordIndicator?.isRecording && (
                    <span
                      className={classNames("recordLabel", {
                        pulse: !menuItem.recordIndicator?.isPaused,
                      })}
                    >
                      {menuItem.recordIndicator?.isPaused ? (
                        <FontAwesomeIcon icon={faPause} size={"lg"} />
                      ) : (
                        "REC"
                      )}
                    </span>
                  )}
                  {menuItem.icon}
                </div>
              </BadgeWrapper>
            )}
            {isOpen && (
              <div
                className={classNames("menuItemLabel", {
                  disabled: menuItem.disabled,
                })}
              >
                {menuItem.preLabel && (
                  <div className="preLabel">{menuItem.preLabel}</div>
                )}
                <BadgeWrapper
                  className={"openBadge"}
                  badgeContent={menuItem.badge?.open}
                >
                  {menuItem.label}
                </BadgeWrapper>
              </div>
            )}
          </div>
        </Tooltip>
      )}
    </>
  );
};

export const Navigation = ({
  openStateLogo,
  closeStateLogo,
  menuItems,
  isOpen = false,
  toggleIsOpen,
  profile,
  searchInput,
  contextSelect,
  version,
  versionHistoryLink,
  languageSwitchFF,
  onLanguageChange,
  direction,
}: NavigationProps) => {
  return (
    <ThemeProvider theme={bazarTheme}>
      <div className={classNames("BazarNavigation", { open: isOpen })}>
        <Box
          paddingY={1}
          display={"flex"}
          justifyContent={isOpen ? "start" : "center"}
          width={"100%"}
        >
          {languageSwitchFF && <LanguageSelect onChange={onLanguageChange} />}
        </Box>
        <Box
          paddingBottom={2}
          paddingX={2}
          className={classNames("navigationOpenToggle", direction)}
          display="flex"
          justifyContent={isOpen ? "flex-end" : "center"}
          onClick={toggleIsOpen}
        >
          <FontAwesomeIcon icon={isOpen ? faChevronsLeft : faChevronsRight} />
        </Box>
        {openStateLogo && closeStateLogo && (
          <div className="logo">
            <img src={isOpen ? openStateLogo : closeStateLogo} alt="logo" />
          </div>
        )}
        {searchInput && <div className="search">{searchInput}</div>}
        {profile && <div className="profile">{profile}</div>}
        {contextSelect && <div className="contextSelect">{contextSelect}</div>}
        <div className="menus">
          <div className={"menu"}>
            {!_.isEmpty(menuItems) &&
              _.map(
                _.filter(
                  menuItems,
                  (menuItem: MenuItem) =>
                    (!_.isNil(menuItem.hidden) ? !menuItem.hidden : true) &&
                    menuItem.isBottom !== true
                ),
                (menuItem: MenuItem, i: number) => (
                  <MenuItemButton
                    key={i}
                    menuItem={menuItem}
                    isOpen={isOpen}
                    disabled={menuItem.disabled}
                  />
                )
              )}
          </div>
          <div className={"menu footer"}>
            {!_.isEmpty(menuItems) &&
              _.map(
                _.filter(
                  menuItems,
                  (menuItem: MenuItem) => menuItem.isBottom === true
                ),
                (menuItem: MenuItem, i: number) => (
                  <MenuItemButton key={i} menuItem={menuItem} isOpen={isOpen} />
                )
              )}
            {version && (
              <div className="version">
                {versionHistoryLink ? (
                  <a href={versionHistoryLink}>v{version}</a>
                ) : (
                  `v${version}`
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </ThemeProvider>
  );
};
