import type {} from "@mui/x-date-pickers/themeAugmentation";
import { Components, createTheme } from "@mui/material/styles";
import { PaletteMode } from "@mui/material";
declare module "@mui/material/styles" {
  interface Palette {
    white: Palette["primary"];
  }
  interface PaletteOptions {
    white?: PaletteOptions["primary"];
  }
}
declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides {
    white: true;
  }
}

export const primaryColor = "#3C5069";
export const secondaryColor = "#fdb810";
export const successColor = "#3C5069";
export const successColor2 = "#9abe83";
export const infoColor = "#3C5069";
export const errorColor = "#c92424";

export const failedColor = "#c92424";
export const failedColor2 = "#DD8C8C";
export const warningColor = "#fdb810";

export const grayColor4 = "#8593aa";
export const grayColor3 = "#828d9e";
export const textGrayColor = "#616262";
export const whiteColor = "#ffffff";
export const blackColor = "#101010";
const disabled = "#bcbcbc";
export const jobRequestTitleColor = "#e6a19a";
export const karaokeColor = "#006bff";

export const neutralsN1 = " #fbfdff";
export const neutralsN2 = " #f2f4f6";
export const neutralsN3 = " #e8ecf1";
export const neutralsN5 = " #657692";
export const neutralsN6 = "#3c5069";
export const neutralsN7Black = "#030b19";

export const sxRemoveOutline = {
  boxShadow: "none",
  ".MuiOutlinedInput-notchedOutline": { border: 0 },
};

export const getCustomColoringSelect = (color: string) => {
  return {
    color: `${color} !important`,
    ".MuiOutlinedInput-notchedOutline": {
      borderColor: color,
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: color,
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: color,
    },
    ".MuiSvgIcon-root ": {
      fill: `${color} !important`,
    },
  };
};

const palette = {
  primary: {
    main: primaryColor,
  },
  secondary: {
    main: secondaryColor,
    contrastText: whiteColor,
  },
  white: {
    main: whiteColor,
    action: {
      disabledBackground: disabled,
      disabled: disabled,
    },
  },
  warning: {
    main: errorColor,
  },
  success: {
    main: successColor,
  },
  info: {
    main: infoColor,
  },
  error: {
    main: errorColor,
  },
};

//COMPONENTS
const MuiIconButton: Components["MuiIconButton"] = {
  styleOverrides: {
    root: {
      fontSize: "20px",
      padding: "10px 15px",
    },
  },
};

const MuiListItemText: Components["MuiListItemText"] = {
  styleOverrides: {
    root: {
      padding: "4px 10px",
      margin: 0,
    },
    primary: {
      fontSize: "14px",
    },
  },
};
const MuiListItem: Components["MuiListItem"] = {
  styleOverrides: {
    root: {
      cursor: "pointer",
      padding: "0",
      direction: "initial",
    },
  },
};
const MuiChip: Components["MuiChip"] = {
  styleOverrides: {
    root: {
      opacity: 0.8,
      backgroundColor: secondaryColor,
      color: primaryColor,
      textTransform: "capitalize",

      svg: {
        padding: "0 5px",
        color: `${primaryColor} !important`,
        fontSize: `12px !important`,
      },
    },
  },
};

const MuiButtonBase: Components["MuiButtonBase"] = {
  defaultProps: {
    disableRipple: true,
  },
};

const MuiTypography: Components["MuiTypography"] = {
  variants: [
    {
      props: { variant: "h1" },
      style: {
        color: neutralsN7Black,
        fontSize: "24px",
        fontWeight: "600",
      },
    },
    {
      props: { variant: "h3" },
      style: {
        color: neutralsN6,
        fontSize: "20px",
      },
    },
    {
      props: { variant: "subtitle1" },
      style: {
        color: neutralsN6,
        fontSize: "16px",
      },
    },
    {
      props: { variant: "subtitle2" },
      style: {
        color: neutralsN5,
        fontSize: "16px",
      },
    },
  ],
};

const MuiButton: Components["MuiButton"] = {
  styleOverrides: {
    root: {
      textTransform: "capitalize",
      borderRadius: "8px",
    },
    startIcon: {
      marginRight: 0,
      marginLeft: 0,
      margin: "0 5px",
    },
    endIcon: {
      marginRight: 0,
      marginLeft: 0,
      margin: "0 5px",
    },
  },
};

const MuiToggleButton: Components["MuiToggleButton"] = {
  styleOverrides: {
    root: {
      textTransform: "none",
      borderRadius: "8px",
      borderColor: grayColor3,
      color: blackColor,
      maxHeight: 35,
      "&.Mui-selected , &.Mui-selected:focus": {
        backgroundColor: secondaryColor,
        color: whiteColor,
        borderColor: grayColor3,
      },
      "&.Mui-disabled": {
        opacity: 0.8,
        border: "1px solid rgba(0, 0, 0, 0.26)",
      },
      "&:dir(rtl)": {
        "&:first-of-type": {
          borderTopRightRadius: "8px",
          borderBottomRightRadius: "8px",
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          borderLeft: 0,
        },
        "&:last-of-type": {
          borderTopLeftRadius: "8px",
          borderBottomLeftRadius: "8px",
          borderTopRightRadius: 0,
          borderBottomRightRadius: 0,
          // borderRight: 0,
        },
      },
    },
  },
};

const MuiSwitch: Components["MuiSwitch"] = {
  styleOverrides: {
    switchBase: {
      "&.Mui-checked": {
        color: secondaryColor,
      },
      "&.Mui-checked+.MuiSwitch-track": {
        backgroundColor: secondaryColor,
      },
    },
  },
};

const MuiOutlinedInput: Components["MuiOutlinedInput"] = {
  styleOverrides: {
    notchedOutline: {
      borderColor: grayColor3,
    },
    root: {
      borderRadius: "8px",
      maxHeight: 35,
    },
    input: {
      padding: "5px 16px",
      border: "unset",
      "&.Mui-disabled": {
        WebkitTextFillColor: grayColor3,
        color: grayColor3,
      },
    },
    adornedEnd: {
      padding: 0,
    },
  },
};

const MuiInputAdornment: Components["MuiInputAdornment"] = {
  styleOverrides: {
    root: {
      position: "absolute",
      marginLeft: "unset",
      right: 33,
      padding: 0,
    },
  },
};

const MuiDateCalendar: Components["MuiDateCalendar"] = {
  styleOverrides: {
    root: {
      width: 200,
      minHeight: 130,
      height: "auto",
    },
  },
};

const MuiPickersDay: Components["MuiPickersDay"] = {
  styleOverrides: {
    root: {
      width: 24,
      height: 24,
      color: primaryColor,
      "&.Mui-selected , &.Mui-selected:focus": {
        backgroundColor: secondaryColor,
      },
    },
  },
};

const MuiDayCalendar: Components["MuiDayCalendar"] = {
  styleOverrides: {
    weekContainer: {
      margin: 0,
      justifyContent: "space-between",
    },
    header: {
      justifyContent: "space-between",
    },
    weekDayLabel: {
      width: 24,
      height: 24,
      color: primaryColor,
      margin: 0,
    },
    slideTransition: {
      minHeight: 145,
    },
  },
};

const MuiPickersCalendarHeader: Components["MuiPickersCalendarHeader"] = {
  styleOverrides: {
    switchViewButton: {
      display: "none",
    },
    root: {
      color: primaryColor,
      marginBottom: 5,
      marginTop: 5,
      padding: 0,
      width: "100%",
      justifyContent: "space-between",
      svg: {
        color: primaryColor,
      },
    },
    labelContainer: {
      margin: 0,
      padding: 0,
    },
    label: {
      margin: 0,
      fontSize: 14,
    },
  },
};

const MuiPickersArrowSwitcher: Components["MuiPickersArrowSwitcher"] = {
  styleOverrides: {
    root: {
      padding: 0,
      color: primaryColor,
      svg: {
        color: primaryColor,
      },
    },
  },
};

const MuiYearCalendar: Components["MuiYearCalendar"] = {
  styleOverrides: {
    root: {
      maxHeight: 160,
      "& .PrivatePickersYear-yearButton": {
        fontSize: 14,
        margin: 0,
        width: 50,
        height: 30,
        color: primaryColor,
        "&.Mui-selected , &.Mui-selected:focus": {
          background: secondaryColor,
          color: whiteColor,
        },
      },
    },
  },
};

const MuiPaper: Components["MuiPaper"] = {
  styleOverrides: {
    root: {
      transition: "none !important",
      borderRadius: "5px",
      overflowX: "visible !important" as "visible",
      overflowY: "visible !important" as "visible",
      boxShadow: "none",
    },
  },
};

const MuiTableCell: Components["MuiTableCell"] = {
  styleOverrides: {
    root: {
      padding: "8px",
      maxWidth: 100,
    },
  },
};

const MuiTableSortLabel: Components["MuiTableSortLabel"] = {
  styleOverrides: {
    root: {
      fontSize: "18px",
      color: "white",
      "&:hover": {
        color: "white",
      },
      "&.Mui-active": {
        "&&": {
          color: "white",

          "& * ": {
            color: "white",
          },
        },
      },
    },
    icon: {
      color: "white",
    },
  },
};

const MuiTooltipVr1: Components["MuiTooltip"] = {
  styleOverrides: {
    tooltip: {
      padding: "10px 20px ",
      fontSize: "14px",
      background: primaryColor,
    },
  },
};

export const MuiTooltipVr2: Components["MuiTooltip"] = {
  styleOverrides: {
    tooltip: {
      borderRadius: "10px",
      padding: "6px 10px ",
      fontSize: "12px",
      background: primaryColor,
    },
  },
};

const MuiMenuItem: Components["MuiMenuItem"] = {
  styleOverrides: {
    root: {
      fontSize: "16px",
      padding: "2px 15px",
    },
  },
};

const MuiTextField: Components["MuiTextField"] = {
  styleOverrides: {
    root: {
      width: "100%",
    },
  },
};

const MuiSlider: Components["MuiSlider"] = {
  styleOverrides: {
    root: {
      color: secondaryColor,
    },
  },
};

const MuiInputBase: Components["MuiInputBase"] = {
  styleOverrides: {
    sizeSmall: {
      maxWidth: "max-content",
      margin: 0,
    },
  },
};

const MuiList: Components["MuiList"] = {
  styleOverrides: {
    root: {
      paddingTop: 0,
      paddingBottom: 0,
    },
  },
};

const themeConfig = {
  palette,
  components: {
    MuiChip,
    MuiButton,
    MuiOutlinedInput,
    MuiDateCalendar,
    MuiPickersDay,
    MuiDayCalendar,
    MuiPickersCalendarHeader,
    MuiPickersArrowSwitcher,
    MuiYearCalendar,
    MuiPaper,
    MuiTableCell,
    MuiTableSortLabel,
    MuiTooltip: MuiTooltipVr1,
    MuiList,
    MuiListItem,
    MuiListItemText,
    MuiIconButton,
    MuiMenuItem,
    MuiToggleButton,
    MuiSwitch,
    MuiInputAdornment,
    MuiTextField,
    MuiInputBase,
    MuiSlider,
    MuiButtonBase,
    MuiTypography,
  },
};

export const bazarTheme = createTheme(themeConfig);

export const themeCreator = (mode: PaletteMode) =>
  createTheme({ palette: { ...palette, mode } });
