import classNames from "classnames";
import React, { HTMLAttributes } from "react";
import { Accept, useDropzone } from "react-dropzone";
import { Button } from "../Button/Button";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./NewDragAndDrop.scss";

export interface DragAndDropProps extends HTMLAttributes<HTMLInputElement> {
  handleOnDrop: (files: File[]) => void;
  accept?: Accept;
  title: string;
  subtitle: string;
  icon: IconProp;
  buttons: {
    icon: IconProp;
    name: string;
    onClick?: Function;
    disabled?: boolean;
  }[];
  className?: string;
}

export const NewDragAndDrop = ({
  handleOnDrop,
  accept,
  className,
  subtitle,
  title,
  buttons,
  icon,
}: DragAndDropProps) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleOnDrop,
    accept,
  });

  return (
    <div
      {...getRootProps()}
      className={classNames(`NewBazarDragAndDrop`, className, {
        isEnter: isDragActive,
      })}
    >
      <Box
        display={"flex"}
        justifyContent={"center"}
        flexDirection={"column"}
        alignItems={"center"}
      >
        {icon && (
          <FontAwesomeIcon icon={icon} size={"3x"} className={"dndIcon"} />
        )}
        {title && (
          <Typography
            p={0.2}
            variant="body1"
            color={"text.secondary"}
            className={"dndTitle"}
          >
            {title}
          </Typography>
        )}
        {subtitle && (
          <Typography p={0.2} variant="body2" color={"text.secondary"}>
            {subtitle}
          </Typography>
        )}
        {buttons?.length && (
          <Box className={"DragAndDropButtonsWrapper"}>
            {buttons.map((btn) => (
              <React.Fragment key={btn.name}>
                <Button
                  startIcon={<FontAwesomeIcon icon={btn.icon} />}
                  onClick={btn.onClick as any}
                  disabled={btn.disabled}
                  variant="outlined"
                  className={"DragAndDropInnerButton"}
                >
                  {btn.name}
                </Button>
              </React.Fragment>
            ))}
          </Box>
        )}
      </Box>
      <input {...getInputProps()} type="file" multiple />
    </div>
  );
};
