import { Box, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useTranslation } from "react-i18next";
import LocalStorageService from "./../../services/localStorageService";
import {
  getCustomColoringSelect,
  primaryColor,
  sxRemoveOutline,
} from "../../bazar-theme";

const fontSize = 12;

const selectStyle = {
  ...getCustomColoringSelect("white"),
  ...sxRemoveOutline,
  fontSize,
};

const menuStyle = {
  PaperProps: {
    sx: {
      display: "flex",
      justifyContent: "center",
      borderRadius: "10px",
      color: primaryColor,
      "& .MuiMenuItem-root": {
        fontSize,
      },
    },
  },
};

export const LanguageSelect = ({
  onChange,
}: {
  onChange?: (lang: string) => void;
}) => {
  const { t, i18n } = useTranslation();

  const handleLanguageChange = (e: SelectChangeEvent<string>) => {
    i18n.changeLanguage(e.target.value);
    onChange && onChange(i18n.language);
    LocalStorageService.setItem("lang", i18n.language);
  };

  return (
    <Box className="LanguageSelect">
      {i18n.options?.resources && (
        <Box px={1}>
          <Select
            className="languageSelect"
            onChange={handleLanguageChange}
            value={i18n.language}
            sx={selectStyle}
            MenuProps={menuStyle}
          >
            {Object.keys(i18n.options.resources)
              .filter((langKey) => langKey !== "ar")
              .map((langKey) => (
                <MenuItem key={langKey} value={langKey}>
                  {t(langKey)}
                </MenuItem>
              ))}
          </Select>
        </Box>
      )}
    </Box>
  );
};
