import React from "react";
import _ from "lodash";
import LinearProgress from "@mui/material/LinearProgress";
import classNames from "classnames";

import "./ProgressBar.scss";

interface Props {
  progress?: number | null;
  error?: boolean;
}

export const ProgressBar = ({ progress, error }: Props) => {
  if (!_.isNumber(progress)) return null;
  return (
    <LinearProgress
      className={classNames("BazarProgressBar", { error })}
      variant="determinate"
      value={progress}
    />
  );
};
