import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  Textarea,
  type UpdateClientUploadsSettings,
} from "../../../../../index";
import { useCallback } from "react";
import { UploadFileForm } from "@sumit-platforms/types";

interface Props {
  uploadState: UploadFileForm;
  onUploadsUpdate: UpdateClientUploadsSettings;
}

export const NotesInput = ({ onUploadsUpdate, uploadState }: Props) => {
  const { t } = useTranslation();
  const setNotes = useCallback(
    (e: any) => {
      onUploadsUpdate({
        field: "note",
        data: e,
      });
    },
    [onUploadsUpdate]
  );
  return (
    <Box className={"Notes"} py={1}>
      <Typography variant={"h3"}>{t("notes")}:</Typography>
      <Typography variant={"subtitle2"} py={0.5}>
        {t("notes_subtitle")}
      </Typography>
      <Box pt={1}>
        <Textarea
          placeholder={t("about_this_delivery")}
          value={uploadState?.note || ""}
          setValue={setNotes}
        />
      </Box>
    </Box>
  );
};
