import {
  Direction,
  LanguageData,
  UploadFileForm,
} from "@sumit-platforms/types";
import { useTranslation } from "react-i18next";
import { DeliveryTypeGroup } from "../../../../NewUploads/SetOrder/DeliveryTypeGroup";
import { LanguageInputs } from "../../../../NewUploads/SetOrder/LanguageInputs";
import { AutoTypeGroup } from "../../../../NewUploads/Submission/AutoDeliveryGroup";
import React from "react";
import { UpdateClientUploadsSettings } from "../../../../NewUploads/NewUploads";

export const BasicSettings = ({
  uploadSettings,
  dir,
  languages,
  onUploadsUpdate,
}: {
  dir: Direction;
  onUploadsUpdate: UpdateClientUploadsSettings;
  uploadSettings: UploadFileForm;
  languages: LanguageData[];
}) => {
  const { t } = useTranslation();
  return (
    <>
      <DeliveryTypeGroup
        dir={dir}
        onUploadsUpdate={onUploadsUpdate}
        deliveryType={uploadSettings.jobType?.typeName}
      />
      <LanguageInputs
        inputLanguage={uploadSettings.inputLanguage || []}
        outputLanguage={uploadSettings.outputLanguage || []}
        onUploadsUpdate={onUploadsUpdate}
        languages={languages}
        dir={dir}
      />
      <AutoTypeGroup
        dir={dir}
        uploadState={uploadSettings}
        onUploadsUpdate={onUploadsUpdate}
        label={`${t("service")}:`}
      />
    </>
  );
};
