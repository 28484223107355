import React from "react";
import { Box, Typography } from "@mui/material";
import { RecordButton } from "./RecordButton";
import { DeleteButton } from "./DeleteButton";
import { SaveButton } from "./SaveButton";
import { EditableText } from "@sumit-platforms/ui-bazar";
import { recordStore } from "@sumit-platforms/ui-bazar/store";
import { Direction } from "@sumit-platforms/types";
import classNames from "classnames";

import "./RecordPage.scss";

export const RecordControlPanel = ({
  title,
  error,
  disabled,
  isLoading,
  handleRecordToggle,
  handleDeleteRecord,
  handleSaveRecord,
  dir,
}: {
  title: string;
  error: boolean;
  handleRecordToggle: () => void;
  handleDeleteRecord: () => void;
  handleSaveRecord: () => void;
  disabled: boolean;
  isLoading: boolean;
  dir: Direction;
}) => {
  const { isRecording, uploadSettings, updateUploadSettings } = recordStore();

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent={"center"}
      alignItems="center"
    >
      <Box mb={3}>
        {isRecording && !error ? (
          <EditableText
            text={uploadSettings.name || ""}
            onSave={(text: string) =>
              updateUploadSettings({ data: text, field: "name" })
            }
            textStyle={{ fontSize: "24px", fontWeight: "600" }}
          />
        ) : (
          <Typography variant={"h1"} color={error ? "error" : undefined}>
            {title}
          </Typography>
        )}
      </Box>
      <Box
        className={classNames("buttonsContainer", dir)}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
        position="relative"
        flexDirection={dir === "rtl" ? "row-reverse" : "row"}
      >
        {isRecording && (
          <DeleteButton
            handleDeleteRecord={handleDeleteRecord}
            disabled={isLoading}
          />
        )}
        <RecordButton
          handleToggleRecord={handleRecordToggle}
          disabled={disabled || isLoading}
        />
        {isRecording && (
          <SaveButton
            isLoading={isLoading}
            handleSaveRecord={handleSaveRecord}
          />
        )}
      </Box>
    </Box>
  );
};
