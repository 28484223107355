import React from "react";
import Typography from "@mui/material/Typography";
import { InfoTooltipIcon } from "../../../core/InfoTooltipIcon/InfoTooltipIcon";
import Box from "@mui/material/Box";

interface Props {
  title: string;
  subtitle?: string;
  subtitleTooltip?: string;
}

export const UploadPageSectionTitle = ({
  subtitle,
  subtitleTooltip,
  title,
}: Props) => {
  return (
    <Box className={"UploadPageSectionTitle"}>
      <Typography variant="h6">{title}</Typography>
      {subtitle && (
        <InfoTooltipIcon tooltipText={subtitleTooltip} size={"sm"}>
          <Typography variant="body2">{subtitle}</Typography>
        </InfoTooltipIcon>
      )}
    </Box>
  );
};
