type Languages =
  | "he-IL"
  | "en-US"
  | "ar-IL"
  | "es-ES"
  | "ru-RU"
  | "pt-BR"
  | "fr-FR"
  | "hi-IN"
  | "de-DE"
  | "zh-CN"
  | "cs-CZ"
  | "nl-NL"
  | "fi-FI"
  | "id-ID"
  | "it-IT"
  | "ja-JP"
  | "ko-KR"
  | "ms-MY"
  | "no-NO"
  | "pl-PL"
  | "pt-PT"
  | "es-419"
  | "sv-SE"
  | "tr-TR"
  | "ro-RO"
  | "am-ET"
  | "uk-UA";

type LanguageData = {
  idLanguage?: number;
  value: Languages;
};

interface LanguagesOptions {
  label: string;
  value: Languages;
}

const languages: LanguagesOptions[] = [
  {
    label: "Hebrew",
    value: "he-IL",
  },
  {
    label: "English",
    value: "en-US",
  },
  {
    label: "Arabic (IL)",
    value: "ar-IL",
  },
  {
    label: "Spanish (ES)",
    value: "es-ES",
  },
  {
    label: "Russian",
    value: "ru-RU",
  },
  {
    label: "Portuguese (BR)",
    value: "pt-BR",
  },
  {
    label: "French",
    value: "fr-FR",
  },
  {
    label: "Hindi",
    value: "hi-IN",
  },
  {
    label: "German",
    value: "de-DE",
  },
  { label: "Chinese", value: "zh-CN" },
  { label: "Czech", value: "cs-CZ" },
  { label: "Dutch", value: "nl-NL" },
  { label: "Finnish", value: "fi-FI" },
  { label: "Indonesian", value: "id-ID" },
  { label: "Italian", value: "it-IT" },
  { label: "Japanese", value: "ja-JP" },
  { label: "Korean", value: "ko-KR" },
  { label: "Malay", value: "ms-MY" },
  { label: "Norwegian", value: "no-NO" },
  { label: "Polish", value: "pl-PL" },
  { label: "Portuguese (Portugal)", value: "pt-PT" },
  { label: "Spanish (Latin-America)", value: "es-419" },
  { label: "Swedish", value: "sv-SE" },
  { label: "Turkish", value: "tr-TR" },
  { label: "Romanian", value: "ro-RO" },
  { label: "Amharic", value: "am-ET" },
  { label: "Ukrainian", value: "uk-UA" },
];

export type { Languages, LanguageData };
export { languages };
