import React, { useCallback } from "react";
import { Box, Popper, Typography } from "@mui/material";
import { recordStore } from "../../../../store";
import { useShallow } from "zustand/react/shallow";
import { IconButton } from "../../../../index";
import { RecordButtons } from "../../../../v3";
import { secondsToTimeString } from "../../../../utils";
import { faArrowUpRightFromSquare } from "@fortawesome/pro-light-svg-icons";
import { useNavigate } from "react-router-dom";
import { Direction } from "@sumit-platforms/types";

import "./RecordPopper.scss";

interface Props {
  open: boolean;
  id?: string;
  anchorEl: HTMLElement | null;
  recordUtils: any;
  dir: Direction;
}

export const RecordPopper = ({
  open,
  anchorEl,
  id,
  dir,
  recordUtils,
}: Props) => {
  const navigate = useNavigate();
  const { uploadSettings } = recordStore(
    useShallow((state) => ({
      uploadSettings: state.uploadSettings,
    }))
  );

  const navigateToUploads = useCallback(() => {
    navigate("record");
  }, [navigate]);

  return (
    <Popper
      open={open}
      id={id}
      anchorEl={anchorEl}
      placement={"top-end"}
      dir={dir}
    >
      <Box className={"RecordPopper"} p={1}>
        <Box className={"RecordPopperHeader"}>
          <Typography fontSize={"18px"} className={"ellipsis"}>
            {uploadSettings.name}
          </Typography>
          <IconButton
            icon={faArrowUpRightFromSquare}
            onClick={navigateToUploads}
          />
        </Box>
        <Box textAlign={"center"} height={"15px"}>
          <Typography variant="subtitle1">
            {recordUtils.timer?.totalSeconds
              ? secondsToTimeString(recordUtils.timer?.totalSeconds)
              : null}
          </Typography>
        </Box>
        <Box className={"RecordPopperButtons"}>
          <RecordButtons
            error={!!recordUtils.error}
            handleRecordToggle={recordUtils.handleRecordToggle}
            handleDeleteRecord={recordUtils.openDeleteModal}
            handleSaveRecord={recordUtils.handleSaveRecord}
            disabled={!!recordUtils.error || recordUtils.isLoading}
            isLoading={recordUtils.isLoading}
            dir={dir}
            hideButtonsText
            hideTitle
          ></RecordButtons>
        </Box>
      </Box>
    </Popper>
  );
};
