import { CircleButton } from "@sumit-platforms/ui-bazar";
import { useTranslation } from "react-i18next";
import { faTrash } from "@fortawesome/pro-light-svg-icons";

export const DeleteButton = ({
  handleDeleteRecord,
  disabled,
}: {
  handleDeleteRecord: () => void;
  disabled: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <CircleButton
      className={"deleteButton"}
      onClick={handleDeleteRecord}
      border={"black"}
      icon={faTrash}
      text={t("remove")}
      disabled={disabled}
    />
  );
};
