import React, { useCallback, useRef, useMemo, useState } from "react";
import _ from "lodash";
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  AutocompleteProps,
  ClickAwayListener,
  FormControl,
  SxProps,
  Typography,
} from "@mui/material";
import { SelectOptionItem } from "./SelectOptionItem";
import { SelectInput } from "./SelectInput";
import { SelectValueLabels } from "./SelectValueLabels";
import { neutralsN6 } from "../../../bazar-theme";
import { useTranslation } from "react-i18next";
import { SelectTags } from "./SelectTags";
import { Tag } from "@sumit-platforms/types";

import "./Select.scss";

export interface SelectOption {
  value: string | number;
  label: string;
  triggerNewValue?: boolean; // option that triggers onNewValueTrigger
}

const getPopperProps = (width: number, dir: string) => ({
  className: "BazarNewSelectPopper",
  sx: {
    direction: dir,
    width: `${width}px !important`,
    "& .MuiPaper-root": {
      borderRadius: "10px !important",
      boxShadow: "none !important",
      border: `1px solid ${neutralsN6}`,
    },
  },
  modifiers: [
    {
      name: "preventOverflow",
      options: {
        boundary: "window",
      },
    },
  ],
});

interface NewSelectProps
  extends Omit<
    AutocompleteProps<any, boolean, boolean, boolean>,
    "renderInput" | "onChange"
  > {
  label?: string;
  options: SelectOption[];
  value: (string | number)[];
  onChange: (val: (string | number)[]) => void;
  dir?: "ltr" | "rtl";
  placeholder?: string;
  multiple?: boolean;
  width?: number;
  onNewValueTrigger?: (newVal: SelectOption) => any;
  loading?: boolean;
  displayTags?: boolean;
  optional?: boolean;
  sx?: SxProps;
}

const ITEM_HEIGHT = 42;

export const Select = ({
  label,
  optional,
  options,
  value = [],
  onChange,
  dir = "ltr",
  placeholder,
  multiple = false,
  width = 286,
  loading,
  onNewValueTrigger,
  displayTags,
  sx,
  ...props
}: NewSelectProps) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>();
  const [isFocus, setIsFocus] = useState(false);
  const [inputValue, setInputValue] = useState(""); // Track the current typed value
  const handleChange = useCallback(
    (
      event: any,
      newValue: SelectOption[] | SelectOption,
      reason: AutocompleteChangeReason,
      clickedOption?: AutocompleteChangeDetails<SelectOption>
    ) => {
      if (!newValue) return onChange([]);

      if (onNewValueTrigger && clickedOption?.option?.triggerNewValue) {
        return onNewValueTrigger(clickedOption?.option);
      }

      const selectedOptions = multiple
        ? (newValue as SelectOption[])
        : [(newValue as SelectOption)?.value || newValue];
      const values = selectedOptions
        .map((o) => (_.isObject(o) ? (o as any).value : o))
        .filter(Boolean);
      onChange(values);
      if (!multiple) {
        setOpen(false);
        setIsFocus(false);
      }
    },
    [onChange, multiple]
  );

  const getOptionLabel = useCallback(
    (option: any) => {
      const item = option?.length
        ? options.find((item) => option.includes(item.value))
        : null;

      const optionLabel = option?.label || item?.label || "";

      return optionLabel;
    },
    [options, value]
  );
  const handleClickAway = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClickInside = useCallback(() => {
    if (!open) {
      setOpen(true);
    }
  }, [open]);

  // Handle input value change
  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value);
    },
    []
  );

  const addOption = useMemo(() => {
    return (
      onNewValueTrigger && {
        value: inputValue,
        label: `Add "${inputValue}"`,
        triggerNewValue: true,
      }
    );
  }, [inputValue, onNewValueTrigger]);

  // Filtered by user input
  const filteredOptions = useMemo(
    () =>
      options.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase())
      ),
    [options, inputValue]
  );

  // Filtered & sorted
  const sortedOptions = useMemo(() => {
    return filteredOptions.sort((a, b) => {
      const aInValues = value.includes(a.value);
      const bInValues = value.includes(b.value);

      if (aInValues && !bInValues) {
        return -1;
      } else if (!aInValues && bInValues) {
        return 1;
      }
      return 0;
    });
  }, [filteredOptions, value]);

  // Filtered & sorted & "Add 'xxx' options
  const enhancedOptions = useMemo(() => {
    const inputExists = sortedOptions.some(
      (op) => op.label.toLowerCase() === inputValue.toLowerCase()
    );

    const shouldAddTriggerNewOption = inputValue?.length && !inputExists;

    return shouldAddTriggerNewOption
      ? [...sortedOptions, addOption] // Add "Add 'xxx'" only if no exact match
      : sortedOptions;
  }, [sortedOptions, inputValue, addOption]);

  const handleOnFocus = useCallback(() => {
    setIsFocus(true);
  }, []);

  const handleOnBlur = useCallback(() => {
    setIsFocus(false);
  }, []);

  const selectedTags = useMemo(() => {
    const selectedValues = options.filter((op) => value.includes(op.value));
    const tags: Tag[] = selectedValues.map((val) => ({
      idTag: val.value as number,
      name: val.label,
      entities: [], // doesnt matter here
    }));
    return tags;
  }, [options, value]);

  const _handleDeleteTag = useCallback(
    async (idTag: number) => {
      const newValues = value.filter((val) => val !== idTag);
      onChange(newValues);
    },
    [value, onChange]
  );
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <FormControl
        className="BazarNewSelectWrapper"
        dir={dir}
        onClick={handleClickInside}
      >
        {label && (
          <Typography variant="subtitle1">
            {optional ? `${label} (${t("optional")}):` : label}
          </Typography>
        )}
        <Autocomplete
          ref={wrapperRef}
          multiple={multiple}
          id="BazarNewSelect"
          value={value}
          onChange={handleChange}
          options={enhancedOptions}
          getOptionLabel={getOptionLabel}
          renderTags={(values) => (
            <SelectValueLabels values={values} options={options} />
          )}
          renderInput={(params) => (
            <SelectInput
              displayTags={displayTags}
              value={value}
              isFocus={isFocus}
              inputValue={inputValue}
              onInputChange={handleInputChange}
              params={params}
              placeholder={isFocus ? t("search") : placeholder}
              dir={dir}
              open={open}
              setOpen={setOpen}
              loading={loading}
              onFocus={handleOnFocus}
              onBlur={handleOnBlur}
            />
          )}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => !multiple && setOpen(false)}
          isOptionEqualToValue={(option, value) => {
            if (!value) return false;
            value = Array.isArray(value) ? value : [value];
            return value.includes(option?.value || "");
          }}
          renderOption={(props, option) => (
            <SelectOptionItem
              height={ITEM_HEIGHT}
              option={option as SelectOption}
              props={props}
              multiple={multiple}
              checked={(value || [])?.some((v) => v === option?.value)}
            />
          )}
          componentsProps={{
            popper: getPopperProps(
              wrapperRef.current?.clientWidth || width,
              dir
            ),
          }}
          sx={{ ...(sx || {}), width, maxWidth: "100%" }}
          {...props}
        />
        {displayTags && (
          <SelectTags
            values={selectedTags}
            onDelete={_handleDeleteTag}
            dir={dir}
          />
        )}
      </FormControl>
    </ClickAwayListener>
  );
};
