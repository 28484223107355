import React, {
  forwardRef,
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import _ from "lodash";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  ShortcutsContext,
  useKeyboardShortcuts,
} from "@sumit-platforms/ui-bazar/hooks";
import classNames from "classnames";
import {
  faArrowRightArrowLeft,
  faCircleExclamation,
  faFileLines,
  faHourglassClock,
  faKeyboard,
  faLock,
  faMagnifyingGlass,
  faMicrophone,
  faPaintBrush,
  faReplyClock,
  faSave,
  faUnlock,
  faWandMagicSparkles,
} from "@fortawesome/pro-light-svg-icons";
import { faA } from "@fortawesome/pro-solid-svg-icons";
import {
  ConfirmModal,
  KeyboardShortcutsModal,
} from "@sumit-platforms/ui-bazar";
import { useIdleTimer } from "react-idle-timer";

import {
  getTcOffsetByStartTime,
  isTranslationTarget,
} from "@sumit-platforms/ui-bazar/utils";
import {
  mainYellow,
  recordingColor,
} from "@sumit-platforms/ui-bazar/constants";
import { Node } from "@sumit-platforms/slate";
import { ReactEditor } from "@sumit-platforms/slate-react";

import {
  EditorFeatureFlags,
  EditorMode,
  JobRange,
  JobSettings,
  JobSpeaker,
  JobSplitType,
  JobStatus,
  JobTranslationType,
  JobWithData,
  MenuBadge,
  MenuBar,
  MenuCategory,
  MenuItem,
  RangeValidationConfig,
  SaveTriggers,
  Shortcut,
  ShortcutAction,
  SubtitlesTranslationRange,
  UserSettings,
  ValidationsConfigData,
  Word,
} from "@sumit-platforms/types";
import { EditorAction } from "./types/EditorAction";
import { CustomEditor, CustomElement } from "./types";

import EditorService from "./services/EditorService";
import MediaService from "./services/MediaService";
// import TrackingService from "./services/TrackingService";
// import FeatureFlagsService from "./services/FeatureFlagsService";
// import { generateId } from "../../utils/generators";
// import { fileSizeFormat } from "../../utils/formatters";
// import Logger from "./services/Logger";
// import SubtitlesTranslation from "./SubtitlesTranslation";
import MediaPlayer from "./components/MediaPlayer/MediaPlayer";
import WaveformRanges from "./components/WaveformRanges/WaveformRanges";
// import MessageModal from "../../components/MessageModal/MessageModal";
// import ExportModal from "../../components/common/ExportModal/ExportModal";
// import SelectList from "../../components/common/SelectList/SelectList";
// import LoadingModal from "../../components/LoadingModal/LoadingModal";
// import ExportModalV3 from "../../components/common/ExportModalV3/ExportModalV3";
// import { ExportConfigData } from "../ExportConfig/ExportConfigTypes";
import { scrollInto } from "./utils/focusAndScroll";
import { generateId } from "./utils/generateId";
import { usePlayerStore } from "@sumit-platforms/ui-bazar/store";
import {
  directionState,
  fpsState,
  isAskAnythingOpenState,
  isDisabledState,
  isFindAndReplaceOpenState,
  isJobScriptOpenState,
  isValidationsOpenState,
  karaokeState,
  rangeSelectorState,
} from "./store/states";
import { useRecoilState, useRecoilValue } from "recoil";
import SlateEditor, { SlateForwardedRef } from "./SlateEditor";
import TranscriptMediaPlayer from "./components/MediaPlayer/TranscriptMediaPlayer";
import { Menubar } from "./components/MenuBar/MenuBar";
import { useMenuBar } from "./store/context/useMenuBar";
import { mergeCategories, mergeItems } from "./store/context/useMenuUtils";

// import config from "../../config";
import "./Editor.scss";

const logger = console;

const readOnlyStatuses = [
  JobStatus.aligning,
  JobStatus.stt,
  JobStatus.pending_source_transcription,
  JobStatus.pending_splits_transcription,
  JobStatus.kit_recording,
];
const disabledStatuses = [JobStatus.kit_recording, JobStatus.stt];
const translationsReadOnlyValues = [JobTranslationType.SOURCE_HIDDEN];

export type UpdateTcOffsetFn = ({
  editor,
  rangeIndex,
  startTime,
  tcOffset,
}: {
  editor: CustomEditor;
  rangeIndex: number;
  startTime: number;
  tcOffset: number;
}) => void;

interface EditorProps {
  job: JobWithData;
  jobSettings: JobSettings;
  setJob: (job: JobWithData) => void;
  save: (job: JobWithData, saveMethod: SaveTriggers) => Promise<void>;
  mode: EditorMode;
  platformDirection?: "ltr" | "rtl";
  showSubtitles?: boolean;
  jobMenuItems?: MenuBar;
  toast?: (options: any) => void;
  useModal: () => any;
  featureFlags?: EditorFeatureFlags;
  downloadMedia?: (idMedia: number) => Promise<any>;
  userSettings?: UserSettings | null;
  updateUserSettings?: (
    newUserSettings: Partial<UserSettings>
  ) => Promise<void>;
  startAsReadOnly?: boolean;
  aiChatOpenOnInit?: boolean;
  aiJobChatServerUrl?: string;
}

interface SlateEditorImperativeRef {
  updateJobData: () => void;
  isDisabled: boolean;
  setIsReadOnly?: React.Dispatch<React.SetStateAction<boolean>>;
  isChangesSaved?: boolean;
  selectedRanges: number[];
}

export const Editor = (
  {
    job,
    jobSettings,
    setJob,
    save,
    mode,
    platformDirection,
    showSubtitles = false,
    jobMenuItems,
    toast,
    useModal,
    featureFlags = {},
    downloadMedia,
    userSettings,
    updateUserSettings,
    startAsReadOnly = false,
    aiChatOpenOnInit = false,
  }: EditorProps,
  ref?: React.Ref<SlateEditorImperativeRef>
): JSX.Element => {
  const navigate = useNavigate();
  const { setZoomValue } = usePlayerStore();
  const shortcutContext = useContext(ShortcutsContext);
  const [queryParameters] = useSearchParams();
  const { t } = useTranslation();
  const [userShortcuts, setUserShortcuts] = useState<Shortcut[]>([]);
  const [isOnHold, setIsOnHold] = useState(false);
  const [sessionId] = useState(generateId("s_"));
  const { setModalContent, clearModalContent, setModalType } = useModal();
  const userActive = useRef(true);
  const isTranslationMode = useRef(false);
  const [autoSave, setAutoSave] = useState(
    _.isNil(userSettings?.autoSave) ? true : userSettings?.autoSave
  );
  const [autoRewind, setAutoRewind] = useState(0);
  const [initialPlaybackPosition, setInitialPlaybackPosition] = useState(0);
  // const { autoSave, playerRewind, playerForward, autoRewind } =
  //   useSelector((state: AppState) => state.userStore.settings);
  const slateRef = useRef<SlateForwardedRef | null>(null);
  // const recoilSetter = useRef<SetRecoilState | null>(null);
  const isChangesSaved = useRef(false);
  const editorType = useMemo(
    () =>
      (mode === "transcript" || mode === "subtitles") &&
      ["subtitles", "protocol", "brief", "interview"].includes(
        job.type?.typeName
      ) &&
      featureFlags?.contentEditable
        ? "slate"
        : "v3",
    [featureFlags, job.type?.typeName, mode]
  );
  const playerRewind = useMemo(() => 3, []);
  const playerForward = useMemo(() => 3, []);
  const isSplit = useMemo(
    () => job.splitType === JobSplitType.SPLIT,
    [job.splitType]
  );
  // const focusedRangeIndex = useRef(-1);

  const [focusedRangeIndex, setFocusedRangeIndex] = useState(-1);
  const [focusedRangeWordsString, setFocusedRangeWordsString] = useState("");
  const [currentTime, setCurrentTime] = useState(0);
  // const [jobStreamingState, setJobStreamingState] =
  // useState<JobData["streaming"]>();
  // const [editorDirection, setEditorDirection] = useState<"ltr" | "rtl">("ltr");
  const [timestampsEnterMethod, setTimestampsEnterMethod] = useState<
    "word" | "player"
  >("word");
  const [isMediaPlayerFloating, setIsMediaPlayerFloating] = useState(true);
  // const [isReadOnly, setIsReadOnly] = useState(startAsReadOnly);
  // const [isJobScriptOpen, setIsJobScriptOpen] = useState(
  //   _.isNil(userSettings?.isJobScriptOpen)
  //     ? true
  //     : userSettings?.isJobScriptOpen || false
  // );
  // const [karaokeMode, setKaraokeMode] = useState(
  //   featureFlags?.karaoke
  //     ? startAsReadOnly
  //       ? userSettings?.karaokeMode
  //       : startAsReadOnly
  //     : false
  // );
  const [isFindAndReplaceOpen, setIsFindAndReplaceOpen] = useRecoilState(
    isFindAndReplaceOpenState
  );
  const [isValidationOpen, setIsValidationOpen] = useRecoilState(
    isValidationsOpenState
  );

  const [isDisabled, setIsDisabled] = useRecoilState(isDisabledState);
  const [editorDirection, setEditorDirection] = useRecoilState(directionState);
  const [isValidationsOpen, setIsValidationsOpen] = useRecoilState(
    isValidationsOpenState
  );
  const [isAskAnythingOpen, setIsAskAnythingOpen] = useRecoilState(
    isAskAnythingOpenState
  );
  const [isJobScriptOpen, setIsJobScriptOpen] =
    useRecoilState(isJobScriptOpenState);
  const [karaokeMode, setKaraokeMode] = useRecoilState(karaokeState);
  const rangeSelecter = useRecoilValue(rangeSelectorState);
  const initialFpsValue = useRecoilValue(fpsState);

  const updateJobDataRanges = useCallback(() => {
    if (!slateRef.current) return;

    const { editor, updateLastRangeInput } = slateRef.current;
    const values = editor.children as CustomElement[];
    if (!values) return;

    if (updateLastRangeInput) updateLastRangeInput();

    const newRanges = EditorService.formatEditorValueToJobData(values, mode);
    job.data.ranges = newRanges;
  }, [job.data, mode]);

  const updateJobData = useCallback(() => {
    updateJobDataRanges();
  }, [updateJobDataRanges]);

  useImperativeHandle(
    ref,
    () => {
      return {
        updateJobData: updateJobData,
        setIsReadOnly: setIsDisabled,
        isDisabled: isDisabled,
        isChangesSaved: isChangesSaved.current,
        selectedRanges: rangeSelecter.selected,
      } as SlateEditorImperativeRef;
    },
    [setIsDisabled, isDisabled, rangeSelecter.selected, updateJobData]
  );

  // const [exportConfigPresets, setExportConfigPresets] = useState<

  //   { id: string; config: ExportConfigData }[]
  // >([]);
  useIdleTimer({
    timeout: 31 * 1000,
    onIdle: () => (userActive.current = false),
    onActive: () => (userActive.current = true),
    debounce: 500,
  });

  // const [showExportModal, setShowExportModal] = useState(false);

  const validationConfig = useRef<RangeValidationConfig>();

  const [actions, setActions] = useState<EditorAction[]>([]);
  const [subtitlesData, setSubtitlesData] = useState("");

  const handleSlateStateReset = () => {
    if (!job.data.ranges.length || !slateRef.current?.editor) {
      // means on first mount. we will skip it
      return;
    }
    const existValue = EditorService.formatEditorValueToJobData(
      slateRef.current.editor.children as CustomElement[],
      mode
    );
    const currentRanges = job.data.ranges;
    const shouldUpdate = !_.isEqual(existValue, currentRanges);

    if (shouldUpdate) {
      const newSlateValue = EditorService.formatJobDataToEditorValue(job, mode);
      EditorService.expensivelyResetEditorState(
        slateRef.current.editor,
        newSlateValue
      );
      EditorService.createWaveformRanges(slateRef.current.editor, isDisabled);
    }
  };

  const initJobData = async () => {
    if (!job) return;

    try {
      const jobLangKey = "outputLanguage";
      const _jobLang = job[jobLangKey][0] || job.inputLanguage[0];
      const isTranslation =
        isTranslationTarget(job) ||
        isTranslationTarget(job.jobSplit?.sourceJob);
      const languageDirection = EditorService.getLangDirection(_jobLang);

      // setJobStreamingState(job.streaming);
      // getJobRevisions(job);
      updateRanges(job.data?.ranges);

      // const newSubtitlesData = createVtt(job.data.ranges);
      // setSubtitlesData(newSubtitlesData);

      // await handleExportConfig(jobData);
      const _isReadOnly =
        startAsReadOnly ||
        isDisabled ||
        readOnlyStatuses.includes(job.status) ||
        translationsReadOnlyValues.includes(job.translation);

      if (!_isReadOnly) {
        setKaraokeMode(false);
      }
      setEditorDirection(languageDirection);
      setIsDisabled(_isReadOnly);
      setIsJobScriptOpen(
        _.isNil(userSettings?.isJobScriptOpen)
          ? true
          : userSettings?.isJobScriptOpen || false
      );

      MediaService.tcOffsets = job.tcOffsets;
      MediaService.setFrameRate(initialFpsValue);
      // initValidation(job.data, _jobLang);
      handleQueryParams();

      handleSlateStateReset();
      if (isTranslation) {
        isTranslationMode.current = true;
      }
    } catch (err) {
      logger.error(err);
      // setErrorReason(t("indicator_error_ocurred"));
    }
  };

  useEffect(() => {
    const keyStrokesHandler = async (e: any) => {
      if (!e.ctrlKey && !e.metaKey) return;
      switch (e.code) {
        case "KeyM":
          handleTogglePlay(e);
          break;
        case "KeyS":
          handleSaveJobKeyStroke(e);
          break;
        case "KeyK":
          handleMarkCurrentWordKeyStroke(e);
          break;
        case "Period":
          handlePlayRelativeKeyStroke(e, playerForward || 5);
          break;
        case "Comma":
          handlePlayRelativeKeyStroke(e, (playerRewind || 5) * -1);
          break;
      }
    };
    if (featureFlags?.useNewKeyboardShortcuts) return;
    document.addEventListener("keydown", keyStrokesHandler, false);

    return () => {
      document.removeEventListener("keydown", keyStrokesHandler);
    };
  }, [job, playerRewind, playerForward, autoRewind]);

  useEffect(() => {
    if (shortcutContext?.initEditorType) {
      shortcutContext.initEditorType("slate");
    }
  }, [shortcutContext]);

  const getJobSpeakers = useCallback(() => {
    const speakersMap = job.data.ranges.reduce((acc, range) => {
      if (!range.speakerName) return acc;
      if (!acc[range.speakerName as string]) {
        const speakerId = (range as any)?.speakerId || null;
        const speaker = {
          name: range.speakerName,
          id: speakerId,
        };
        acc[range.speakerName] = speaker;
      }
      return acc;
    }, {} as Record<string, JobSpeaker>);

    const speakers = _.values(speakersMap);
    return speakers;
  }, [job]);

  const getWordByTime = useCallback(
    (time: number) => {
      if (!job || !job.data.ranges) return null;
      let currentRangeIndex;
      const currentRange = _.find(job.data.ranges, (r, i) => {
        if (r.et >= time) {
          currentRangeIndex = i;
          return true;
        }
        return false;
      });
      if (!currentRange || _.isNil(currentRangeIndex)) return null;

      const currentWord = _.find(
        currentRange.words,
        (w, i) => w.end_time >= time
      );

      if (!currentWord) return null;

      let wordStartIndex = -1;
      let wordEndIndex = 0;
      for (const rangeWord of currentRange.words.filter(
        (w) => w.range_ix === currentWord.range_ix
      )) {
        wordStartIndex++;
        if (currentWord === rangeWord) {
          wordEndIndex = wordStartIndex + rangeWord.word.length;
          break;
        }
        wordStartIndex = wordStartIndex + rangeWord.word.length;
      }

      return {
        word: currentWord,
        start_index: wordStartIndex,
        end_index: wordEndIndex,
        range_ix: currentRangeIndex,
      };
    },
    [job]
  );

  const handleTogglePlay = useCallback(
    (e: React.KeyboardEvent) => {
      e.preventDefault();
      MediaService.togglePlay((autoRewind || 0) * -1);
    },
    [autoRewind]
  );
  const handleSaveJobKeyStroke = useCallback(
    (e: React.KeyboardEvent) => {
      e.preventDefault();
      if (isDisabled) return;
      handleSaveJob(SaveTriggers.KEYBOARD_SHORTCUT);
    },
    [handleSaveJob, isDisabled]
  );

  const scrollToWord = (rangeIndex: number) => {
    const editor = slateRef?.current?.editor;
    if (editor) {
      const node = Node.get(editor, [rangeIndex]);
      try {
        const domNode = ReactEditor.toDOMNode(editor, node);
        if (domNode) {
          domNode.scrollIntoView({ behavior: "instant", block: "start" });
        }
      } catch (e) {
        console.error(`Cannot find node to scroll on index ${rangeIndex}`, e);
      }
    }
  };

  const selectCurrentWord = useCallback(
    (options?: { delay: number }) => {
      // TrackingService.reportEvent("select_playing_word", {
      //   job_type: job?.jobType,
      //   room_id: job?.roomId,
      // });

      const currentWord: {
        word: Word;
        start_index: number;
        end_index: number;
        range_ix: number;
      } | null = getWordByTime(MediaService.currentTime);
      if (!currentWord) return;
      setTimeout(() => {
        scrollToWord(currentWord.range_ix);
      }, options?.delay || 200);
    },
    [getWordByTime]
  );

  const handleMarkCurrentWordKeyStroke = useCallback(
    (e: React.KeyboardEvent) => {
      e.preventDefault();
      if (isDisabled) return;
      selectCurrentWord();
    },
    [isDisabled, selectCurrentWord]
  );

  const handlePlayRelativeKeyStroke = useCallback(
    (e: React.KeyboardEvent, offset: number) => {
      e.preventDefault();
      MediaService.playRelative(offset);
    },
    []
  );

  const handleResetShortcuts = useCallback(async () => {
    if (!updateUserSettings) return;
    await updateUserSettings({
      ...userSettings,
      keyboardShortcuts: [],
    });
  }, [updateUserSettings, userSettings]);

  const onUserShortcutEdit = useCallback(
    async (newShortcut: Shortcut[]) => {
      if (!updateUserSettings) return;
      const clonedUserShortcuts = _.clone(
        userSettings?.keyboardShortcuts || []
      );
      const updatedUserShortcuts = _.chain([
        ...newShortcut,
        ...clonedUserShortcuts,
      ])
        .uniqBy("action")
        .map(
          (shortcut) => _.pick(shortcut, ["action", "keys"]) // save only the action and keys
        )
        .value();

      await updateUserSettings({
        ...userSettings,
        keyboardShortcuts: updatedUserShortcuts as Partial<Shortcut[]>,
      });
    },
    [updateUserSettings, userSettings]
  );

  const toggleJobAskAnything = useCallback(() => {
    if (!featureFlags?.jobAiChatFF) return;
    const val = !isAskAnythingOpen;
    setIsAskAnythingOpen(val);
    if (val) {
      // closing all other action.
      // TODO: refactor it so it be still
      setIsJobScriptOpen(false);
      setIsValidationOpen(false);
      setIsFindAndReplaceOpen(false);
    }
  }, [
    featureFlags,
    isAskAnythingOpen,
    setIsAskAnythingOpen,
    setIsFindAndReplaceOpen,
    setIsJobScriptOpen,
    setIsValidationOpen,
  ]);

  const toggleJobAskAnythingKeystroke = useCallback(
    (e: any) => {
      e.preventDefault();
      toggleJobAskAnything();
    },
    [toggleJobAskAnything]
  );

  const toggleIsFindAndReplaceOpen = useCallback(() => {
    if (!featureFlags?.findAndReplace) return;
    const newValue = !isFindAndReplaceOpen;
    setIsFindAndReplaceOpen(newValue);
    if (newValue) {
      if (isJobScriptOpen) setIsJobScriptOpen(false);
      if (isValidationsOpen) setIsValidationsOpen(false);
      if (isAskAnythingOpen) setIsAskAnythingOpen(false);
    }
  }, [
    featureFlags?.findAndReplace,
    isFindAndReplaceOpen,
    isAskAnythingOpen,
    setIsAskAnythingOpen,
    isJobScriptOpen,
    isValidationsOpen,
    setIsFindAndReplaceOpen,
    setIsJobScriptOpen,
    setIsValidationsOpen,
  ]);

  const handleOpenKeyboardModal = useCallback(() => {
    setModalType("info");
    setModalContent(
      <KeyboardShortcutsModal
        userShortcuts={userShortcuts || []}
        onShortcutEdit={onUserShortcutEdit}
        closeModal={clearModalContent}
        mode={mode}
        editorType={editorType}
        onResetShortcuts={handleResetShortcuts}
      />
    );
  }, [
    handleResetShortcuts,
    editorType,
    setModalType,
    setModalContent,
    userShortcuts,
    onUserShortcutEdit,
    clearModalContent,
    mode,
  ]);

  const { userShortcuts: userShortcutsWithDefaults } = useKeyboardShortcuts({
    handlers: {
      TOGGLE_PLAY: handleTogglePlay,
      SAVE_JOB: handleSaveJobKeyStroke,
      MARK_CURRENT_WORD: handleMarkCurrentWordKeyStroke,
      OPEN_KEYBOARD_SHORTCUTS_MODAL: handleOpenKeyboardModal,
      TOGGLE_ASK_ANYTHING: toggleJobAskAnythingKeystroke,
      PLAY_FORWARD: (e: React.KeyboardEvent) =>
        handlePlayRelativeKeyStroke(e, playerForward || 5),
      PLAY_BACKWARD: (e: React.KeyboardEvent) =>
        handlePlayRelativeKeyStroke(e, (playerRewind || 5) * -1),
    },
    ref: document.body,
    disabled: !featureFlags?.useNewKeyboardShortcuts,
  });

  const onUserSettingsChange = useCallback(
    async (updatedUserSettings: Partial<UserSettings>) => {
      if (updateUserSettings) {
        updateUserSettings(updatedUserSettings);
      }
    },
    [updateUserSettings]
  );

  const handleJobScriptClick = useCallback(() => {
    const updatedOpenState = !isJobScriptOpen;
    setIsJobScriptOpen(updatedOpenState);
    if (updatedOpenState) {
      if (isValidationsOpen) setIsValidationsOpen(false);
      if (isFindAndReplaceOpen) setIsFindAndReplaceOpen(false);
      if (isAskAnythingOpen) setIsAskAnythingOpen(false);
    }

    if (updatedOpenState !== userSettings?.isJobScriptOpen) {
      onUserSettingsChange({ isJobScriptOpen: updatedOpenState });
    }
  }, [
    isJobScriptOpen,
    setIsJobScriptOpen,
    isAskAnythingOpen,
    setIsAskAnythingOpen,
    userSettings?.isJobScriptOpen,
    isValidationsOpen,
    setIsValidationsOpen,
    isFindAndReplaceOpen,
    setIsFindAndReplaceOpen,
    onUserSettingsChange,
  ]);

  const handleReadOnlyClick = useCallback(() => {
    const updatedReadOnlyState = !isDisabled;
    setIsDisabled(updatedReadOnlyState);

    if (updatedReadOnlyState) {
      const userKaraokeMode = _.isNil(userSettings?.karaokeMode)
        ? true
        : userSettings?.karaokeMode;
      setKaraokeMode(userKaraokeMode);
    } else {
      setKaraokeMode(false);
    }
    if (slateRef.current?.lastFindAndReplaceTerm) {
      slateRef.current.lastFindAndReplaceTerm = "";
    }
  }, [isDisabled, setIsDisabled, setKaraokeMode, userSettings?.karaokeMode]);

  const handleValidationClick = useCallback(() => {
    const updatedValidationOpen = !isValidationOpen;
    setIsValidationOpen(updatedValidationOpen);
    if (updatedValidationOpen) {
      if (isJobScriptOpen) {
        setIsJobScriptOpen(false);
      }
      if (isFindAndReplaceOpen) {
        setIsFindAndReplaceOpen(false);
      }
    }
  }, [
    isValidationOpen,
    setIsValidationOpen,
    isJobScriptOpen,
    setIsJobScriptOpen,
    isFindAndReplaceOpen,
    setIsFindAndReplaceOpen,
  ]);

  const handleKaraokeButtonClick = useCallback(() => {
    const updatedKaraokeMode = !karaokeMode;
    if (!isDisabled) handleReadOnlyClick();

    setKaraokeMode(updatedKaraokeMode);
    if (
      updateUserSettings &&
      updatedKaraokeMode !== userSettings?.karaokeMode
    ) {
      updateUserSettings({ karaokeMode: updatedKaraokeMode });
    }
  }, [
    karaokeMode,
    isDisabled,
    handleReadOnlyClick,
    setKaraokeMode,
    updateUserSettings,
    userSettings?.karaokeMode,
  ]);

  const handleAutoSaveChange = async () => {
    const newValue = !autoSave;
    setAutoSave(newValue);
  };

  useEffect(() => {
    const updatedUserSettings = { autoSave };
    const currentUserSettings = _.omit(userSettings, "shortcuts");
    if (
      !_.isEqual(currentUserSettings.autoSave, updatedUserSettings.autoSave)
    ) {
      onUserSettingsChange(updatedUserSettings);
    }
  }, [autoSave]);

  useEffect(() => {
    // TODO: replace to useMemo to prevent renders
    // addActions([
    //   {
    //     key: "findAndReplace",
    //     label: t("find_and_replace"),
    //     icon: faMagnifyingGlass,
    //     onClick: toggleIsFindAndReplaceOpen,
    //     selected: isFindAndReplaceOpen,
    //     hide: !featureFlags?.findAndReplace, // || mode === "subtitles" hide from subtitle until stable
    //   },
    //   {
    //     key: "readOnly",
    //     label: isDisabled ? t("unlock") : t("lock"),
    //     icon: isDisabled ? faUnlock : faLock,
    //     selected: isDisabled,
    //     onClick: handleReadOnlyClick,
    //     disabled: disabledStatuses.includes(job.status),
    //     children:
    //       featureFlags?.karaoke && mode === "transcript"
    //         ? [
    //             {
    //               key: "karaoke",
    //               label: t("karaoke"),
    //               tooltipText: t("karaoke_mode_tooltip"),
    //               icon: faPaintBrush,
    //               onClick: handleKaraokeButtonClick,
    //               selected: karaokeMode,
    //             },
    //           ]
    //         : null,
    //   },
    //   {
    //     key: "jobScript",
    //     label: t("job_script"),
    //     icon: faFileLines,
    //     onClick: handleJobScriptClick,
    //     selected: isJobScriptOpen,
    //     hide: mode === "transcript",
    //   },
    //   {
    //     key: "autoRewind",
    //     label: t("auto_rewind"),
    //     icon: faReplyClock,
    //     onClick: () =>
    //       setAutoRewind((prevautoRewind) => (prevautoRewind > 0 ? 0 : 3)),
    //     selected: autoRewind > 0,
    //   },
    //   {
    //     key: "direction",
    //     label: t(editorDirection),
    //     icon: faArrowRightArrowLeft,
    //     onClick: () => {
    //       let newDir: Direction = editorDirection;
    //       setEditorDirection((dir) => {
    //         newDir = dir === "ltr" ? "rtl" : "ltr";
    //         return newDir as Direction;
    //       });
    //       setEditorDirection(newDir);
    //     },
    //   },
    //   {
    //     key: "keyboardShortcuts",
    //     label: t("shortcuts"),
    //     icon: faKeyboard,
    //     onClick: handleOpenKeyboardModal,
    //     hide: !featureFlags?.useNewKeyboardShortcuts,
    //   },
    //   {
    //     key: "save",
    //     label: t("save"),
    //     icon: faSave,
    //     onClick: () => handleSaveJob(SaveTriggers.SAVE_BUTTON),
    //     selected: autoSave,
    //     disabled: isDisabled,
    //     children: [
    //       {
    //         key: "autoSave",
    //         label: t("auto_save_toggle"),
    //         icon: faA,
    //         color: autoSave ? mainYellow : disabledButtonGray,
    //         onClick: handleAutoSaveChange,
    //         disabled: isDisabled,
    //       },
    //     ],
    //   },
    // ]);
  }, [
    job,
    autoSave,
    autoRewind,
    handleOpenKeyboardModal,
    isDisabled,
    isFindAndReplaceOpen,
    handleKaraokeButtonClick,
    karaokeMode,
    isJobScriptOpen,
    // slateRef
  ]);

  const editorFeaturedButtons: MenuItem[] = useMemo(
    () => [
      {
        id: "findAndReplace",
        name: t("find_and_replace"),
        icon: faMagnifyingGlass,
        checked: isFindAndReplaceOpen,
        action: toggleIsFindAndReplaceOpen,
        shortcutId: ShortcutAction.FIND_AND_REPLACE,
        hide: !featureFlags?.findAndReplace,
      },
      {
        id: "askAnything",
        name: t("ask_anything"),
        icon: faWandMagicSparkles,
        action: toggleJobAskAnything,
        type: "action",
        hide: !featureFlags?.jobAiChatFF,
        shortcutId: ShortcutAction.TOGGLE_ASK_ANYTHING,
        checked: isAskAnythingOpen,
      },
    ],
    [
      featureFlags?.findAndReplace,
      featureFlags?.jobAiChatFF,
      isAskAnythingOpen,
      isFindAndReplaceOpen,
      t,
      toggleIsFindAndReplaceOpen,
      toggleJobAskAnything,
    ]
  );
  const editorCategories: MenuCategory[] = useMemo(
    () => [
      {
        name: t("file"),
        items: [
          {
            id: "save",
            type: "action",
            name: t("save"),
            icon: faSave,
            action: () => handleSaveJob(SaveTriggers.SAVE_BUTTON),
            disabled: isDisabled,
            shortcutId: ShortcutAction.SAVE_JOB,
            sortIndex: 0,
          },
          {
            id: "autoSave",
            type: "toggle",
            name: t("auto_save_toggle"),
            icon: faA,
            action: handleAutoSaveChange,
            checked: autoSave,
            disabled: isDisabled,
            sortIndex: 1,
            bottomSeparator: true,
          },
        ],
      },
      {
        name: t("tools"),
        items: [
          {
            id: "validations",
            name: t("validations"),
            icon: faCircleExclamation,
            action: handleValidationClick,
            checked: isValidationOpen,
            hide: mode === "transcript",
          },
          {
            id: "askAnything",
            name: t("ask_anything"),
            icon: faWandMagicSparkles,
            action: toggleJobAskAnything,
            type: "action",
            hide: !featureFlags?.jobAiChatFF,
            shortcutId: ShortcutAction.TOGGLE_ASK_ANYTHING,
            checked: isAskAnythingOpen,
          },
          {
            id: "findAndReplace",
            name: t("find_and_replace"),
            icon: faMagnifyingGlass,
            checked: isFindAndReplaceOpen,
            action: toggleIsFindAndReplaceOpen,
            shortcutId: ShortcutAction.FIND_AND_REPLACE,
            hide: !featureFlags?.findAndReplace,
          },
        ],
      },
      {
        name: t("view"),
        items: [
          {
            id: "readOnly",
            name: isDisabled ? t("unlock") : t("lock"),
            icon: isDisabled ? faUnlock : faLock,
            type: "toggle",
            checked: isDisabled,
            action: handleReadOnlyClick,
            disabled: disabledStatuses.includes(job.status),
            shortcutId: ShortcutAction.LOCK_MODE,
          },
          {
            id: "karaoke",
            name: t("karaoke"),
            icon: faPaintBrush,
            type: "toggle",
            action: handleKaraokeButtonClick,
            checked: karaokeMode,
            disabled: !isDisabled,
            hide: !featureFlags?.karaoke || mode !== "transcript",
          },
          {
            id: "jobScript",
            type: "toggle",
            name: t("job_script"),
            icon: faFileLines,
            action: handleJobScriptClick,
            checked: isJobScriptOpen,
            hide: mode === "transcript",
          },
        ],
      },
      {
        name: t("settings"),
        items: [
          {
            id: "autoRewind",
            type: "toggle",
            name: t("auto_rewind"),
            icon: faReplyClock,
            action: () => setAutoRewind((prev) => (prev > 0 ? 0 : 3)),
            checked: autoRewind > 0,
          },
          {
            id: "direction",
            type: "action",
            name: t("switch_direction"),
            icon: faArrowRightArrowLeft,
            action: () => {
              setEditorDirection((dir) => (dir === "ltr" ? "rtl" : "ltr"));
            },
          },
          {
            id: "keyboardShortcuts",
            type: "action",
            name: t("shortcuts"),
            icon: faKeyboard,
            action: handleOpenKeyboardModal,
            shortcutId: ShortcutAction.OPEN_KEYBOARD_SHORTCUTS_MODAL,
            hide: !featureFlags?.useNewKeyboardShortcuts,
          },
        ],
      },
    ],
    [
      autoRewind,
      autoSave,
      featureFlags?.findAndReplace,
      featureFlags?.jobAiChatFF,
      featureFlags?.karaoke,
      featureFlags?.useNewKeyboardShortcuts,
      handleAutoSaveChange,
      handleJobScriptClick,
      handleKaraokeButtonClick,
      handleOpenKeyboardModal,
      handleReadOnlyClick,
      handleSaveJob,
      handleValidationClick,
      isAskAnythingOpen,
      isDisabled,
      isFindAndReplaceOpen,
      isJobScriptOpen,
      isValidationOpen,
      job.status,
      karaokeMode,
      mode,
      setEditorDirection,
      t,
      toggleIsFindAndReplaceOpen,
      toggleJobAskAnything,
    ]
  );

  const editorBadges: MenuBadge[] = useMemo(
    () => [
      {
        icon: faMicrophone,
        tooltip: t("recording"),
        id: "recording",
        hide: job.status !== JobStatus.kit_recording,
        color: recordingColor,
      },
      {
        icon: faHourglassClock,
        tooltip: t("transcribing"),
        id: "transcribing",
        hide: job.status !== JobStatus.stt,
        color: mainYellow,
      },
      {
        icon: faLock,
        tooltip: t("view_only_mode"),
        id: "readOnly",
        color: mainYellow,
        hide: !isDisabled,
        onClick: handleReadOnlyClick,
        checked: isDisabled,
      },
    ],
    [handleReadOnlyClick, isDisabled, job.status, t]
  );

  const menuBar = useMemo(() => {
    const menuBar: MenuBar = {
      menuTitle: job.name,
      categories: mergeCategories(editorCategories, jobMenuItems?.categories),
      featuredButtons: mergeItems(
        editorFeaturedButtons,
        jobMenuItems?.featuredButtons
      ),
      badges: editorBadges,
    };
    return menuBar;
  }, [
    job.name,
    editorCategories,
    jobMenuItems?.categories,
    jobMenuItems?.featuredButtons,
    editorFeaturedButtons,
    editorBadges,
  ]);

  useEffect(() => {
    initJobData();
    if (job.status === JobStatus.kit_recording && slateRef.current?.editor) {
      const _editorRef = ReactEditor.toDOMNode(
        slateRef.current?.editor,
        slateRef.current?.editor
      );
      _editorRef.scrollTo({
        top: _editorRef.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [job.data]);

  useEffect(() => {
    if (showSubtitles) {
      //Setting zoom value to 100 if job has subtitiles e.g ranges
      setZoomValue(100);
    } else {
      //In other cases without ranges like protocols and interviews zoom is set to 0 e.g no zoom at all.
      setZoomValue(0);
    }
  }, [showSubtitles]);

  useEffect(() => {
    setUserShortcuts(userShortcutsWithDefaults);
  }, [userSettings, userShortcutsWithDefaults]);

  const handleQueryParams = async () => {
    const time = Number(queryParameters.get("t"));
    const tc = Number(queryParameters.get("tc"));
    let _time = time;
    if (tc) {
      _time = Number(tc) - getTcOffsetByStartTime(job.tcOffsets);
    }
    if (_time) {
      MediaService.setOffset(_time);
      setInitialPlaybackPosition(_time);
      selectCurrentWord({ delay: 500 });
    }
  };

  // Actions
  const updateRanges = (newRanges: JobRange[], rangeIndex?: number) => {
    const updatedRanges = [...newRanges];
    validateJobRanges(updatedRanges, rangeIndex);

    job.data.ranges = updatedRanges;

    if (job && showSubtitles) {
      // const newSubtitlesData = createVtt(updatedRanges);
      // setSubtitlesData(newSubtitlesData);
    }

    if (
      !isChangesSaved.current &&
      !_.isEmpty(job.data?.ranges) &&
      !_.isEmpty(job.data.ranges)
    ) {
      const isChanged = !_.isEqual(job.data.ranges, job.data?.ranges);
      isChangesSaved.current = isChanged;
    }
  };

  // const initValidation = async (_job: JobWithData, lang: string) => {
  //   const _validationConfig = await EditorService.getValidationConfig(_job);
  //   validationConfig = _validationConfig;
  //   updateRanges(_job.data?.ranges);
  // };

  // const saveUserLastPosition = () => {
  //   const editorContainerEl = document.getElementById("editorContainer");

  //   const scrollOffsetTop = _.get(editorContainerEl, "scrollTop")
  //     ? (_.get(editorContainerEl, "scrollTop") as number)
  //     : 0;
  //   const rangeEl = document.getElementById(
  //     `range-${focusedRangeIndex.current}`
  //   ) as HTMLTextAreaElement;
  //   if (rangeEl) {
  //     const selectionStart = rangeEl.selectionStart;
  //     if (
  //       _.isNumber(selectionStart) &&
  //       focusedRangeIndex.current >= 0 &&
  //       job?.roomId
  //     ) {
  //       EditorService.saveUserLastPosition({
  //         jobId: job.roomId,
  //         cursorPosition: selectionStart,
  //         rangeIx: focusedRangeIndex.current,
  //         playbackPosition: MediaService.currentTime,
  //         scrollOffsetTop,
  //       });
  //     }
  //   }
  // };

  const openConfirmModal = (
    title: string,
    message: string,
    confirm: () => void,
    closeAfterConfirm = true
  ): void => {
    setModalType("danger");
    setModalContent(
      <ConfirmModal
        title={title}
        message={message}
        confirm={confirm}
        cancel={clearModalContent}
        closeAfterConfirm={closeAfterConfirm}
      />
    );
  };

  async function handleSaveJob(saveMethod: SaveTriggers) {
    // if (FeatureFlagsService.isEnabled("saveUserLastPosition", loggedInUser)) {
    //   saveUserLastPosition();
    // }
    if (isDisabled) return;

    if (slateRef.current?.editor && editorType === "slate") {
      updateJobData();
    }

    const updatedJob = getJobData();

    try {
      // console.log({ type: "info", tt: t("saving") }));
      await save(updatedJob, saveMethod);
      isChangesSaved.current = false;
      // console.log({ type: "success", tt: t("save_success") }));
      // idleTimer.reset();
    } catch (err) {
      // console.log({ type: "failure", tt: t("save_fail") }));
    }
  }

  const getJobData = () => {
    const updatedJob = {
      ...job,
      data: {
        ...job.data,
      },
      tcOffsets: MediaService.tcOffsets,
    } as JobWithData;
    return updatedJob;
  };

  const validateJobRanges = (
    ranges: JobRange[],
    rangeIndex?: number,
    subtitlesValidation?: ValidationsConfigData
  ) => {
    if (
      !jobSettings.subtitlesValidation ||
      !job.type.typeName.includes("subtitle")
    )
      return;
    let rangesToValidate = ranges;

    if (!_.isNil(rangeIndex)) {
      // Validating only current edited range with prev + next ranges
      const firstRangeIndex = Math.max(rangeIndex - 1, 0);
      const lastRangesIndex = rangeIndex + 1;

      rangesToValidate = ranges.slice(firstRangeIndex, lastRangesIndex + 1);
    }

    EditorService.validateJobRanges(
      rangesToValidate,
      subtitlesValidation || jobSettings.subtitlesValidation
    );
  };

  const validateAllRanges = useCallback(
    (validationsConfig?: ValidationsConfigData) => {
      if (!slateRef.current?.editor || !job.data?.ranges?.length) return;
      for (let i = 0; i < job.data.ranges.length; i += 3) {
        EditorService.runValidations({
          editor: slateRef.current.editor,
          rangeIndex: i,
          validationsConfig,
        });
      }
    },
    [job.data?.ranges]
  );

  const onMediaDurationLoad = useCallback(
    (duration: number) => {
      if (!slateRef.current?.editor) return;
      validateAllRanges({
        isRangeOutOfDuration: { duration } as any,
      });
      EditorService.createWaveformRanges(slateRef.current.editor, isDisabled);
    },
    [validateAllRanges]
  );

  // const approveJob = async () => {
  //   if (!job) return;
  //   try {
  //     await saveJob("done");
  //     navigate("/my-jobs");
  //   } catch (err) {
  //     logger.error("failed to done/approve meeting");
  //   }
  // };
  useEffect(() => {
    const autoSaveInterval = setInterval(() => {
      if (autoSave && userActive.current) {
        handleSaveJob(SaveTriggers.AUTO_SAVE);
      }
    }, 1000 * 60 * 1);
    if (!autoSave) {
      clearInterval(autoSaveInterval);
    }
    return () => clearInterval(autoSaveInterval);
  }, [autoSave, isDisabled]);

  // const handleUserIdle = () => {
  //   TrackingService.reportEvent("idle_start");
  // };

  // const handleUserActive = () => {
  //   const lastIdleTime = idleTimer.getLastActiveTime()?.getTime();
  //   if (lastIdleTime) {
  //     const idleDuration = Date.now() - lastIdleTime;
  //     TrackingService.reportEvent("idle_stop", {
  //       idle_duration: idleDuration,
  //       event_type: "idle_stop",
  //     });
  //   }
  // };

  // useEffect(() => {
  //   if (userActive) {
  //     handleUserActive();
  //   } else {
  //     handleUserIdle();
  //   }
  // }, [userActive]);

  const addActions = async (newActions: EditorAction[]) => {
    // setActions((prevActions) => {
    //   const newActionsMap = _.keyBy(newActions, "key");
    //
    //   const updatedActions = prevActions.map(
    //     (action) => newActionsMap[action.key] || action
    //   );
    //
    //   const additionalActions = newActions.filter(
    //     (action) => !prevActions.some((a) => a.key === action.key)
    //   );
    //
    //   return [...updatedActions, ...additionalActions];
    // });
  };

  // useEffect(() => {
  //   const _actions = actions.map((a) => {
  //     const timestampsEnterMethodActionLabel = t("timestamps_enter_method_1", {
  //       timestampsEnterMethod,
  //     });
  //     return a.key !== "timestampsEnterMethod"
  //       ? a
  //       : { ...a, label: timestampsEnterMethodActionLabel };
  //   });
  //   setActions(_actions);
  // }, [timestampsEnterMethod]);

  const toggleTimestampsEnterMethod = () => {
    setTimestampsEnterMethod((preveEnterMethod) => {
      const _enterMethod = preveEnterMethod === "word" ? "player" : "word";
      return _enterMethod;
    });
  };
  // -Actions

  // -Job

  // Words
  // - Words

  // Ranges
  // const setFocusedRangeIndex = (rangeIx: number) => {
  //   console.log({ rangeIx });
  //   focusedRangeIndex.current = rangeIx;
  //   console.log(focusedRangeIndex.current);
  // };

  const updateRangeTimes = ({
    rangeIndex,
    start,
    end,
    method,
  }: {
    rangeIndex: number;
    start?: number;
    end?: number;
    method: "button" | "text" | "waveform";
  }) => {
    if (!slateRef.current?.editor) return;
    //TODO: seriously consider structuredClone here, should be tested on big jobs
    const updatedRanges = structuredClone(
      EditorService.getRanges(slateRef.current.editor)
    );
    if (!_.isNumber(rangeIndex) || !updatedRanges[rangeIndex]) return;

    if (_.isNumber(start)) {
      const updatedStart = start;
      updatedRanges[rangeIndex].st = updatedStart;
      if (updatedRanges[rangeIndex].words[0]) {
        updatedRanges[rangeIndex].words[0].start_time = updatedStart;
      }
      if (updatedRanges[rangeIndex].words[0]) {
        updatedRanges[rangeIndex].words[0].time_edit = true;
      }
    }

    if (_.isNumber(end)) {
      const updatedEnd = end;
      const lastWordIndex = updatedRanges[rangeIndex].words.length - 1;
      updatedRanges[rangeIndex].et = updatedEnd;
      if (updatedRanges[rangeIndex].words[lastWordIndex]) {
        updatedRanges[rangeIndex].words[lastWordIndex].end_time = updatedEnd;
      }
      if (updatedRanges[rangeIndex].words[lastWordIndex]) {
        updatedRanges[rangeIndex].words[lastWordIndex].time_edit = true;
      }
    }

    if (job && showSubtitles) {
      const newSubtitlesData = "updateRangeTimes";
      setSubtitlesData(newSubtitlesData);
    }

    updatedRanges[rangeIndex].time_edit = true;

    const updatedRange = _.clone(updatedRanges[rangeIndex]);
    EditorService.updateNodeData({
      editor: slateRef.current?.editor,
      element: slateRef.current?.editor.children[rangeIndex] as CustomElement,
      data: { range: updatedRange },
    });

    EditorService.runValidations({
      editor: slateRef.current?.editor,
      rangeIndex,
    });

    EditorService.reorderRangeWords(slateRef.current.editor, rangeIndex);
  };

  const updateTcOffset: UpdateTcOffsetFn = ({
    editor,
    rangeIndex,
    startTime,
    tcOffset,
  }) => {
    let updatedJobTcOffsetArray = MediaService.tcOffsets
      ? [...MediaService.tcOffsets]
      : [[0, 0]];
    const isInitialJobPart =
      job.splitType === JobSplitType.SPLIT
        ? job.jobSplit?.splitIndex === 0
        : true;
    if (rangeIndex === 0 && isInitialJobPart) {
      updatedJobTcOffsetArray = [[0, tcOffset - startTime]];
    } else {
      const filteredTcOffsetArray = updatedJobTcOffsetArray.filter(
        (tc) => tc[0] < startTime
      );

      updatedJobTcOffsetArray = [
        ...filteredTcOffsetArray,
        [startTime, tcOffset],
      ];
    }

    const latterJobSplitsTcOffsets =
      (MediaService.tcOffsets || []).filter(
        (tc) => tc[0] > (job.jobSplit?.endTime || job.duration)
      ) || [];

    const sortedAndUniqTcOffset = _.uniqWith(
      _.sortBy(
        [...updatedJobTcOffsetArray, ...latterJobSplitsTcOffsets],
        ([tcStartTime]) => tcStartTime
      ),
      _.isEqual
    );
    MediaService.tcOffsets = sortedAndUniqTcOffset;

    const updatedJob = {
      ...job,
      data: { ...job.data, ranges: EditorService.getRanges(editor) },
      tcOffsets: updatedJobTcOffsetArray,
    };

    const newSlateState = EditorService.formatJobDataToEditorValue(
      updatedJob,
      mode
    );

    EditorService.expensivelyResetEditorState(editor, newSlateState);
  };

  // - Ranges

  const getWaveformRanges = () => (
    <WaveformRanges
      editor={slateRef.current?.editor}
      ranges={showSubtitles ? job.data.ranges : []}
      peaks={jobSettings.peaks}
      updateRangeTimes={updateRangeTimes}
      jobId={job.idJob}
      initialZoomValue={showSubtitles ? 100 : 0}
      showZoomOption={!!showSubtitles}
      focusRange={(rangeIndex) =>
        scrollInto(`rangeContainer-${rangeIndex}`, "editorContainer")
      }
      initialPlaybackPosition={initialPlaybackPosition}
      disabled={isDisabled}
    />
  );

  const config = useMenuBar(menuBar);

  return (
    <div className={classNames("EditableEditor", mode)}>
      <Menubar config={{ ...config, direction: platformDirection }} />
      <div className={classNames("editorContainer", editorDirection)}>
        {(mode === "transcript" || mode === "subtitles") && (
          <SlateEditor
            job={job}
            mode={mode}
            ref={slateRef}
            updateTcOffset={updateTcOffset}
            addActions={addActions}
            isFindAndReplaceOpen={isFindAndReplaceOpen} //TODO: use slate state
            toggleIsFindAndReplaceOpen={toggleIsFindAndReplaceOpen} //TODO: use slate state
            jobSettings={jobSettings}
            handleReadOnlyClick={handleReadOnlyClick}
            isTranslationMode={isTranslationMode.current}
          />
        )}
        {!_.isEmpty(job.media) && mode === "subtitles" && (
          <MediaPlayer // Separating media players is a mistake
            mode={mode}
            // loggedInUser={loggedInUser}
            editorController={slateRef.current?.editor}
            initialPlaybackPosition={initialPlaybackPosition}
            // currentCaption={getCurrentCaptionWords()}
            direction={editorDirection}
            mediaSources={job.media}
            offsets={{ 0: 0 }}
            displayText={showSubtitles}
            jobId={job.idJob}
            tcOffsets={job.tcOffsets}
            downloadMedia={downloadMedia}
            // openVideoOnStart={!!queryParameters.get("t")}
            openVideoOnStart={true}
            onMediaDurationLoad={onMediaDurationLoad}
            startTimeOffset={job.jobSplit?.startTime}
          >
            {getWaveformRanges()}
          </MediaPlayer>
        )}
        {!_.isEmpty(job.media) && mode === "transcript" && (
          <TranscriptMediaPlayer // Separating media players is a mistake
            // loggedInUser={loggedInUser}
            initialPlaybackPosition={initialPlaybackPosition}
            onMediaDurationLoad={onMediaDurationLoad}
            currentCaption={""} //getCurrentCaptionWords()
            // onCurrentTimeUpdate={handleCurrentTimeUpdate}
            direction={editorDirection}
            mediaSources={job.media}
            offsets={{ 0: 0 }}
            subtitlesData={subtitlesData}
            displayText={showSubtitles}
            jobId={job.idJob}
            tcOffsets={job.tcOffsets}
            downloadMedia={downloadMedia}
            openVideoOnStart={!!queryParameters.get("t")}
            startTimeOffset={job.jobSplit?.startTime}
          >
            {getWaveformRanges()}
          </TranscriptMediaPlayer>
        )}
      </div>
    </div>
  );
};

export type { EditorProps, SlateEditorImperativeRef };
export default forwardRef(Editor);
