import { type UpdateClientUploadsSettings } from "../../../../../index";
import { CardItem, CardToggleGroup } from "../../../../../v3";
import { useCallback, useMemo, useState } from "react";
import {
  faBars,
  faDoNotEnter,
  faMessageQuestion,
  faScreenUsers,
} from "@fortawesome/pro-light-svg-icons";
import { SpeakersNumberInput } from "./SpeakersNumberInput";
import { useTranslation } from "react-i18next";
import {
  DiarizeTypes,
  Direction,
  Upload,
  UploadFileForm,
} from "@sumit-platforms/types";
import { ImportAAFInput } from "./ImportAAFInput";
import { Typography } from "@mui/material";

import "./SpeakersSplitInput.scss";

interface Props {
  uploadState: UploadFileForm;
  onUploadsUpdate: UpdateClientUploadsSettings;
  dir?: Direction;
  showAaf?: boolean;
}

export const SpeakersSplitInput = ({
  onUploadsUpdate,
  uploadState,
  dir,
  showAaf = true,
}: Props) => {
  const { t } = useTranslation();
  const [speakerNumber, setSpeakerNumber] = useState<number | null>(null);
  const protocolSpeakersSplitButtonsGroup = useMemo(() => {
    return [
      {
        label: t("automatic"),
        id: "unsupervised",
        icon: faMessageQuestion,
        tooltip: t("automatic_tooltip"),
      },
      {
        label: t("speakers_bank"),
        id: "bank_diarize",
        icon: faScreenUsers,
        tooltip: t("speakers_bank_tooltip"),
        hide: true, // yet to implement
      },
      {
        label: t("multichannel"),
        id: "multichannel",
        icon: faBars,
        tooltip: t("multichannel_tooltip"),
      },
      {
        label: t("no_split"),
        id: "no_split",
        icon: faDoNotEnter,
        tooltip: t("no_split_tooltip"),
      },
    ] as CardItem[];
  }, [t]);

  const handleSpeakerSplitChange = useCallback(
    (newValue: string) => {
      onUploadsUpdate({
        data: newValue,
        field: "settings.diarize.diarize" as keyof Upload,
      });
    },
    [onUploadsUpdate]
  );

  const getSpeakersNumberFromInput = useCallback((number: number) => {
    let min = number;
    let max = number;

    if (number >= 5) {
      min = Math.floor(number * 0.85);
      max = Math.floor(number * 1.15);
    }
    return { min, max };
  }, []);

  const handleSpeakerNumberChange = useCallback(
    (e: any) => {
      const userInput = e.target.value;
      const { min, max } = getSpeakersNumberFromInput(userInput);
      onUploadsUpdate({
        data: min,
        field: "settings.diarize.diarize_param.min_speakers" as keyof Upload,
      });
      onUploadsUpdate({
        data: max,
        field: "settings.diarize.diarize_param.max_speakers" as keyof Upload,
      });
      setSpeakerNumber(userInput);
    },
    [onUploadsUpdate]
  );

  const shouldAafFileInput = useMemo(
    () =>
      showAaf &&
      uploadState.settings?.diarize?.diarize === DiarizeTypes.MULTICHANNEL,
    [uploadState.settings, showAaf]
  );

  return (
    <>
      <Typography variant={"h3"} mb={1}>
        {t("speakers_split")}:
      </Typography>
      <CardToggleGroup
        items={protocolSpeakersSplitButtonsGroup}
        onTabChange={handleSpeakerSplitChange}
        selectedTab={uploadState.settings?.diarize?.diarize as string}
        dir={dir}
      />
      {uploadState.settings?.diarize?.diarize === DiarizeTypes.UNSUPRTVISED && (
        <SpeakersNumberInput
          value={speakerNumber}
          onChange={handleSpeakerNumberChange}
        />
      )}
      {shouldAafFileInput && (
        <ImportAAFInput
          uploadState={uploadState}
          onUploadsUpdate={onUploadsUpdate}
        />
      )}
    </>
  );
};
