import { useTranslation } from "react-i18next";
import { type UpdateClientUploadsSettings } from "../../../../../index";
import { Switch } from "../../../../../v3";
import { JobTranscriptionSource, UploadFileForm } from "@sumit-platforms/types";
import { useCallback } from "react";
import Box from "@mui/material/Box";

export const SkipSttInput = ({
  onUploadsUpdate,
  uploadState,
}: {
  uploadState: UploadFileForm;
  onUploadsUpdate: UpdateClientUploadsSettings;
}) => {
  const { t } = useTranslation();
  const handleOnChange = useCallback(
    (e: any) => {
      onUploadsUpdate({
        data: e.target.checked ? JobTranscriptionSource.SKIP_STT : null,
        field: "jobType.transcriptionSource" as keyof UploadFileForm,
      });
    },
    [onUploadsUpdate, uploadState]
  );
  return (
    <Box
      display={"flex"}
      width={"100%"}
      alignItems={"center"}
      justifyContent={"space-between"}
    >
      <Switch
        label={t("dont_transcribe_my_media")}
        checked={
          uploadState.jobType?.transcriptionSource ===
          JobTranscriptionSource.SKIP_STT
        }
        id={"dont_transcribe_my_media"}
        onChange={handleOnChange}
        reverse
      />
    </Box>
  );
};
