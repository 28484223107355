import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Option } from "./MultiSelect";
import _ from "lodash";
import {
  Box,
  Checkbox,
  FormLabel,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Switch } from "../Switch/Switch";
import { Tooltip } from "../Tooltip/Tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/pro-light-svg-icons";

interface MultiSelectOptionProps {
  option: Option;
  handleSelect: (option: Option) => void;
  selected: any[];
  onSwitchCheck?: ({
    selected,
    checked,
  }: {
    selected: string | number | Date;
    checked: boolean;
  }) => void;
  isMulti?: boolean;
}

export const MultiSelectOption = ({
  option,
  handleSelect,
  selected,
  onSwitchCheck,
  isMulti,
}: MultiSelectOptionProps) => {
  const [switchChecked, setSwitchChecked] = useState<boolean>(
    _.isBoolean(option.switch?.checked) ? option.switch?.checked : false
  );
  const switchDisabled = useMemo(
    () => !selected.includes(option.value),
    [selected]
  );
  const handleOnSwitchCheck = useCallback(
    (checked: boolean) => {
      if (!option.switch) return;
      setSwitchChecked(checked);
      onSwitchCheck &&
        !_.isArray(option.value) &&
        onSwitchCheck({
          selected: option.value,
          checked,
        });
    },
    [option, onSwitchCheck]
  );
  useEffect(() => {
    if (switchDisabled) handleOnSwitchCheck(false);
  }, [selected]);

  return (
    <ListItem
      disabled={option.disabled}
      key={option.key || option.label}
      onClick={() => handleSelect(option)}
      className={"BazarMultiSelectOption"}
    >
      {option.startIconElem && option.startIconElem}
      <ListItemText
        primary={option.label}
        className={"BazarMultiSelectOptionLabel"}
      />
      {option.iconElem && option.iconElem}
      {option.switch && (
        <FormLabel onClick={(e) => e.stopPropagation()}>
          <Box display={"flex"}>
            <Box fontSize={"small"}>{option.switch.label}</Box>
            <Box>
              <Switch
                disabled={switchDisabled}
                checked={switchChecked}
                onChange={(e) => handleOnSwitchCheck(e.target.checked)}
                size={"small"}
              />
            </Box>
          </Box>
        </FormLabel>
      )}
      {option.toolTip && (
        <Tooltip title={option.toolTip} placement="right">
          <FontAwesomeIcon
            icon={faQuestionCircle}
            className={"tooltipTrigger"}
          />
        </Tooltip>
      )}
      {isMulti && <Checkbox checked={selected.includes(option.value)} />}
    </ListItem>
  );
};
