import { Box, Typography } from "@mui/material";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Button, ButtonProps } from "../../../index";

import "./CircleButton.scss";

interface InternalProps {
  icon?: IconProp;
  border?: "red" | "black" | null;
  text?: string;
  children?: React.ReactNode;
}

export const CircleButton = ({
  onClick,
  icon,
  border,
  ...props
}: Omit<ButtonProps, "children"> & InternalProps) => {
  return (
    <Button
      className={classNames("BazarCircleButton", props.className, {
        redBorder: border === "red",
        blackBorder: border === "black",
        disabled: props.disabled,
      })}
      onClick={onClick as any}
      disabled={props.disabled}
      sx={{ width: "20px" }}
    >
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"100%"}
      >
        {icon ? (
          <FontAwesomeIcon
            className={classNames("CircleButtonIcon")}
            icon={icon}
          />
        ) : null}
        {props.text && (
          <Typography className={"CircleButtonText"} mx={1}>
            {" "}
            {props.text}
          </Typography>
        )}
        {props.children && props.children}
      </Box>
    </Button>
  );
};
