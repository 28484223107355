import { Box, ThemeProvider } from "@mui/material";
import { useForm } from "react-hook-form";
import { sumitLogoBlack } from "@sumit-platforms/common-assets/images";
import { bazarTheme, Button, FormInput } from "../../../index";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { useCallback, useMemo, useState } from "react";
import UserService from "../../services/userService";
import { validateEmail } from "../../../utils";
import { useMutation } from "@tanstack/react-query";

import "./UnsubscribePage.scss";

export const UnsubscribePage = ({ config }: { config: any }) => {
  const { t } = useTranslation();
  const [success, setSuccess] = useState(false);

  const { isPending, mutate: unsubscribeMutation } = useMutation({
    mutationFn: (email: string) => userService.unsubscribe(email),
    onError: (error) => {
      console.log("unsubscribe error:", error);
      setSuccess(true);
    },
    onSuccess: () => {
      setSuccess(true);
    },
  });

  const userService = useMemo(() => UserService({ config }), [config]);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<{ email: string }>({
    mode: "onBlur",
  });

  const onSubmit = useCallback(
    async (form: { email: string }) => {
      setSuccess(false);
      unsubscribeMutation(form.email);
    },
    [unsubscribeMutation]
  );

  return (
    <ThemeProvider theme={bazarTheme}>
      <Box className="UnsubscribePage">
        <Box className="contentContainer">
          <Box className="headerTitle">
            <img src={sumitLogoBlack} alt="Sumit-AI" className="headerLogo" />
          </Box>
          <Typography variant={"h3"}>
            {t("unsubscribe_instructions")}
          </Typography>
          <form className={"unsubscribeForm"} onSubmit={handleSubmit(onSubmit)}>
            <FormInput
              register={register("email", {
                validate: (value: any) =>
                  validateEmail(value)
                    ? true
                    : (t("email_is_not_valid") as string),
                required: t("required_field"),
              })}
              label={`${t("email")}:`}
              error={errors.email?.message}
            />
            <Button
              className={"UnsubscribeButton"}
              loading={isPending}
              type={"submit"}
            >
              {t("unsubscribe")}
            </Button>
          </form>
          {success && (
            <Typography variant="subtitle2" color={"success"}>
              {t("unsubscribe_success")}
            </Typography>
          )}
        </Box>
      </Box>
    </ThemeProvider>
  );
};
