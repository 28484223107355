import React, { HTMLAttributes } from "react";
import CheckboxMui from "@mui/material/Checkbox";
import { SxProps } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/pro-light-svg-icons";
import { faCheckSquare } from "@fortawesome/pro-solid-svg-icons";

import "./Checkbox.scss";

export interface CheckboxProps extends HTMLAttributes<HTMLInputElement> {
  icon?: React.ReactNode;
  checkedIcon?: React.ReactNode;
  checked: boolean;
  sx?: SxProps;
}

export const Checkbox = ({
  icon = <FontAwesomeIcon icon={faSquare} color={"#FDB810"} size={"lg"} />,
  checkedIcon = (
    <FontAwesomeIcon icon={faCheckSquare} color={"#FDB810"} size={"lg"} />
  ),
  checked,
  onChange,
  sx,
}: CheckboxProps) => {
  return (
    <div className="BazarCheckbox">
      <CheckboxMui
        icon={icon}
        checkedIcon={checkedIcon}
        checked={checked}
        onChange={onChange}
        sx={sx}
      />
    </div>
  );
};
