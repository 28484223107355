import Box from "@mui/material/Box";
import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Direction, LanguageData, Languages } from "@sumit-platforms/types";
import type { UpdateClientUploadsSettings } from "../NewUploads";
import { formatLanguagesToOptions } from "../../../../utils";
import { Select } from "../../../../v3";

interface Props {
  inputLanguage: Languages[];
  outputLanguage: Languages[];
  onUploadsUpdate: UpdateClientUploadsSettings;
  languages: LanguageData[];
  dir?: Direction;
}

export const LanguageInputs = ({
  inputLanguage,
  outputLanguage,
  onUploadsUpdate,
  languages,
  dir,
}: Props) => {
  const { t } = useTranslation();
  const languagesOptions = useMemo(
    () => formatLanguagesToOptions(languages, t),
    [languages, t]
  );

  const handleInputLanguageOnChange = useCallback(
    (lang: (number | string)[]) => {
      onUploadsUpdate({
        data: lang ? (lang as Languages[]) : [],
        field: "inputLanguage",
      });
    },
    [onUploadsUpdate]
  );

  const handleOutputLanguageOnChange = useCallback(
    (lang: (number | string)[]) => {
      onUploadsUpdate({
        data: (lang as Languages[]) || [],
        field: "outputLanguage",
      });
    },
    [onUploadsUpdate]
  );
  return (
    <Box className={"LanguageInputs"} my={1.5}>
      <Select
        className={"inputLanguage"}
        options={languagesOptions as any}
        value={inputLanguage?.length ? [inputLanguage[0]] : []}
        label={t("input_language_label")}
        placeholder={t("select_languages")}
        onChange={handleInputLanguageOnChange}
        multiple={false}
        dir={dir}
      />

      <Select
        className={"outputLanguage"}
        label={t("output_language_label")}
        options={languagesOptions as any}
        value={outputLanguage}
        placeholder={t("select_languages")}
        onChange={handleOutputLanguageOnChange}
        multiple={true}
        dir={dir}
        optional
      />
    </Box>
  );
};
