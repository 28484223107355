import React, { useMemo } from "react";
import Box from "@mui/material/Box";
import { UploadPageSectionTitle } from "./UploadPageSectionTitle";
import { NewDragAndDrop } from "../../../core/DragAndDrop/NewDragAndDrop";
import { useTranslation } from "react-i18next";
import { faCloudArrowUp, faFolderOpen } from "@fortawesome/pro-light-svg-icons";
import { faGoogleDrive } from "@fortawesome/free-brands-svg-icons";
import {
  ActiveUpload,
  applicationMimeTypesAccept,
  audioMimeTypesAccept,
  srtMimeTypesAccept,
  Upload,
  UploadFileForm,
  UploadProgress,
  videoMimeTypesAccept,
} from "@sumit-platforms/types";
import { UploadsManager } from "./UploadsManager";

interface Props {
  uploadState: UploadFileForm;
  uploads: (Upload | ActiveUpload)[];
  progress: { [index: string]: UploadProgress | null };
  handleOnUpload: (files: File[]) => void;
  handleRemoveUpload?: (upload: Upload) => Promise<void>;
  openBrowseFiles: (e?: any) => void;
  openDrivePicker?: (e?: any) => void;
  uploadSizeLimitInGB?: number;
  onUploadNameEdit: (text: string, idUpload: number) => void;
  onOrderChange?: (uploads: (Upload | ActiveUpload)[]) => void;
  featureFlags?: {
    concatMedia?: boolean;
  };
}

const dragAndDropSupportedFormats = {
  ...audioMimeTypesAccept,
  ...videoMimeTypesAccept,
  ...applicationMimeTypesAccept,
  ...srtMimeTypesAccept,
};

export const UploadsManagerWrapper = ({
  uploads,
  uploadState,
  progress,
  handleOnUpload,
  openBrowseFiles,
  handleRemoveUpload,
  openDrivePicker,
  uploadSizeLimitInGB,
  onUploadNameEdit,
  onOrderChange,
  featureFlags,
}: Props) => {
  const { t } = useTranslation();
  const buttons = useMemo(() => {
    return [
      { icon: faFolderOpen, name: t("local_files"), onClick: openBrowseFiles },
      {
        icon: faGoogleDrive,
        name: t("google_drive"),
        onClick: openDrivePicker,
      },
    ];
  }, [openBrowseFiles, openDrivePicker]);

  return (
    <Box className={"UploadsManagerWrapper"}>
      <UploadPageSectionTitle
        title={`1. ${t("upload_media_title")}`}
        subtitle={t("upload_media_subtitle", {
          maxUploadInGB: uploadSizeLimitInGB,
        })}
        // subtitleTooltip={t("upload_media_subtitle_tooltip", {
        //   supportedFormatsString,
        // })}
      />
      {uploads.length ? (
        <UploadsManager
          uploads={uploads}
          progress={progress}
          buttons={buttons}
          handleRemoveUpload={handleRemoveUpload}
          uploadState={uploadState}
          onUploadNameEdit={onUploadNameEdit}
          onOrderChange={onOrderChange}
          allowConcat={featureFlags?.concatMedia}
        />
      ) : (
        <NewDragAndDrop
          className={"NewUploadsDragAndDrop"}
          handleOnDrop={handleOnUpload}
          title={t("upload_drag_and_drop_title")}
          subtitle={`${t("upload_drag_and_drop_subtitle")}:`}
          icon={faCloudArrowUp}
          buttons={buttons}
          accept={dragAndDropSupportedFormats}
        />
      )}
    </Box>
  );
};
