import { Dexie } from "dexie";
import _ from "lodash";
import { format } from "date-fns";
import { RecordEntity } from "@sumit-platforms/types";

const DB_NAME = "SumitAIDatabase";
const DB_VERSION = 1;

export type DBTableNames = "records"; // Extend this as needed
export type TableDefinitions = Record<DBTableNames, string>;

const TABLE_DEFINITIONS: TableDefinitions = {
  records: "id",
};

class ExtendedDexie extends Dexie {
  records!: RecordEntity;

  constructor() {
    super(DB_NAME);
    this.version(DB_VERSION).stores(TABLE_DEFINITIONS);
  }

  async upsert<T>(tableName: DBTableNames, data: Partial<T>) {
    const table = this.table(tableName);
    const id = (data as any)?.id;
    if (!id) throw new Error("No id provided to upsert method");
    const existing = await table.get(id);
    if (existing) {
      await table.update(id, { ..._.omit(data, ["id", "createdAt"]) } as any);
    } else {
      await table.add({
        ...data,
        id,
        createdAt: format(Date.now(), "dd/MM/yyyy, HH:mm"),
      } as T);
    }
  }
}

const indexDB = new ExtendedDexie();

export { indexDB };
