import React, { useRef, useState } from "react";
import {
  Box,
  ClickAwayListener,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/pro-solid-svg-icons";
import { ListItem, ListItemProps } from "./ListItem";
import { Direction } from "@sumit-platforms/types";
import classNames from "classnames";

import "./List.scss";

interface ListProps {
  items: ListItemProps[];
  collapsible?: boolean;
  collapseTitle?: string;
  dir?: Direction;
  onCollapse?: () => void;
}

export const List = ({
  items = [],
  collapsible = false,
  collapseTitle,
  dir,
  onCollapse,
}: ListProps) => {
  const [expanded, setExpanded] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  const toggleExpand = (event: any) => {
    event.stopPropagation();
    const _expanded = !expanded;
    setExpanded(_expanded);
    if (!_expanded && onCollapse) {
      onCollapse();
    }
  };

  const handleClose = (event?: any) => {
    if (
      event &&
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }
    setExpanded(false);
    if (onCollapse) {
      onCollapse();
    }
  };

  return (
    <Paper elevation={3} className={classNames("BazarList", dir)}>
      {collapsible ? (
        <>
          <Box
            ref={anchorRef}
            sx={{
              p: 2,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              cursor: "pointer",
              position: "relative", // Needed for proper Popper positioning
            }}
            className={"ListHeader"}
            onClick={toggleExpand}
          >
            <Typography className="ListHeaderText">
              {collapseTitle || ""}
            </Typography>
            <FontAwesomeIcon
              icon={expanded ? faChevronUp : faChevronDown}
              className={"chevronIcon"}
            />
          </Box>

          <Popper
            open={expanded}
            anchorEl={anchorRef.current}
            placement="bottom-start"
            style={{
              zIndex: 999,
              width: anchorRef.current?.offsetWidth || "100%",
            }}
          >
            <ClickAwayListener onClickAway={handleClose}>
              <Paper
                className={classNames("BazarList", dir)}
                sx={{
                  overflow: "scroll !important",
                  overflowX: "scroll !important",
                  overflowY: "scroll !important",
                }}
              >
                {items.map((item) => (
                  <ListItem
                    key={`${item.text}-${item.description}`}
                    text={item.text}
                    description={item.description}
                    actions={item.actions}
                    close={handleClose}
                    dir={dir}
                  />
                ))}
              </Paper>
            </ClickAwayListener>
          </Popper>
        </>
      ) : (
        items.map((item) => (
          <ListItem
            key={`${item.text}-${item.description}`}
            text={item.text}
            description={item.description}
            actions={item.actions}
            dir={dir}
            close={handleClose}
          />
        ))
      )}
    </Paper>
  );
};
