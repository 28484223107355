import Box from "@mui/material/Box";
import { CardItem, CardToggleGroup } from "../../../../v3";
import type { UpdateClientUploadsSettings } from "../NewUploads";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  faMessages,
  faFileLines,
  faList,
  faSubtitles,
} from "@fortawesome/pro-light-svg-icons";
import { Direction, JobTypes, Upload } from "@sumit-platforms/types";

interface Props {
  deliveryType?: keyof JobTypes;
  onUploadsUpdate: UpdateClientUploadsSettings;
  dir?: Direction;
}

export const DeliveryTypeGroup = ({
  deliveryType,
  onUploadsUpdate,
  dir,
}: Props) => {
  const { t } = useTranslation();
  const deliveryTypeButtonsGroup = useMemo(() => {
    return [
      {
        label: t("transcription"),
        id: "interview",
        icon: faMessages,
        tooltip: t("transcription_tooltip"),
      },
      {
        label: t("protocol"),
        id: "protocol",
        icon: faFileLines,
        tooltip: t("protocol_tooltip"),
      },
      {
        label: t("brief"),
        id: "brief",
        icon: faList,
        tooltip: t("brief_tooltip"),
      },
      {
        label: t("subtitles"),
        id: "subtitles",
        icon: faSubtitles,
        tooltip: t("subtitles_tooltip"),
      },
    ] as CardItem[];
  }, [t]);

  const handleOnChange = useCallback(
    (newValue: string) => {
      onUploadsUpdate({
        data: newValue,
        field: "jobType.typeName" as keyof Upload,
      });
    },
    [onUploadsUpdate]
  );

  return (
    <Box className={"deliveryTypeGroup"}>
      <CardToggleGroup
        items={deliveryTypeButtonsGroup}
        selectedTab={deliveryType || ""}
        onTabChange={handleOnChange}
        label={`${t("delivery_type")}:`}
        dir={dir}
      />
    </Box>
  );
};
