import _ from "lodash";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import axios from "axios";
import { getTimeNumberFromString, isVideoFormat } from "./../../utils";
import {
  EmptySubtitle,
  ExportEncoding,
  ExportOptions,
  ExportedFile,
  SpeakersDocxConfig,
  ZeroSubtitle,
  SummaryExportOptions,
} from "@sumit-platforms/types";

interface ExportServiceProps {
  config: any;
}
export const exportService = ({ config }: ExportServiceProps) => {
  const endpoint = `${config?.server?.host}/${config?.server?.export}`;

  const handleExportDocx = async ({
    jobIds,
    fileName,
    selectedRanges,
    idDocxTemplate,
    docxDefaultSettings,
    tc,
    exportSourceJob,
  }: {
    jobIds: number[];
    fileName: string;
    selectedRanges?: number[];
    idDocxTemplate?: number;
    docxDefaultSettings?: any;
    tc?: string | null;
    exportSourceJob?: boolean;
  }) => {
    const docxs = await createDocx({
      jobIds,
      selectedRanges,
      idDocxTemplate,
      docxDefaultSettings,
      tc,
      exportSourceJob,
    });

    await handleExportedFiles(docxs, fileName, "docx");
  };

  const handleExportLocators = async (
    jobIds: number[],
    fileName: string,
    tc?: string | null,
    encoding?: ExportEncoding,
    autoBreak?: boolean
  ) => {
    try {
      const locators = await createLocators(jobIds, tc, encoding, autoBreak);
      const exportExtension = jobIds.length > 1 ? "zip" : "txt";
      await handleLocatorsExport(locators, `${fileName}.${exportExtension}`);
    } catch (err) {
      console.log("err");
    }
  };

  const handleExportJson = async (jobIds: number[], fileName: string) => {
    const json = await createJson({
      jobIds,
    });
    await handleExportedFiles(json, fileName, "json");
  };
  const handleExportUSCJson = async (jobIds: number[], fileName: string) => {
    const json = await createUSCJson({
      jobIds,
    });
    await handleExportedFiles(json, fileName, "json");
  };

  const handleExportSubtitles = async ({
    jobIds,
    fileName,
    format,
    flip,
    zeroSubtitle,
    emptySubtitle,
    autoBreak,
    tc,
  }: {
    jobIds: number[];
    fileName: string;
    format: string;
    flip?: boolean;
    zeroSubtitle?: ZeroSubtitle | null;
    emptySubtitle?: EmptySubtitle;
    autoBreak?: boolean;
    tc?: string | null;
  }) => {
    const subtitles = await createSubtitles({
      jobIds,
      format,
      flip,
      zeroSubtitle,
      emptySubtitle,
      autoBreak,
      tc,
    });
    await handleExportedFiles(subtitles, fileName, format);
  };

  const handleExportedFiles = async (
    files: ExportedFile[],
    fileName?: string,
    format?: string
  ): Promise<void> => {
    const zip = new JSZip();
    if (files.length > 1) {
      await Promise.all(
        files.map(async (file: ExportedFile) => {
          const base64Response = await fetch(file.data);
          return zip.file(
            `${file.filename.replaceAll("/", "")}`,
            base64Response.blob()
          );
        })
      );
      zip.generateAsync({ type: "blob" }).then((content) => {
        saveAs(content, `${fileName ? fileName : "unknown"}.zip`);
      });
    } else {
      const _fileName = fileName ? `${fileName}.${format}` : files[0].filename;
      saveAs(files[0].data, _fileName);
    }
  };

  const handleLocatorsExport = async (
    jobsData: Blob,
    fileName: string
  ): Promise<void> => {
    saveAs(jobsData, fileName);
  };

  const createSubtitles = async ({
    jobIds,
    format,
    flip,
    zeroSubtitle,
    emptySubtitle,
    autoBreak,
    tc,
  }: {
    jobIds: number[];
    format: string;
    flip?: boolean;
    zeroSubtitle?: ZeroSubtitle | null;
    emptySubtitle?: EmptySubtitle;
    autoBreak?: boolean;
    tc?: string | null;
  }): Promise<ExportedFile[]> => {
    try {
      const response = await axios.post(endpoint + "/subtitles", {
        jobIds,
        format,
        flip,
        zeroSubtitle,
        emptySubtitle,
        autoBreak,
        tc,
      });

      if (response.status !== 200) throw new Error(response.statusText);

      const exportedFiles = response.data.files;
      return exportedFiles;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const createDocx = async ({
    jobIds,
    selectedRanges,
    idDocxTemplate,
    docxDefaultSettings,
    tc,
    exportSourceJob,
  }: {
    jobIds: number[];
    selectedRanges?: number[];
    idDocxTemplate?: number;
    docxDefaultSettings?: SpeakersDocxConfig;
    tc?: string | null;
    exportSourceJob?: boolean;
  }): Promise<ExportedFile[]> => {
    try {
      const response = await axios.post(endpoint + "/docx", {
        jobIds,
        selectedRanges,
        idDocxTemplate,
        docxDefaultSettings,
        tc,
        exportSourceJob,
      });
      if (response.status !== 200) throw new Error(response.statusText);

      const exportedFiles = response.data.files;
      return exportedFiles;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const createLocators = async (
    jobIds: number[],
    tc?: string | null,
    encoding?: string,
    autoBreak?: boolean,
    exportSourceJob?: boolean
  ): Promise<Blob> => {
    try {
      const offset = tc ? getTimeNumberFromString(tc) : null;
      const response = await axios.post(
        endpoint + "/locators",
        {
          jobIds,
          offset,
          encoding,
          autoBreak,
          exportSourceJob,
        },
        { responseType: "blob" }
      );
      if (response.status !== 200) throw new Error(response.statusText);

      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const createExtract = async (
    data: any,
    searchParams: any
  ): Promise<string[]> => {
    try {
      const response = await axios.post(endpoint + "/docx/extract", {
        data,
        searchParams,
      });
      if (response.status !== 200) throw new Error(response.statusText);

      return response.data.files;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const createJson = async ({
    jobIds,
  }: {
    jobIds: number[];
  }): Promise<ExportedFile[]> => {
    try {
      const response = await axios.post(endpoint + "/json", {
        jobIds,
      });

      if (response.status !== 200) throw new Error(response.statusText);

      const exportedFiles = response.data.files;
      return exportedFiles;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const createUSCJson = async ({
    jobIds,
  }: {
    jobIds: number[];
  }): Promise<ExportedFile[]> => {
    try {
      const response = await axios.post(endpoint + "/uscJson", {
        jobIds,
      });

      if (response.status !== 200) throw new Error(response.statusText);

      const exportedFiles = response.data.files;
      return exportedFiles;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const handleBurnSubtitles = async ({
    jobIds,
    format,
    flip,
    zeroSubtitle,
    emptySubtitle,
  }: ExportOptions & { jobIds: number[] }) => {
    try {
      const response = await axios.post(endpoint + "/burn", {
        jobIds,
        format,
        flip,
        zeroSubtitle,
        emptySubtitle,
      });
      if (response.status !== 200) throw new Error(response.statusText);
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const exportJob = async ({
    jobIds,
    selectedRanges,
    fileName,
    format,
    flip,
    tc,
    encoding,
    idDocxTemplate,
    docxDefaultSettings,
    zeroSubtitle,
    emptySubtitle,
    autoBreak,
    exportSourceJob,
  }: ExportOptions & {
    jobIds: number[];
    fileName: string;
  }) => {
    switch (format) {
      case "docx":
        await handleExportDocx({
          jobIds,
          fileName,
          selectedRanges,
          idDocxTemplate,
          docxDefaultSettings,
          tc,
          exportSourceJob,
        });
        break;
      case "locators":
        await handleExportLocators(jobIds, fileName, tc, encoding, autoBreak);
        break;
      case "json":
        await handleExportJson(jobIds, fileName);
        break;
      case "usc-json":
        await handleExportUSCJson(jobIds, fileName);
        break;
      default:
        if (isVideoFormat(format)) {
          await handleBurnSubtitles({ jobIds, format, flip });
        } else {
          await handleExportSubtitles({
            jobIds,
            fileName,
            format,
            flip,
            zeroSubtitle,
            emptySubtitle,
            autoBreak,
            tc,
          });
        }
        break;
    }
  };

  const createJobSummary = async ({
    idJob,
    options,
  }: {
    idJob: number;
    options: SummaryExportOptions;
  }): Promise<any> => {
    try {
      const response = await axios.post(`${endpoint}/createJobSummary`, {
        idJob,
        options,
      });
      if (response.status !== 200) throw new Error(response.statusText);
    } catch (err) {
      console.error(`Error in createJobSummary, with error: ${err}`);
      throw err;
    }
  };

  const downloadJobSummary = async ({
    idJob,
    options,
  }: {
    idJob: number;
    options: SummaryExportOptions;
  }): Promise<any> => {
    try {
      const response = await axios.post(
        `${endpoint}/downloadJobSummary`,
        {
          idJob,
          options,
        },
        {
          responseType: "blob",
        }
      );

      if (response.status !== 200) throw new Error(response.statusText);

      const fileName = `${options.fileName}.docx`;
      saveAs(
        new Blob([response.data], {
          type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
        }),
        fileName
      );

      return true;
    } catch (err) {
      console.error(`Error in downloadJobSummary, with error: ${err}`);
      throw err;
    }
  };

  return {
    createSubtitles,
    handleExportSubtitles,
    createDocx,
    handleExportDocx,
    createLocators,
    handleExportLocators,
    createExtract,
    handleExportedFiles,
    createJson,
    handleExportJson,
    createUSCJson,
    handleBurnSubtitles,
    exportJob,
    createJobSummary,
    downloadJobSummary,
  };
};
