import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleX } from "@fortawesome/pro-light-svg-icons";
import { SpinningLoader } from "../../../../index";
import React from "react";

interface SubmissionStatusProps {
  isSubmitLoading: boolean;
  isUploadSuccess: boolean;
  uploadError?: { message?: string };
}

export const SubmissionStatus = ({
  isSubmitLoading,
  isUploadSuccess,
  uploadError,
}: SubmissionStatusProps) => {
  if (!isSubmitLoading && !isUploadSuccess && !uploadError?.message) {
    return null;
  }

  return (
    <Box display="flex" alignItems="center" height="100%" width="100px" mr={3}>
      {isSubmitLoading && (
        <SpinningLoader
          style={{
            margin: "auto",
            height: "100px",
            width: "100px",
          }}
        />
      )}
      {isUploadSuccess && (
        <FontAwesomeIcon
          icon={faCircleCheck}
          size="6x"
          className="successIcon"
        />
      )}
      {uploadError?.message && !isSubmitLoading && (
        <FontAwesomeIcon icon={faCircleX} size="6x" className="failIcon" />
      )}
    </Box>
  );
};
