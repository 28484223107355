import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import {
  ActiveUpload,
  Upload,
  UploadFileForm,
  UploadProgress,
} from "@sumit-platforms/types";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import { Button, SearchAndFilters } from "../../../../index";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { SortableUploadRow } from "./SortableUploadRow";

interface Props {
  uploadState: UploadFileForm;
  uploads: (Upload | ActiveUpload)[];
  buttons: {
    icon: IconProp;
    name: string;
    onClick?: Function;
    disabled?: boolean;
  }[];
  handleRemoveUpload?: (upload: Upload) => Promise<void>;
  progress: { [index: string]: UploadProgress | null };
  onUploadNameEdit: (text: string, idUpload: number) => void;
  onOrderChange?: (uploads: (Upload | ActiveUpload)[]) => void;
  allowConcat?: boolean;
}

export const UploadsManager = ({
  uploadState,
  uploads,
  buttons,
  handleRemoveUpload,
  progress,
  onUploadNameEdit,
  onOrderChange,
  allowConcat,
}: Props) => {
  const { t } = useTranslation();
  const [loadingState, setLoadingState] = useState<Record<string, boolean>>({});
  const [searchTerm, setSearchTerm] = useState("");

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const _handleRemoveUpload = useCallback(
    async (up: Upload | ActiveUpload) => {
      if (handleRemoveUpload) {
        setLoadingState((prev) => ({ ...prev, [up.idUpload]: true }));
        await handleRemoveUpload(up as Upload);
        setLoadingState((prev) => ({ ...prev, [up.idUpload]: false }));
      }
    },
    [loadingState, handleRemoveUpload]
  );

  const handleSearchUploads = useCallback(
    (e: any) => {
      setSearchTerm(e.target.value);
    },
    [uploads]
  );

  const handleDragEnd = useCallback(
    (event: any): any => {
      const updatedUploads = _.clone(uploads);
      const { active, over } = event;

      if (active.id !== over.id) {
        const oldIndex = updatedUploads.findIndex(
          (item) => item.idUpload === active.id
        );
        const newIndex = updatedUploads.findIndex(
          (item) => item.idUpload === over.id
        );

        const newItems = arrayMove(updatedUploads, oldIndex, newIndex);

        // Notify parent component about the order change
        if (onOrderChange) {
          onOrderChange(newItems);
        }

        return newItems;
      }
    },
    [onOrderChange]
  );

  const showReorderingHint = useMemo(
    () => uploads.length > 1 && uploadState.concatMedia,
    [uploads, uploadState]
  );

  const getSearchedUploads = useCallback(() => {
    const filteredUploads = uploads.filter((upload) =>
      upload.name.toLowerCase().trim().includes(searchTerm.toLowerCase().trim())
    );
    return filteredUploads;
  }, [uploads, searchTerm]);

  return (
    <Box className={"UploadsManager"} pt={2}>
      <Typography variant="body1" pb={1}>
        {t("add_more_files")}:
      </Typography>
      {buttons?.length && (
        <Box className={"UploadsManagerButtonsWrapper"} display="flex" gap={2}>
          {buttons.map((btn, index) => (
            <Button
              key={index}
              startIcon={<FontAwesomeIcon icon={btn.icon} />}
              onClick={btn.onClick as any}
              disabled={btn.disabled}
              variant="outlined"
            >
              {btn.name}
            </Button>
          ))}
        </Box>
      )}
      <Box className={"UploadRowsWrapper"} py={2}>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="start"
          flexDirection={"column"}
        >
          <SearchAndFilters defaultQuery={{}} onSearch={handleSearchUploads} />
          {showReorderingHint && (
            <Typography mt={1} variant="body2" color="text.secondary">
              {t("drag_to_reorder_files")}
            </Typography>
          )}
        </Box>

        <Box className={"UploadRows"}>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
          >
            <SortableContext
              items={getSearchedUploads().map((up) => up.idUpload)}
              strategy={verticalListSortingStrategy}
            >
              {getSearchedUploads()?.map((up) => (
                <SortableUploadRow
                  key={up.idUpload}
                  id={up.idUpload}
                  upload={up}
                  handleRemoveUpload={() => _handleRemoveUpload(up)}
                  progress={progress ? progress[up.idUpload] : null}
                  loading={loadingState[up.idUpload]}
                  isGlobalTranscriptAttached={
                    !!uploadState.tempTranscriptionFile
                  }
                  isGlobalAafAttached={!!uploadState.tempAAFFile}
                  onUploadNameEdit={onUploadNameEdit}
                  showReorderingHint={!!showReorderingHint && !!allowConcat}
                  disableDrag={!allowConcat}
                />
              ))}
            </SortableContext>
          </DndContext>
        </Box>
      </Box>
    </Box>
  );
};
