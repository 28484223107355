import { useCallback, useState } from "react";
import _ from "lodash";
import { Box } from "@mui/material";
import { Button } from "../../../../../../index";
import { recordStore } from "../../../../../../store";
import { useTranslation } from "react-i18next";

interface Props {
  handleSubmit: () => Promise<any>;
  handleCancel: () => void;
}

export const SaveRecordSubmission = ({ handleSubmit, handleCancel }: Props) => {
  const { t } = useTranslation();
  const { uploadSettings } = recordStore();

  const isSubmitDisabled = () => {
    const deliveryTypeNotSelected = !uploadSettings.jobType?.typeName;
    const serviceNotSelected = _.isNil(uploadSettings.jobType?.autoTranscript);
    const langNotSelected = !uploadSettings.inputLanguage?.length;
    return deliveryTypeNotSelected || serviceNotSelected || langNotSelected;
  };

  return (
    <Box
      className={"recordSubmission"}
      mt={2}
      display={"flex"}
      justifyContent={"flex-end"}
      gap={2}
    >
      <Button variant={"outlined"} onClick={handleCancel}>
        {t("cancel")}
      </Button>
      <Button disabled={isSubmitDisabled()} onClick={handleSubmit}>
        {t("submit")}
      </Button>
    </Box>
  );
};
