import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  Button,
  IconButton,
  type UpdateClientUploadsSettings,
} from "../../../../../index";
import { useCallback, useMemo, useRef, useState } from "react";
import {
  Attachment,
  attachmentsMimeTypes,
  AttachmentType,
  fileInputAccept,
  UploadFileForm,
} from "@sumit-platforms/types";
import { AttachmentService } from "../../../../../services";
import { faTrash } from "@fortawesome/pro-light-svg-icons";

interface Props {
  uploadState: UploadFileForm;
  onUploadsUpdate: UpdateClientUploadsSettings;
  config: any;
  idClient?: number;
}

export const AttachmentsInput = ({
  onUploadsUpdate,
  uploadState,
  config,
  idClient,
}: Props) => {
  const { t } = useTranslation();
  const fileInput = useRef<any>();

  const attachmentService = useMemo(() => {
    return AttachmentService({
      config,
    });
  }, []);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const addAttachments = useCallback(() => {
    fileInput.current?.click();
  }, []);

  const handleUploadAttachment = async () => {
    try {
      if (!idClient) return;
      const files = Array.from(
        fileInput.current?.files ? fileInput.current?.files : []
      );

      if (files.length === 0) return;
      setIsLoading(true);
      const newAttachments = await Promise.all(
        //TODO: continue here
        files.map((file) =>
          attachmentService.create(
            AttachmentType.NOTE,
            [-1], //NOTE: this attachment are not connected to the idUpload
            // once upload is done, well attach the into job. If the user is refreshing the page, their gone
            idClient,
            file as File
          )
        )
      );
      if (!newAttachments?.length) return;
      const updatedAttachments = [
        ...(uploadState.attachments || []),
        ...(newAttachments || []),
      ].filter(Boolean);
      onUploadsUpdate({
        data: updatedAttachments as Attachment[],
        field: "attachments",
      });
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      throw new Error(err);
    }
  };

  const handleAttachmentRemove = async (idAttachment: number) => {
    try {
      setIsLoading(true);
      await attachmentService.remove(idAttachment);
      const newAttachments = uploadState.attachments?.filter(
        (a) => a.idAttachment !== idAttachment
      );
      onUploadsUpdate({
        data: newAttachments || [],
        field: "attachments",
      });
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      throw new Error(err);
    }
  };

  return (
    <Box className={"AttachmentsInput"} py={1}>
      <Typography variant={"h3"}>{t("attachments")}:</Typography>
      <Typography variant={"subtitle2"} py={0.5} mb={1}>
        {t("attachments_subtitle")}
      </Typography>
      <input
        className={"hiddenInput"}
        type="file"
        ref={fileInput}
        onChange={handleUploadAttachment}
        multiple
        accept={fileInputAccept(attachmentsMimeTypes)}
        style={{ display: "none" }}
      />
      <Button variant={"outlined"} loading={isLoading} onClick={addAttachments}>
        {t("upload_files")}
      </Button>
      {uploadState?.attachments?.map((att) => (
        <Box
          my={1}
          display="flex"
          className={"AttachmentsList"}
          alignItems={"center"}
        >
          <IconButton
            icon={faTrash}
            onClick={() => handleAttachmentRemove(att.idAttachment)}
            tooltipText={t("delete_attachment")}
            disabled={isLoading}
          />
          <Typography variant="body2" color={"text.primary"}>
            {att.name}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
