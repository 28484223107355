import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { Grid, Typography } from "@mui/material";
import { Button } from "../../core/Button/Button";
import { Modal } from "../../core/Modal/Modal";
import Input from "../../core/Input/Input";

import "./SummarizeJobModal.scss";

import {
  SummaryExportOptions,
  BriefFormat,
  BriefLength,
} from "@sumit-platforms/types";
import BriefLengthGroup from "./BriefLengthGroup";
import BriefFormatGroup from "./BriefFormatGroup";

export interface SummarizeJobModalProps {
  idJob: number;
  jobName: string;
  onApprove: (idJob: number, options: SummaryExportOptions) => Promise<void>;
  onCancel: () => void;
}

export const SummarizeJobModal = ({
  idJob,
  jobName,
  onApprove,
  onCancel,
}: SummarizeJobModalProps) => {
  const { t } = useTranslation();
  const [fileName, setFileName] = useState(jobName);
  const [message, setMessage] = useState("");
  const [briefLength, setBriefLength] = useState(BriefLength.SHORT);
  const [briefFormat, setBriefFormat] = useState(BriefFormat.BULLETS);
  const [loading, setLoading] = useState(false);

  const handleOnApprove = async () => {
    setLoading(true);
    try {
      setMessage("");
      await onApprove(idJob, { fileName, briefLength, briefFormat });
      setMessage("export_summary_modal_success");
    } catch (e) {
      console.log("e :", e);
    } finally {
      setLoading(false);
    }
  };

  const handleOnFileNameChange = (e: any) => {
    setFileName(e.target?.value);
  };

  const onBriefLengthChange = useCallback((val: BriefLength) => {
    setBriefLength(val);
  }, []);

  const onBriefFormatChange = useCallback((val: BriefFormat) => {
    setBriefFormat(val);
  }, []);

  return (
    <Modal type={"info"} closeModal={onCancel} className={"SummarizeJobModal"}>
      <Grid
        className={"summarizeModalContainer"}
        container
        display={"flex"}
        flexDirection={"column"}
        py={2}
        px={4}
        width={"25rem"}
        textAlign={"center"}
      >
        <Grid item pt={1}>
          <Typography sx={{ fontSize: 22 }} pb={1}>
            {t("brief_modal_title")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography>{t("brif_modal_description")}</Typography>
        </Grid>
        <Grid item pt={1} pb={1} px={1}>
          <Input
            value={fileName}
            onChange={handleOnFileNameChange}
            placeholder={t("file_name")}
            label={t("file_name")}
            className={"summarizeJobInput"}
          />
        </Grid>
        <Grid item pt={1} pb={1} px={1}>
          <BriefLengthGroup
            value={briefLength}
            onChange={onBriefLengthChange}
            isLoading={loading}
            defaultValue={briefLength}
          />
        </Grid>
        <Grid item pt={1} pb={3} px={1}>
          <BriefFormatGroup
            value={briefFormat}
            onChange={onBriefFormatChange}
            isLoading={loading}
            defaultValue={briefFormat}
          />
        </Grid>
        <Grid item display="flex" justifyContent={"center"} gap={3}>
          <Button onClick={onCancel} variant="outlined">
            {t("cancel") as string}
          </Button>
          <Button loading={loading} onClick={handleOnApprove} color="primary">
            {t("download") as string}
          </Button>
        </Grid>
        {message && (
          <Grid item pt={2}>
            <p className={"message"}>{message}</p>
          </Grid>
        )}
      </Grid>
    </Modal>
  );
};
