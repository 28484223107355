import React, { useMemo } from "react";
import { Chip as MuiChip, ThemeProvider } from "@mui/material";
import { bazarTheme } from "../../../bazar-theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { Direction } from "@sumit-platforms/types";
import classNames from "classnames";

import "./Tag.scss";

export interface TagProps {
  label: string;
  letterLimit?: number;
  onDelete?: () => void;
  moreSuffix?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
  dir?: Direction;
}

export const Tag = ({
  label,
  disabled,
  onDelete,
  letterLimit,
  style,
  dir,
}: TagProps) => {
  const handleOnAction = () => {
    if (onDelete) onDelete();
  };

  const _label = useMemo(() => {
    let _label = label;
    if (letterLimit && _label.length > letterLimit) {
      _label = `${label.slice(0, letterLimit)}...`;
    }
    return _label;
  }, [label, letterLimit]);

  return (
    <ThemeProvider theme={bazarTheme}>
      <MuiChip
        className={classNames("BazarTag", dir)}
        sx={style}
        color={"success"}
        label={_label}
        disabled={disabled}
        onDelete={handleOnAction}
        deleteIcon={
          onDelete ? (
            <FontAwesomeIcon className={"tagDeleteIcon"} icon={faTimes} />
          ) : (
            <></>
          )
        }
      />
    </ThemeProvider>
  );
};
