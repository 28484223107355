import React, { useState, useEffect } from "react";
import { Box, Grid, ThemeProvider, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import {
  bazarTheme,
  SpinningLoader,
  VoiceVolumeMeter,
} from "@sumit-platforms/ui-bazar";
import { recordStore } from "@sumit-platforms/ui-bazar/store";
import { RecordControlPanel } from "./RecordControlPanel";
import { secondsToTimeString } from "@sumit-platforms/ui-bazar/utils";
import { StopwatchResult } from "react-timer-hook";
import { useGlobalData } from "../../store";
import { RecordsHistory } from "./RecordsHistory";
import { RecordsIDBModel } from "@sumit-platforms/types";

import "./RecordPage.scss";

type Props = {
  isPermissionDenied: boolean;
  error: string;
  handleRecordToggle: () => void;
  handleDeleteRecord: () => void;
  openDeleteModal: () => void;
  handleSaveRecord: () => void;
  timer: StopwatchResult;
  onAir: boolean;
  isLoading: boolean;
  status: string;
  mediaDuration?: number | null;
  recordId: string;
  onSaveIDBRecordClick: (record: RecordsIDBModel) => void;
};

export const RecordPage = ({ recordUtils }: { recordUtils: Props }) => {
  const { t } = useTranslation();
  const [permissionGranted, setPermissionGranted] = useState<null | boolean>(
    null
  );
  const { isRecording: isRecordStart } = recordStore();
  const { direction } = useGlobalData();

  useEffect(() => {
    const requestMicrophonePermission = async () => {
      try {
        // Request audio permissions
        const stream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });

        // If we get here, permission was granted
        setPermissionGranted(true);

        // Important: release the stream to avoid keeping microphone active
        stream.getTracks().forEach((track) => track.stop());

        console.log("Microphone permission granted");
      } catch (err) {
        console.error("Error requesting microphone permission:", err);
        setPermissionGranted(false);
      }
    };

    requestMicrophonePermission();
  }, []);

  return (
    <ThemeProvider theme={bazarTheme}>
      <Grid
        className={"RecordPage Page"}
        container
        display={"flex"}
        justifyContent={"center"}
      >
        <Grid item xs={11} mt={2} height={"0px"}>
          <Typography>{t("record")}</Typography>
        </Grid>
        <Grid item xs={11} mt={3} textAlign={"center"} height={"0px"}>
          <Box
            className={"RecordsHistoryWrapper"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <RecordsHistory
              currentRecordId={recordUtils.recordId}
              onSaveIDBRecordClick={recordUtils.onSaveIDBRecordClick}
              dir={direction}
            />
          </Box>
        </Grid>

        <Grid xs={11} item mt={"auto"} textAlign={"center"}>
          <RecordControlPanel
            title={
              recordUtils.error ||
              permissionGranted === false ||
              recordUtils.isPermissionDenied
                ? recordUtils.isPermissionDenied || permissionGranted === false
                  ? t("allow_mic_access")
                  : t("general_mic_error", {
                      error: recordUtils.error || t("unknown_error"),
                    })
                : t("start_recording")
            }
            error={
              !!recordUtils.error ||
              permissionGranted === false ||
              recordUtils.isPermissionDenied
            }
            handleRecordToggle={recordUtils.handleRecordToggle}
            handleDeleteRecord={recordUtils.openDeleteModal}
            handleSaveRecord={recordUtils.handleSaveRecord}
            disabled={
              !!recordUtils.error ||
              recordUtils.isLoading ||
              permissionGranted === false ||
              recordUtils.isPermissionDenied ||
              recordUtils.status === "stopped"
            }
            isLoading={recordUtils.isLoading}
            dir={direction}
          />
          {recordUtils.error && (
            <Typography mt={0.5} color={"error"} variant={"subtitle2"}>
              {t("error")}: {recordUtils.error}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={11}
          textAlign={"center"}
          height={"0px"}
          className={"timerAndSpinner"}
          my={4}
        >
          <Box mb={1}>
            <Typography variant="h5">
              {isRecordStart &&
                secondsToTimeString(
                  recordUtils.mediaDuration || recordUtils.timer.totalSeconds
                )}
            </Typography>
          </Box>
          {recordUtils.isLoading && (
            <Box height={"50px"} width={"50px"} margin={"auto"}>
              <SpinningLoader />
            </Box>
          )}
        </Grid>
        <Grid xs={11} item width={"100%"} height={"60px"}>
          {isRecordStart && (
            <VoiceVolumeMeter isRecording={recordUtils.onAir} />
          )}
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
