import config from "../../config";
import { useAuth } from "@sumit-platforms/ui-bazar/hooks";

import Welcome from "./components/Welcome/Welcome";
import MeetingSchedule from "./components/MeetingSchedule/MeetingSchedule";

import "./Calendar.scss";

const Calendar = () => {
  const { user } = useAuth({ config });

  return (
    <div className="Calendar">
      <div className="firstRow">
        <div className="welcomeWrapper">
          <Welcome userName={user?.firstName} />
        </div>
      </div>
      <div className="secondRow">
        <div className="column">
          <MeetingSchedule />
        </div>
      </div>
    </div>
  );
};

export default Calendar;
