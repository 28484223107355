import React, { useCallback } from "react";
import { Direction, Tag, UploadFileForm } from "@sumit-platforms/types";
import { type UpdateClientUploadsSettings } from "../../../../../index";
import { TagsSelectInput } from "../../../../../v3";

interface Props {
  config: any;
  uploadState: UploadFileForm;
  onUploadsUpdate: UpdateClientUploadsSettings;
  idClient?: number;
  dir?: Direction;
}

export const TagsSelectWrapper = ({
  config,
  onUploadsUpdate,
  uploadState,
  idClient,
  dir,
}: Props) => {
  const onTagChange = useCallback(
    (tags: Tag[]) => {
      onUploadsUpdate({
        data: tags,
        field: "tags",
      });
    },
    [uploadState.tags]
  );

  const onTagCreate = useCallback(
    async (newTag: Tag) => {
      if (newTag) {
        onUploadsUpdate({
          data: [...(uploadState?.tags || []), newTag],
          field: "tags",
        });
      }
    },
    [uploadState.tags]
  );

  return (
    <TagsSelectInput
      value={uploadState.tags || []}
      onTagChange={onTagChange}
      onTagCreate={onTagCreate}
      idClient={idClient}
      config={config}
      dir={dir}
    />
  );
};
