import { CircleButton } from "@sumit-platforms/ui-bazar";
import { useTranslation } from "react-i18next";
import { faSave } from "@fortawesome/pro-light-svg-icons";

export const SaveButton = ({
  handleSaveRecord,
  isLoading,
}: {
  handleSaveRecord: () => void;
  isLoading: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <CircleButton
      className={"saveButton"}
      onClick={handleSaveRecord}
      icon={faSave}
      text={t("end_and_save")}
      disabled={isLoading}
    />
  );
};
