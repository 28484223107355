import { useTranslation } from "react-i18next";
import { type UpdateClientUploadsSettings } from "../../../index";
import { Direction, Project, UploadFileForm } from "@sumit-platforms/types";
import { useCallback, useMemo, useState } from "react";
import { Select, SelectOption } from "../../../v3";

type Props = {
  projects: Project[];
  uploadState: UploadFileForm;
  onUploadsUpdate: UpdateClientUploadsSettings;
  handleProjectCreate: (project: string) => Promise<Project | null>;
  dir?: Direction;
};

export const ProjectsDropdown = ({
  handleProjectCreate,
  onUploadsUpdate,
  uploadState,
  projects,
  dir,
}: Props) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const projectOptions = useMemo(
    () => projects?.map((p) => ({ value: p.idProject, label: p.name })),
    [projects]
  );

  const selectedProject: (string | number)[] = useMemo(() => {
    const selected = projectOptions.find(
      (p) => p.value === uploadState?.idProject
    );
    if (!selected) return [];
    return [selected.value];
  }, [uploadState?.idProject, projects, projectOptions]);

  const handleOnChange = useCallback(
    (idProjectSelected: (string | number)[]) => {
      const selected = projects.find((p) =>
        idProjectSelected.includes(p.idProject)
      );
      if (!selected) return;
      onUploadsUpdate({
        data: selected?.idProject,
        field: "idProject",
      });
    },
    [onUploadsUpdate, projects]
  );

  const handleAddProject = useCallback(
    async (option: SelectOption) => {
      const newProject = option.value as string;
      setLoading(true);
      const project = await handleProjectCreate(newProject);
      if (project) {
        onUploadsUpdate({
          data: project?.idProject,
          field: "idProject",
        });
      }
      setLoading(false);
    },
    [handleProjectCreate]
  );

  return (
    <Select
      className={"ProjectsDropdown"}
      label={t("project")}
      optional
      value={selectedProject}
      placeholder={t("insert_project_name")}
      onChange={handleOnChange}
      options={projectOptions}
      onNewValueTrigger={handleAddProject}
      loading={loading}
      dir={dir}
    />
  );
};
