import { Box } from "@mui/material";
import { Direction, Project } from "@sumit-platforms/types";
import { SpeakersSplitInput } from "../../../../../../index";
import { recordStore } from "../../../../../../store";
import { RecordOrganizeYourMedia } from "./RecordOrganizeYourMedia";
import { NotesInput } from "../../../../NewUploads/SetOrder/AdvancedSettings/NotesInput";
import { AttachmentsInput } from "../../../../NewUploads/SetOrder/AdvancedSettings/AttachmetsInput";

interface Props {
  dir?: Direction;
  projects: Project[];
  handleProjectCreate: (project: string) => Promise<Project | null>;
  config: any;
  idClient?: number;
}

export const RecordAdvancedSettings = ({
  dir,
  handleProjectCreate,
  projects,
  config,
  idClient,
}: Props) => {
  const { uploadSettings, updateUploadSettings } = recordStore();

  return (
    <Box className={"RecordAdvancedSettings"} py={2}>
      <SpeakersSplitInput
        uploadState={uploadSettings}
        onUploadsUpdate={updateUploadSettings}
        dir={dir}
        showAaf={false}
      />
      <RecordOrganizeYourMedia
        dir={dir}
        projects={projects}
        handleProjectCreate={handleProjectCreate}
        config={config}
        idClient={idClient}
      />
      <NotesInput
        uploadState={uploadSettings}
        onUploadsUpdate={updateUploadSettings}
      />
      <AttachmentsInput
        uploadState={uploadSettings}
        onUploadsUpdate={updateUploadSettings}
        config={config}
        idClient={idClient}
      />
    </Box>
  );
};
