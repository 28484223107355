import { CircleButton } from "../../../../index";
import { useTranslation } from "react-i18next";
import { faTrash } from "@fortawesome/pro-light-svg-icons";

export const DeleteButton = ({
  handleDeleteRecord,
  disabled,
  hideButtonsText,
}: {
  handleDeleteRecord: () => void;
  disabled: boolean;
  hideButtonsText?: boolean;
}) => {
  const { t } = useTranslation();

  return (
    <CircleButton
      className={"deleteButton"}
      onClick={handleDeleteRecord}
      border={"black"}
      icon={faTrash}
      text={hideButtonsText ? "" : t("remove")}
      disabled={disabled}
    />
  );
};
