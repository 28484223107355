import Uppy from "@uppy/core";
import type { FileProgressStarted } from "@uppy/utils/lib/FileProgress";

import Tus from "@uppy/tus";

export interface UploadConfig {
  onProgress?: (args: {
    uploadStartTime: number;
    bytesUploaded: number;
    bytesTotal?: number | null;
  }) => void;
  onSuccess: (payload: { uploadUrl: string; fileId: string }) => Promise<void>;
  onError?: (error: Error) => void;
  tusEndpoint: string;
  file: File;
}

export class FileUploader {
  public uppy: Uppy;
  public fileId?: string;
  private uploadStartTime: number;
  private config: UploadConfig;
  private tusEndpoint: string;

  constructor(config: UploadConfig) {
    this.config = config;
    this.uploadStartTime = Date.now();
    this.tusEndpoint = config.tusEndpoint;
    this.uppy = this.initUppy();
  }

  private initUppy(): Uppy {
    const uppy = new Uppy().use(Tus, {
      withCredentials: true,
      endpoint: this.tusEndpoint,
      retryDelays: [0, 1000, 3000, 5000],
    });

    this.setupDefaultEvents(uppy);
    return uppy;
  }

  private setupDefaultEvents(uppy: Uppy) {
    uppy.on("upload-progress", (file, progress) => {
      this.handleProgress(progress);
    });

    uppy.on("upload-success", async (_, result) => {
      await this.handleSuccess(result.uploadURL || "");
    });

    uppy.on("error", (error) => {
      this.handleError(error);
    });
  }

  public addFile(file: File): string {
    this.fileId = this.uppy.addFile(file);
    this.uppy.setFileMeta(this.fileId, { idUpload: this.fileId });
    return this.fileId;
  }

  public cancelUpload() {
    if (this.fileId) {
      this.uppy.removeFile(this.fileId);
    }
  }

  public async startUpload() {
    this.uppy.upload();
  }

  private handleProgress({ bytesUploaded, bytesTotal }: FileProgressStarted) {
    if (this.config.onProgress) {
      this.config.onProgress({
        uploadStartTime: this.uploadStartTime,
        bytesUploaded,
        bytesTotal,
      });
    }
  }

  private async handleSuccess(uploadUrl: string) {
    try {
      await this.config.onSuccess({
        uploadUrl,
        fileId: this.fileId!,
      });
    } catch (error: any) {
      this.handleError(error);
    }
  }

  private handleError(error: Error) {
    if (this.config.onError) {
      this.config.onError(error);
    }
  }
}
