import Box from "@mui/material/Box";
import React, { useEffect, useRef, useState } from "react";

interface VoiceVolumeMeterProps {
  isRecording: boolean;
  sensitivity?: number;
  barSpacing?: number; // Added prop for customizing bar spacing
}

export const VoiceVolumeMeter = ({
  isRecording,
  sensitivity = 8,
  barSpacing = 8, // Default spacing between bars
}: VoiceVolumeMeterProps) => {
  const [volume, setVolume] = useState(0);
  const [permissionDenied, setPermissionDenied] = useState(false);
  const [numBars, setNumBars] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const audioContext = useRef<AudioContext | null>(null);
  const analyser = useRef<AnalyserNode | null>(null);
  const microphone = useRef<MediaStreamAudioSourceNode | null>(null);
  const animationFrame = useRef<number | null>(null);

  const actualSensitivity = useRef(Math.pow(sensitivity, 1.5) / 3);

  useEffect(() => {
    actualSensitivity.current = Math.pow(sensitivity, 1.5) / 3;
  }, [sensitivity]);

  // Calculate number of bars based on container width
  useEffect(() => {
    const calculateBars = () => {
      if (containerRef.current) {
        // Use 95% of the container width to prevent UI sticking
        const containerWidth = containerRef.current.clientWidth * 0.95;
        // Calculate how many bars can fit (each bar is 2px wide plus spacing)
        const barsCount = Math.floor(containerWidth / (2 + barSpacing));
        // Ensure we have at least 1 bar
        setNumBars(Math.max(1, barsCount));
      }
    };

    // Calculate initially
    calculateBars();

    // Recalculate on window resize
    const handleResize = () => {
      calculateBars();
    };

    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [barSpacing]);

  useEffect(() => {
    if (isRecording) {
      setPermissionDenied(false);

      audioContext.current = new (window.AudioContext ||
        (window as any).webkitAudioContext)();
      analyser.current = audioContext.current.createAnalyser();
      analyser.current.fftSize = 1024; // Increase for more detailed analysis
      analyser.current.smoothingTimeConstant = 0.5; // Add some smoothing

      navigator.mediaDevices
        .getUserMedia({ audio: true, video: false })
        .then((stream) => {
          if (audioContext.current && analyser.current) {
            microphone.current =
              audioContext.current.createMediaStreamSource(stream);
            microphone.current.connect(analyser.current);

            analyzeVolume();
          }
        })
        .catch((err) => {
          console.error("Error accessing microphone:", err);

          if (
            err.name === "NotAllowedError" ||
            err.name === "PermissionDeniedError"
          ) {
            setPermissionDenied(true);
          }
        });
    } else {
      // Clean up when recording stops
      if (audioContext.current) {
        if (microphone.current) {
          microphone.current.disconnect();
          microphone.current = null;
        }

        if (analyser.current) {
          analyser.current.disconnect();
          analyser.current = null;
        }

        if (animationFrame.current) {
          cancelAnimationFrame(animationFrame.current);
          animationFrame.current = null;
        }

        if (audioContext.current.state !== "closed") {
          audioContext.current.close();
          audioContext.current = null;
        }

        setVolume(0);
      }
    }

    return () => {
      if (animationFrame.current) {
        cancelAnimationFrame(animationFrame.current);
      }

      if (audioContext.current && audioContext.current.state !== "closed") {
        if (microphone.current) {
          microphone.current.disconnect();
        }
        audioContext.current.close();
      }
    };
  }, [isRecording]);

  // Function to analyze microphone volume
  const analyzeVolume = () => {
    if (!analyser.current) return;

    const dataArray = new Uint8Array(analyser.current.frequencyBinCount);

    const updateVolume = () => {
      analyser.current?.getByteFrequencyData(dataArray);

      // Calculate weighted average focusing on vocal frequency range
      const vocalRangeStart = Math.floor(dataArray.length * 0.05); // ~100Hz
      const vocalRangeEnd = Math.floor(dataArray.length * 0.25); // ~3000Hz

      let sum = 0;
      let count = 0;

      // Put more weight on the vocal frequency range
      for (let i = 0; i < dataArray.length; i++) {
        const value = dataArray[i];
        if (i >= vocalRangeStart && i <= vocalRangeEnd) {
          // Vocal range gets higher weight
          sum += value * 1.5;
          count += 1.5;
        } else {
          // Other frequencies get normal weight
          sum += value;
          count += 1;
        }
      }

      const avg = sum / count;

      // Apply the sensitivity multiplier
      const sensitiveFactor = actualSensitivity.current;
      const adjustedValue = avg * sensitiveFactor;

      // Normalize to 0-100 scale with a non-linear curve for better visual response
      // This gives better results at lower volumes
      const normalizedVolume = Math.min(
        100,
        Math.max(0, Math.floor(Math.pow(adjustedValue / 255, 0.85) * 100))
      );

      setVolume(normalizedVolume);

      animationFrame.current = requestAnimationFrame(updateVolume);
    };

    updateVolume();
  };

  // Fixed heights that match the SVG pattern
  const barHeights = [
    14, 22, 30, 46, 14, 46, 62, 46, 30, 30, 14, 30, 62, 62, 78, 62, 78, 62, 46,
    46,
  ];

  // Generate the bars based on dynamic numBars
  const renderBars = () => {
    if (numBars <= 0) return null;

    const bars = [];

    for (let i = 0; i < numBars; i++) {
      // Get height from pattern
      const baseHeight = barHeights[i % barHeights.length];

      // Scale based on volume - use actual volume when recording
      const volumeFactor = volume / 100;
      const displayHeight = isRecording
        ? Math.max(4, Math.round(baseHeight * volumeFactor))
        : 4;

      bars.push(
        <Box
          key={i}
          sx={{
            height: `${displayHeight}px`,
            width: "2px",
            backgroundColor: isRecording ? "#EA1414" : "#3c5069",
            margin: `0 ${barSpacing / 2}px`,
            borderRadius: "1px",
            transition: "height 0.1s ease",
          }}
        />
      );
    }

    return bars;
  };

  // If microphone access was denied, show instructions
  if (permissionDenied) {
    return (
      <Box
        className={"BazarVoiceVolumeMeter"}
        sx={{
          width: "100%",
          height: "155px",
          backgroundColor: "#FBFDFF",
          borderRadius: "10px",
          boxShadow: "4px 4px 24px rgba(34, 41, 54, 0.03)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "20px",
          textAlign: "center",
          color: "#EA1414",
        }}
      >
        Microphone access denied. Please enable microphone access in your
        browser settings and reload the page.
      </Box>
    );
  }

  return (
    <Box
      className={"BazarVoiceVolumeMeter"}
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "155px",
          backgroundColor: "#FBFDFF",
          borderRadius: "10px",
          boxShadow: "4px 4px 24px rgba(34, 41, 54, 0.03)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          padding: "10px",
          overflow: "hidden",
        }}
      >
        <Box
          ref={containerRef}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            padding: "0 10px", // Add padding to ensure bars don't touch the edges
          }}
        >
          {renderBars()}
        </Box>
      </Box>
    </Box>
  );
};
