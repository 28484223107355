import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { type UpdateClientUploadsSettings } from "../../../../../index";
import { NotesInput } from "./NotesInput";
import { AttachmentsInput } from "./AttachmetsInput";
import { UploadFileForm } from "@sumit-platforms/types";
import { SkipSttInput } from "./SkipSttInput";
import { ImportTranscriptionInput } from "./ImportTranscriptionInput";

interface Props {
  uploadState: UploadFileForm;
  onUploadsUpdate: UpdateClientUploadsSettings;
  config: any;
  idClient: number;
  showSkipStt: boolean;
  showUseOwnTranscription: boolean;
}

export const OtherTranscriptionOptions = ({
  onUploadsUpdate,
  uploadState,
  config,
  idClient,
  showSkipStt,
  showUseOwnTranscription,
}: Props) => {
  const { t } = useTranslation();
  return (
    <Box className={"OtherTranscriptionOptions"} py={1.2}>
      <Typography variant={"h3"}>
        {t("other_transcription_options")}:
      </Typography>
      <Typography variant={"subtitle2"} py={0.5}>
        {t("other_transcription_options_subtitle")}:
      </Typography>
      <Box
        display={"flex"}
        flexDirection={"column"}
        width={"450px"}
        className={"transcriptionSource"}
        my={1}
      >
        {showSkipStt && (
          <SkipSttInput
            uploadState={uploadState}
            onUploadsUpdate={onUploadsUpdate}
          />
        )}
        {showUseOwnTranscription && (
          <ImportTranscriptionInput
            uploadState={uploadState}
            onUploadsUpdate={onUploadsUpdate}
          />
        )}
      </Box>
      <NotesInput uploadState={uploadState} onUploadsUpdate={onUploadsUpdate} />
      <AttachmentsInput
        uploadState={uploadState}
        onUploadsUpdate={onUploadsUpdate}
        config={config}
        idClient={idClient}
      />
    </Box>
  );
};
