import config from "../config";
import axios, { AxiosError } from "axios";

const recallEndpoint = `${config.server.host}/${config.server.recall}`;

interface IRecordEventArgs {
  meetingUrl: string;
  idClient: number;
  idUser: number;
  idEvent?: string;
  title?: string;
  startDate?: Date;
}

export const recordEvent = (data: IRecordEventArgs) =>
  axios.post(`${recallEndpoint}/recordEvent`, data);

export const cancelEventRecord = (idEvent: string) =>
  axios.post(`${recallEndpoint}/cancelEventRecording`, { idEvent });

export const getEvents = async ({
  start,
  end,
  calendarUser,
}: {
  start: Date;
  end: Date;
  calendarUser: any;
}) => {
  const events = await axios.get(`${recallEndpoint}/events`, {
    params: { start, end, calendarUser },
  });

  return events.data;
};
