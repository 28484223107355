import React, { useMemo } from "react";
import {
  ThemeProvider,
  Switch as MuiSwitch,
  SwitchProps as MuiSwitchProps,
  InputLabel,
  Box,
} from "@mui/material";
import { bazarTheme } from "../../../bazar-theme";
import classNames from "classnames";
import { InfoTooltipIcon } from "../../../core/InfoTooltipIcon/InfoTooltipIcon";

export interface SwitchProps extends MuiSwitchProps {
  setValue?: (value: boolean) => void;
  label?: string;
  labelStyle?: React.CSSProperties;
  infoTooltip?: string;
  width?: string;
  reverse?: boolean;
}

export const Switch = ({
  setValue,
  label,
  labelStyle,
  infoTooltip,
  width,
  reverse = false,
  ...props
}: SwitchProps) => {
  const id = useMemo(() => {
    return label ? `switch-${label}` : "";
  }, [label]);

  return (
    <ThemeProvider theme={bazarTheme}>
      <Box
        display={"flex"}
        alignItems={"center"}
        width={width || "100%"}
        justifyContent={"space-between"}
        className={classNames("BazarSwitch", props?.className)}
        flexDirection={reverse ? "row-reverse" : "row"}
      >
        {label ? (
          <InfoTooltipIcon tooltipText={infoTooltip}>
            <InputLabel
              htmlFor={props?.id || id}
              className={classNames({
                disabled: props.disabled,
              })}
              sx={{ cursor: "pointer", ...labelStyle }}
            >
              {label}
            </InputLabel>
          </InfoTooltipIcon>
        ) : null}
        <MuiSwitch
          onChange={(e) => {
            setValue && setValue(e.target.checked);
          }}
          id={id}
          {...props}
        />
      </Box>
    </ThemeProvider>
  );
};
