import _ from "lodash";
import config from "../../../../config";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import { useMutation, useQuery } from "@tanstack/react-query";
import { PublicClientApplication } from "@azure/msal-browser";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVideo } from "@fortawesome/pro-solid-svg-icons";
import { Calendar, dateFnsLocalizer } from "react-big-calendar";
import {
  format,
  parse,
  startOfWeek,
  getDay,
  startOfDay,
  addWeeks,
  endOfDay,
} from "date-fns";
import Cookies from "js-cookie";
import enUS from "date-fns/locale/en-US";
import {
  Button,
  EventRecordModal,
  GoogleSignInButton,
  MicrosoftSignInButton,
  SpinningLoader,
} from "@sumit-platforms/ui-bazar";
import { useAuth, useToast } from "@sumit-platforms/ui-bazar/hooks";
import { useModal } from "@sumit-platforms/ui-bazar/store";

import { clientStore } from "../../../../store/client";
import { useGlobalData } from "../../../../store";
import * as recallService from "../../../../services/recallService";

import {
  faCheck,
  faCopy,
  faVideoCamera,
} from "@fortawesome/pro-light-svg-icons";
import { useTranslation } from "react-i18next";

import MeetingScheduleEvent from "./MeetingScheduleEvent";
import {
  GoogleAuthService,
  LocalStorageService,
} from "@sumit-platforms/ui-bazar/services";

import "./MeetingSchedule.scss";

export type RecallEvent = {
  id: string;
  idBot: string;
  title: string;
  hasBot: boolean;
  start: Date;
  end: Date;
  meetingUrl: string;
  status: string | null;
  idJob?: number;
};

const locales = {
  "en-US": enUS,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const msalConfig = {
  auth: config.microsoftAuthProvider,
};

const MeetingSchedule = () => {
  const { t } = useTranslation();
  const { client } = clientStore();
  const { user } = useAuth({ config });
  const { setToast } = useGlobalData();
  const { toastSuccess, toastError } = useToast({ setToast });
  const [isLoading, setIsLoading] = useState(false);
  const [addressCopyCheck, setAddressCopyCheck] = useState(false);

  const googleAuthService = GoogleAuthService({ config });
  const [msalInstance, setMsalInstance] =
    useState<PublicClientApplication | null>(null);
  const [calendarUser, setCalendarUser] = useState<any>(
    LocalStorageService.getItem("must-calendar-user") || null
  );

  const { setModalContent, clearModalContent, setModalType } = useModal();
  const [loadingEventIds, setLoadingEventIds] = useState<Set<string>>(
    new Set()
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const [startDate, setStartDate] = useState<Date>(
    startOfWeek(new Date(), { weekStartsOn: 0 })
  );
  const [endDate, setEndDate] = useState<Date>(
    endOfDay(addWeeks(new Date(), 1))
  );

  useEffect(() => {
    const msalInstance = new PublicClientApplication(msalConfig);
    msalInstance
      .initialize()
      .then(() => {
        setMsalInstance(msalInstance);
      })
      .catch((err) => {
        console.error("MSAL initialization failed:", err);
        toastError(t("microsoft_login_failed"));
      });
  }, []);

  const { data, refetch } = useQuery({
    queryKey: [
      "calendar-events",
      startDate,
      endDate,
      calendarUser?.user?.email,
    ],
    queryFn: async () => {
      setIsLoading(true);
      try {
        switch (calendarUser?.provider) {
          case "google": {
            setCalendarUser({ ...calendarUser, provider: "google" });
            break;
          }
          case "microsoft": {
            const token = await getMicrosoftToken();
            const userSession = {
              ...calendarUser,
              provider: "microsoft",
              token,
            };
            setCalendarUser(userSession);
            LocalStorageService.setItem("must-calendar-user", userSession);

            break;
          }
          default:
            break;
        }

        const events = await recallService.getEvents({
          start: startDate,
          end: endDate,
          calendarUser,
        });

        return events;
      } finally {
        setIsLoading(false);
      }
    },
    enabled: !!calendarUser,
  });

  const recordEvent = useMutation({
    mutationKey: ["check-limit"],
    mutationFn: recallService.recordEvent,
    onSuccess: () => {
      refetch();
    },
  });

  const cancelEventRecording = useMutation({
    mutationKey: ["check-limit"],
    mutationFn: recallService.cancelEventRecord,
    onSuccess: () => {
      refetch();
    },
  });

  const googleLogin = useGoogleLogin({
    flow: "auth-code",
    onSuccess: async (tokenResponse) => {
      try {
        const authCode = tokenResponse.code;
        const user = await googleAuthService.authenticateWithCode(authCode);
        LocalStorageService.setItem("must-calendar-user", {
          provider: "google",
          user,
        });
        setCalendarUser({ provider: "google", user });
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },
    onError: () => {
      console.log("Login Failed");
    },
    scope: config.googleAuthProvider.scopes.calendar.join(" "),
  });

  const handleCalendarLogout = () => {
    setCalendarUser(null);
    Cookies.remove("google-auth");
    LocalStorageService.removeItem("must-calendar-user");
  };

  const handleMicrosoftLogin = async () => {
    try {
      if (!msalInstance) {
        throw new Error("MSAL not initialized");
      }
      const response = await msalInstance.loginPopup({
        scopes: ["Calendars.Read"],
      });

      const userSession = { provider: "microsoft", user: response.account };
      setMsalInstance(msalInstance);
      setCalendarUser(userSession);
      LocalStorageService.setItem("must-calendar-user", userSession);
    } catch (error) {
      console.error("microsoft login failed:", error);
      toastError(t("microsoft_login_failed"));
    }
  };

  const getMicrosoftToken = async () => {
    if (!calendarUser || !msalInstance) return null;
    try {
      const response = await msalInstance.acquireTokenSilent({
        scopes: ["Calendars.Read"],
        account: calendarUser?.user,
      });
      return response.accessToken;
    } catch (error: any) {
      if (error.name === "InteractionRequiredAuthError") {
        const response = await msalInstance.acquireTokenPopup({
          scopes: ["Calendars.Read"],
          account: calendarUser?.user,
        });
        return response.accessToken;
      }
      throw error;
    }
  };

  const events = useMemo(
    () =>
      data?.map((event: any) => ({
        ...event,
        start: new Date(event.start.dateTime),
        end: new Date(event.end.dateTime),
        title: event.summary,
      })) || [],
    [data]
  );

  const handleCalendarDateChange = (
    newSelectedDates: Date[] | { start: Date; end: Date }
  ) => {
    let startDate = new Date();
    let endDate = new Date();
    if (_.isArray(newSelectedDates)) {
      startDate = newSelectedDates[0];
      endDate = _.last(newSelectedDates) as Date;
    } else {
      startDate = newSelectedDates.start;
      endDate = newSelectedDates.end;
    }
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const handleOpenNewMeetingModal = () => {
    setModalContent(
      <EventRecordModal
        onApprove={handleCreateNewEventRecord}
        onCancel={clearModalContent}
      />
    );
  };

  const handleCopyInvitationLink = () => {
    if (!client) return;

    setAddressCopyCheck(true);
    setTimeout(() => {
      setAddressCopyCheck(false);
    }, 3000);

    const recordAddress = `record+${client?.idClient}@sumit-ai.com,record@sumit-ai.com`;
    navigator.clipboard
      .writeText(recordAddress)
      .catch((err) => console.error("Failed to copy: ", err));
    toastSuccess(t("address_copied"));
  };

  const handleCreateNewEventRecord = async (meetingUrl: string) => {
    if (!user || !client) return;
    try {
      await recordEvent.mutateAsync({
        idClient: client.idClient,
        idUser: user.idUser,
        meetingUrl,
      });
      clearModalContent();
      toastSuccess(t("create_meeting_record_success"));
    } catch (err) {
      toastError(t("create_meeting_record_fail"));
    }
  };

  const toggleEventRecord = async (event: RecallEvent) => {
    if (!user || !client) return;
    try {
      setLoadingEventIds((prev) => new Set(prev).add(event.id));
      if (event.hasBot) {
        await cancelEventRecording.mutateAsync(event.id, {
          onSuccess: async () => {
            await refetch();
            setLoadingEventIds((prev) => {
              const newSet = new Set(prev);
              newSet.delete(event.id);
              return newSet;
            });
          },
        });
      } else {
        await recordEvent.mutateAsync(
          {
            idClient: client.idClient,
            idUser: user.idUser,
            meetingUrl: event.meetingUrl,
            idEvent: event.id,
            startDate: event.start,
            title: event.title,
          },
          {
            onSuccess: async () => {
              await refetch();
              setLoadingEventIds((prev) => {
                const newSet = new Set(prev);
                newSet.delete(event.id);
                return newSet;
              });
            },
          }
        );
      }
    } catch (error) {
      toastError(t("create_meeting_record_fail"));
      setLoadingEventIds((prev) => {
        const newSet = new Set(prev);
        newSet.delete(event.id);
        return newSet;
      });
    }
  };

  return (
    <div className="meetingSchedule" ref={containerRef}>
      <div className="meetingScheduleHeader">
        {calendarUser &&
          (calendarUser.provider === "google" ? (
            <GoogleSignInButton
              signIn={googleLogin}
              signOut={handleCalendarLogout}
              user={calendarUser?.user}
              logoutLabel={t("calendar_logout_button")}
            />
          ) : (
            <MicrosoftSignInButton
              signIn={handleMicrosoftLogin}
              signOut={handleCalendarLogout}
              user={calendarUser?.user}
              logoutLabel={t("calendar_logout_button")}
            />
          ))}
        <div className="invitationAddressLink">
          {t("copy_invitation_address")}{" "}
          <Button
            className="roundBtn"
            onClick={handleCopyInvitationLink}
            disabled={!calendarUser}
          >
            <FontAwesomeIcon icon={addressCopyCheck ? faCheck : faCopy} />
          </Button>
        </div>
        <Button
          className="newEvent roundBtn"
          onClick={handleOpenNewMeetingModal}
          disabled={!calendarUser}
        >
          <FontAwesomeIcon icon={faVideoCamera} />
        </Button>
      </div>
      {!calendarUser && (
        <div className="loginContainer">
          <div className="authProviders">
            <GoogleSignInButton
              signIn={googleLogin}
              signOut={handleCalendarLogout}
              user={calendarUser}
              loginLabel={t("google_login_button")}
            />
            <MicrosoftSignInButton
              signIn={handleMicrosoftLogin}
              signOut={handleCalendarLogout}
              user={calendarUser?.user}
              loginLabel={t("microsoft_login_button")}
            />
          </div>
          <div className="orSeparator">{t("or")}</div>

          <Button className="newEvent" onClick={handleOpenNewMeetingModal}>
            {t("paste_meeting_link")}
          </Button>
        </div>
      )}

      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        style={{
          height: Number(containerRef.current?.offsetHeight) - 100,
          opacity: !calendarUser || isLoading ? 0.4 : 1,
          pointerEvents: calendarUser ? "auto" : "none",
        }}
        formats={{ eventTimeRangeFormat: () => "" }}
        scrollToTime={new Date()}
        defaultView="week"
        views={["day", "week", "month"]}
        onRangeChange={handleCalendarDateChange}
        components={{
          event: ({ title, event }: any) => (
            <MeetingScheduleEvent
              event={event}
              toggleEventRecord={toggleEventRecord}
              isLoading={loadingEventIds.has(event.id)}
            />
          ),
        }}
        messages={{
          next: t("next"),
          previous: t("previous"),
          today: t("today"),
          month: t("month"),
          week: t("week"),
          day: t("day"),
        }}
      />
      {isLoading && (
        <div className="loading">
          <SpinningLoader style={{ width: 48 }} />
        </div>
      )}
    </div>
  );
};

export default MeetingSchedule;
