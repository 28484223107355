import config from "../../config";
import React, { useCallback, useMemo } from "react";
import { Grid, Typography } from "@mui/material";
import {
  Breadcrumbs,
  NewUploads,
  SpinningLoader,
} from "@sumit-platforms/ui-bazar";
import { useFeatureFlag, useToast } from "@sumit-platforms/ui-bazar/hooks";
import { useTranslation } from "react-i18next";
import { useGlobalData } from "../../store";
import { clientStore } from "../../store/client";

import "./NewUploadFiles.scss";

const NewUploadFiles = () => {
  const { t } = useTranslation();
  const { setToast, languages, direction } = useGlobalData();
  const toast = useToast({ setToast });
  const concatMediaFF = useFeatureFlag("must_concat_media");
  const { client } = clientStore();

  return (
    <Grid
      className="NewUploadFiles Page"
      container
      display={"flex"}
      justifyContent={"center"}
    >
      <Grid item xs={11} height={"100%"}>
        {!client ? (
          <Grid
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
          >
            <Grid item width={60} height={60}>
              <SpinningLoader />
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid item xs={11} mb={3}>
              <Typography className={"pageTitle"}>{t("uploads")}</Typography>
            </Grid>
            <NewUploads
              config={config}
              client={client}
              toast={toast}
              featureFlags={{
                concatMedia: concatMediaFF,
              }}
              languages={languages}
              dir={direction}
              onlyUserUploads={true}
            />
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default NewUploadFiles;
