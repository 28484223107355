import axios from "axios";
import {
  QueryParams,
  User,
  UserFilterOptions,
  UserSettings,
  UserStatus,
} from "@sumit-platforms/types";

const UserService = ({ config }: { config: any }) => {
  const usersEndpoint = `${config.server.host}/${config.server.users}`;
  const get = async (
    query?: QueryParams<UserFilterOptions>
  ): Promise<{ totalUsers: number; users: User[] }> => {
    try {
      const users = await axios.get<{ totalUsers: number; users: User[] }>(
        `${usersEndpoint}`,
        {
          params: query,
        }
      );
      return users.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const update = async (user: Partial<User>, idUser: number) => {
    try {
      const response = await axios.put(`${usersEndpoint}/${idUser}`, user);
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const linkUserToClient = async (idUser: number, idClient: number) => {
    try {
      const response = await axios.put(`${usersEndpoint}/linkUserToClient`, {
        idUser,
        idClient,
      });
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const setUserRole = async (
    idUser: number,
    idRole: string,
    idClient: number
  ) => {
    try {
      const response = await axios.put(`${usersEndpoint}/setUserRole`, {
        idUser,
        idRole,
        idClient,
      });
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const getAvailableUsersToLink = async (idClient: number) => {
    try {
      const response = await axios.get<User[]>(
        `${usersEndpoint}/getAvailableUsersToLink/${idClient}`
      );
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const removeUserFromClient = async ({
    idClient,
    idUser,
  }: {
    idClient: number;
    idUser: number;
  }) => {
    try {
      const response = await axios.put(
        `${usersEndpoint}/removeUserFromClient`,
        {
          idClient,
          idUser,
        }
      );
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const updateUserSettings = async (newUserSettings: Partial<UserSettings>) => {
    try {
      const response = await axios.put<UserSettings>(
        `${usersEndpoint}/updateUserSettings`,
        newUserSettings
      );
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const updateUserStatus = async (idUser: number, status: UserStatus) => {
    try {
      const response = await axios.post<UserSettings>(
        `${usersEndpoint}/${idUser}/updateStatus`,
        { status }
      );
      if (response.status !== 200) {
        throw new Error(response.statusText);
      }
      return response.data;
    } catch (err) {
      console.error(err);
      throw err;
    }
  };

  const unsubscribe = async (email: string) => {
    try {
      const response = await axios.post(
        `${usersEndpoint}/unsubscribe?email=${email}`
      );
      return response.data;
    } catch (err) {
      console.error(err);
    }
  };

  return {
    get,
    update,
    linkUserToClient,
    getAvailableUsersToLink,
    removeUserFromClient,
    setUserRole,
    updateUserStatus,
    updateUserSettings,
    unsubscribe,
  };
};

export default UserService;
