import Box from "@mui/material/Box";
import { Direction, UploadFileForm } from "@sumit-platforms/types";
import type { UpdateClientUploadsSettings } from "./NewUploads";
import { useTranslation } from "react-i18next";
import React from "react";
import { UploadPageSectionTitle } from "./UploadsManager/UploadPageSectionTitle";
import { SubmissionActions } from "./Submission/SubmissionActions";
import { SubmissionContent } from "./Submission/SubmissionContent";
import { SubmissionStatus } from "./Submission/SubmissionStatus";

interface Props {
  uploadState: UploadFileForm;
  onUploadsUpdate: UpdateClientUploadsSettings;
  handleUpload: () => void;
  submitDisabled: boolean;
  isSubmitLoading: boolean;
  isUploadSuccess: boolean;
  uploadError: { message?: string };
  onUploadMoreClick: () => void;
  toDeliveriesPage: () => void;
  onEditUploadClick: () => void;
  dir?: Direction;
}

export const Submission = ({
  onUploadsUpdate,
  uploadState,
  handleUpload,
  submitDisabled,
  isSubmitLoading,
  isUploadSuccess,
  onUploadMoreClick,
  toDeliveriesPage,
  onEditUploadClick,
  uploadError,
  dir,
}: Props) => {
  const { t } = useTranslation();

  return (
    <Box
      className="SubmissionWrapper"
      display="flex"
      justifyContent="space-between"
    >
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        width={"100%"}
      >
        <UploadPageSectionTitle title={`3. ${t("submission")}`} />

        <SubmissionContent
          isSubmitLoading={isSubmitLoading}
          isUploadSuccess={isUploadSuccess}
          uploadState={uploadState}
          onUploadsUpdate={onUploadsUpdate}
          uploadError={uploadError}
          dir={dir}
        />

        <SubmissionActions
          isUploadSuccess={isUploadSuccess}
          submitDisabled={submitDisabled}
          handleUpload={handleUpload}
          onUploadMoreClick={onUploadMoreClick}
          toDeliveriesPage={toDeliveriesPage}
          uploadError={uploadError}
          onEditUploadClick={onEditUploadClick}
        />
      </Box>

      <SubmissionStatus
        isSubmitLoading={isSubmitLoading}
        isUploadSuccess={isUploadSuccess}
        uploadError={uploadError}
      />
    </Box>
  );
};
