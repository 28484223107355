import React from "react";
import { CircularProgress, ThemeProvider } from "@mui/material";
import classNames from "classnames";
import { bazarTheme } from "../../bazar-theme";

import "./SpinningLoader.scss";

export const SpinningLoader = ({
  className,
  blur = false,
  style = {},
  disableShrink = false,
}: {
  className?: string;
  blur?: boolean;
  style?: React.CSSProperties;
  disableShrink?: boolean;
}) => {
  return (
    <ThemeProvider theme={bazarTheme}>
      <div
        className={classNames("SpinningLoaderComponent", className, { blur })}
        style={style}
      >
        <CircularProgress
          disableShrink={disableShrink}
          color={"primary"}
          style={{ color: style.color }}
        />
      </div>
    </ThemeProvider>
  );
};
