import _ from "lodash";
import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";
import chroma from "chroma-js";
import { Option } from "../MultiSelect/MultiSelect";
import { StylesConfig, GroupBase } from "react-select";
import { primaryColor } from "../../bazar-theme";
import InputLabel from "@mui/material/InputLabel";

export interface TagOption extends Option {
  valueParent?: any;
}

export interface TagsSelectProps {
  search: (term?: string | undefined) => void;
  options: TagOption[];
  selected: TagOption[];
  onSelect: (value: TagOption[]) => void;
  onCreate: (value: string, idClient: number) => void;
  idClient: number;
  placeholder?: string;
  label?: string;
  className?: string;
}

const styles: StylesConfig<TagOption, true, GroupBase<TagOption>> = {
  multiValueLabel: (baseStyle) => ({
    ...baseStyle,
    color: primaryColor,
  }),
  multiValue: (baseStyle) => ({
    ...baseStyle,
    backgroundColor: chroma(primaryColor).alpha(0.1).css(),
    borderRadius: 10,
    padding: " 2px 10px 2px 10px",
  }),
  multiValueRemove: (baseStyle) => ({
    ...baseStyle,
    color: primaryColor,
    ":hover": {
      backgroundColor: "transparent",
      cursor: "pointer",
    },
  }),
  control: (baseStyle) => ({
    ...baseStyle,
    outline: "none",
    borderRadius: 10,
    padding: " 2px 0px 2px 0px",
    borderColor: primaryColor,
    ":active": {
      borderColor: primaryColor,
    },
    ":hover": {
      borderColor: primaryColor,
    },
  }),
};

export const TagsSelect = ({
  search,
  options,
  selected,
  onSelect,
  onCreate,
  idClient,
  placeholder,
  label,
  className,
}: TagsSelectProps) => {
  const [loading, setLoading] = useState(false);

  const handleOnCreate = async (value: string) => {
    await onCreate(value, idClient);
  };

  const handleOnChange = (_selected: any) => {
    onSelect(_selected);
  };

  const handleOnSearch = async (e: any) => {
    setLoading(true);
    debouncedSearchTags(e.target.value);
  };

  const debouncedSearchTags = _.debounce(async (term) => {
    await search(term);
    setLoading(false);
  }, 300);

  return (
    <>
      {label && <InputLabel shrink>{label}</InputLabel>}
      <CreatableSelect
        placeholder={placeholder}
        className={className}
        onChange={handleOnChange}
        isClearable
        isMulti={true}
        options={options}
        tabSelectsValue={true}
        value={selected}
        onCreateOption={handleOnCreate}
        isLoading={loading}
        onKeyDown={handleOnSearch}
        styles={styles}
      />
    </>
  );
};
