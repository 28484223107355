import React, { ChangeEvent } from "react";
import { Input } from "../../../../../index";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { Typography } from "@mui/material";

interface Props {
  value: any;
  onChange: (e: Partial<ChangeEvent<HTMLInputElement>>) => void;
}

export const SpeakersNumberInput = ({ value, onChange }: Props) => {
  const { t } = useTranslation();

  return (
    <Box className={"SpeakersNumberInputWrapper"} py={1}>
      <Typography variant={"subtitle1"}>
        {" "}
        {t("speakers_input_label")}:
      </Typography>
      <Input
        type={"number"}
        min={0}
        max={99}
        value={value}
        onChange={onChange}
        className={"SpeakersNumberInput"}
        placeholder={"-"}
      />
    </Box>
  );
};
