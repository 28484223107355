import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { Input, type UpdateClientUploadsSettings } from "../../../../../index";
import { Switch } from "../../../../../v3";
import { UploadFileForm } from "@sumit-platforms/types";
import { useCallback } from "react";

interface Props {
  uploadState: UploadFileForm;
  onUploadsUpdate: UpdateClientUploadsSettings;
  defaultMultiTrackFileName: string;
  isAllMediasSameDuration: boolean;
  isMultiTrackDisabled: boolean;
  isConcatDisabled: boolean;
  showConcatMedia?: boolean;
  maxGapInMinForMultiTrack: number;
}

export const MultiTrackFileInputs = ({
  onUploadsUpdate,
  uploadState,
  defaultMultiTrackFileName,
  isAllMediasSameDuration,
  isMultiTrackDisabled,
  isConcatDisabled,
  showConcatMedia,
  maxGapInMinForMultiTrack,
}: Props) => {
  const { t } = useTranslation();

  const handleMergeMediaChange = useCallback(
    (e: any) => {
      const checked = e.target.checked;
      onUploadsUpdate({ field: "multiTrackMedia", data: checked });
      if (checked && !uploadState.multiTrackMediaName) {
        onUploadsUpdate({
          field: "multiTrackMediaName",
          data: defaultMultiTrackFileName,
        });
      }
    },
    [onUploadsUpdate]
  );

  const handleConcatMediaChange = useCallback(
    (e: any) => {
      const checked = e.target.checked;
      onUploadsUpdate({ field: "concatMedia", data: checked });
      if (checked && !uploadState.multiTrackMediaName) {
        onUploadsUpdate({
          field: "multiTrackMediaName",
          data: defaultMultiTrackFileName,
        });
      }
    },
    [onUploadsUpdate]
  );

  const handleMultiTrackMediaNameChange = useCallback(
    (e: any) => {
      const newValue = e.target.value;
      onUploadsUpdate({ field: "multiTrackMediaName", data: newValue });
    },
    [onUploadsUpdate]
  );

  return (
    <Box className={"MergeFilesInput"} py={1.5}>
      <Typography variant={"h3"}>{t("merge_files")}:</Typography>
      <Typography variant={"subtitle2"} py={0.5}>
        {t("merge_files_subtitle")}:
      </Typography>
      <Box className={"switchesContainer"}>
        <Box
          className={"MultiTrackMedia"}
          display={"flex"}
          flexDirection={"column"}
          width={"250px"}
        >
          <Switch
            label={t("multi_track_media")}
            infoTooltip={t("multi_track_media_tooltip")}
            checked={uploadState.multiTrackMedia}
            id={"1"}
            onChange={handleMergeMediaChange}
            disabled={isMultiTrackDisabled || uploadState.concatMedia}
            reverse
          />
          {uploadState.multiTrackMedia && !isMultiTrackDisabled && (
            <Input
              className="MergeFilesNameInput"
              label={`${t("multi_track_media_name")}:`}
              value={uploadState.multiTrackMediaName}
              onChange={handleMultiTrackMediaNameChange}
              autofocus
            />
          )}
        </Box>
        {!isAllMediasSameDuration && (
          <Typography color={"red"} fontSize={14}>
            {t("medias_not_same_duration", { maxGapInMinForMultiTrack })}
          </Typography>
        )}
        {showConcatMedia && (
          <Box
            className={"ConcatMedia"}
            display={"flex"}
            flexDirection={"column"}
          >
            <Switch
              width={"250px"}
              label={t("concat_media")}
              infoTooltip={t("concat_media_tooltip")}
              id={"2"}
              onChange={handleConcatMediaChange}
              checked={uploadState.concatMedia}
              disabled={isConcatDisabled || uploadState.multiTrackMedia}
              reverse
            />

            {uploadState.concatMedia && (
              <Typography my={1} fontSize={14}>
                {t("concat_media_subtitle")}
              </Typography>
            )}
            {uploadState.concatMedia && (
              <Input
                className="MergeFilesNameInput"
                label={`${t("multi_track_media_name")}:`}
                value={uploadState.multiTrackMediaName}
                onChange={handleMultiTrackMediaNameChange}
                autofocus
                style={{ width: "250px" }}
              />
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};
