import _ from "lodash";
import React from "react";
import { GoogleGIcon } from "@sumit-platforms/common-assets/images";
import classNames from "classnames";

import "./GoogleSignInButton.scss";

export const GoogleSignInButton = ({
  signIn,
  signOut,
  user,
  loginLabel = "Continue with Google",
  logoutLabel = "Logout",
}: {
  signIn: () => void;
  signOut: () => void;
  user?: any;
  loginLabel?: string;
  logoutLabel?: string;
}) => {
  return (
    <button
      onClick={!user ? signIn : _.noop}
      className={classNames("GoogleSignInButton", {
        signedIn: user,
      })}
      aria-label="Sign in with Google"
    >
      {user ? (
        <div className="user">
          <img
            className="userPicture"
            src={user.picture}
            width={20}
            height={20}
          />
          <div className="userDetails">
            <div className="email">{user.email}</div>
            <div className="logout" onClick={signOut}>
              {logoutLabel}
            </div>
          </div>
        </div>
      ) : (
        <span className="google-text">{loginLabel}</span>
      )}
      <div className="googleLogo">
        <img src={GoogleGIcon} width={20} height={20} />
      </div>
    </button>
  );
};
