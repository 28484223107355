import { Box, Typography } from "@mui/material";
import { Direction, Project } from "@sumit-platforms/types";
import { recordStore } from "../../../../../../store";
import { ProjectsDropdown } from "../../../../ProjectsDropdown/ProjectsDropdown";
import { useTranslation } from "react-i18next";
import { TagsSelectWrapper } from "../../../../NewUploads/SetOrder/AdvancedSettings/TagsSelectWrapper";

interface Props {
  dir?: Direction;
  projects: Project[];
  handleProjectCreate: (project: string) => Promise<Project | null>;
  idClient?: number;
  config: any;
}

export const RecordOrganizeYourMedia = ({
  dir,
  projects,
  handleProjectCreate,
  idClient,
  config,
}: Props) => {
  const { t } = useTranslation();
  const { uploadSettings, updateUploadSettings } = recordStore();

  return (
    <Box className={"RecordAdvancedSettings"} py={2}>
      <Typography variant={"h3"}>{t("organize_your_media")}:</Typography>
      <Typography variant={"subtitle2"} py={0.5}>
        {t("organize_your_media_subtitle")}:
      </Typography>
      <Box py={1}>
        <ProjectsDropdown
          uploadState={uploadSettings}
          onUploadsUpdate={updateUploadSettings}
          dir={dir}
          projects={projects}
          handleProjectCreate={handleProjectCreate}
        />
      </Box>
      <TagsSelectWrapper
        idClient={idClient}
        config={config}
        uploadState={uploadSettings}
        onUploadsUpdate={updateUploadSettings}
        dir={dir}
      />
    </Box>
  );
};
