import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Tag } from "@sumit-platforms/types";

export const useTags = ({
  entity,
  tagService,
}: {
  entity: "job" | "upload" | "users";
  tagService: any;
}): {
  tags: Tag[];
  setTags: (tags: Tag[]) => void;
  searchTags: (term?: string) => void;
  submitTag: (tageName: string, idClient: number) => Promise<Tag | undefined>;
  fetchTags: () => Promise<Tag[]>;
  fetchAndUpdateTags: () => Promise<Tag[]>;
} => {
  const { t } = useTranslation();
  const [tags, setTags] = useState<Tag[]>([]);

  const fetchAndUpdateTags = async () => {
    const _tags = await fetchTags();
    const filteredTags = _tags.filter((t: Tag) => !_.isEmpty(t.name));
    const orderedTags = _.orderBy(filteredTags, ["name"]);
    setTags(orderedTags);
    return orderedTags;
  };

  useEffect(() => {
    fetchAndUpdateTags();
  }, []);

  const searchTags = async (term?: string) => {
    try {
      let _tags: Tag[] = [];
      if (term) {
        _tags = await tagService.search(term, entity);
      }
      setTags(_tags);
    } catch (err) {
      throw new Error(t("search_tags_failed"));
    }
  };

  const fetchTags = async () => {
    try {
      const _tags = await tagService.get(entity);
      return _tags;
    } catch (err) {
      throw new Error(t("fetching_tags_failed"));
    }
  };

  const submitTag = async (
    tagName: string,
    idClient: number
  ): Promise<Tag | undefined> => {
    try {
      if (!tagName) return;
      return tagService.create({ name: tagName, entities: [entity] }, idClient);
    } catch (err) {
      throw new Error(t("submitting_tags_failed"));
    }
  };

  useEffect(() => {
    searchTags();
  }, []);

  return {
    tags,
    setTags,
    searchTags,
    submitTag,
    fetchTags,
    fetchAndUpdateTags,
  };
};
