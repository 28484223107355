import { useMemo } from "react";
import Box from "@mui/material/Box";
import { MultiTrackFileInputs } from "./MultiTrackFileInputs";
import { OrganizeYourMedia } from "./OrganizeYourMedia";
import { OtherTranscriptionOptions } from "./OtherTranscriptionOptions";
import {
  AdvancedSettingsRenderConfig,
  AvailableUploadDeliveryTypes,
  Direction,
  Project,
  uploadAdvancedSettingsOptions,
  UploadFileForm,
  ValidationPreset,
} from "@sumit-platforms/types";
import { SpeakersSplitInput } from "./SpeakersSplitInput";
import { ValidationsInput } from "./ValidationsInput";
import type {
  UpdateClientUploadsSettings,
  UploadsPageFeatureFlags,
} from "../../NewUploads";

interface AdvancedTranscriptionSettingsProps {
  uploadState: UploadFileForm;
  onUploadsUpdate: UpdateClientUploadsSettings;
  config: any;
  projects: Project[];
  handleProjectCreate: (projectName: string) => Promise<Project | null>;
  idClient: number;
  validations: ValidationPreset[];
  defaultMultiTrackFileName: string;
  isAllMediasSameDuration: boolean;
  isMultiTrackDisabled: boolean;
  isConcatDisabled: boolean;
  maxGapInMinForMultiTrack: number;
  featureFlags?: UploadsPageFeatureFlags;
  dir?: Direction;
  renderOptions?: AdvancedSettingsRenderConfig;
  height: string;
}

export const AdvancedSettings = ({
  onUploadsUpdate,
  uploadState,
  config,
  idClient,
  renderOptions,
  defaultMultiTrackFileName,
  isAllMediasSameDuration,
  isMultiTrackDisabled,
  isConcatDisabled,
  maxGapInMinForMultiTrack,
  featureFlags,
  dir,
  height,
}: AdvancedTranscriptionSettingsProps) => {
  if (!renderOptions) return null;
  return (
    <Box className={"AdvancedSettings"} height={height}>
      {renderOptions.showSpeakersSplit && (
        <SpeakersSplitInput
          onUploadsUpdate={onUploadsUpdate}
          uploadState={uploadState}
          dir={dir}
        />
      )}
      <MultiTrackFileInputs
        onUploadsUpdate={onUploadsUpdate}
        uploadState={uploadState}
        defaultMultiTrackFileName={defaultMultiTrackFileName}
        maxGapInMinForMultiTrack={maxGapInMinForMultiTrack}
        isAllMediasSameDuration={isAllMediasSameDuration}
        isMultiTrackDisabled={isMultiTrackDisabled}
        isConcatDisabled={isConcatDisabled}
        showConcatMedia={featureFlags?.concatMedia}
      />
      <OrganizeYourMedia
        showRepresentative={renderOptions.showRepresentative}
        onUploadsUpdate={onUploadsUpdate}
        uploadState={uploadState}
        config={config}
        idClient={idClient}
        dir={dir}
      />
      <OtherTranscriptionOptions
        onUploadsUpdate={onUploadsUpdate}
        uploadState={uploadState}
        config={config}
        idClient={idClient}
        showSkipStt={renderOptions?.showSkipStt}
        showUseOwnTranscription={renderOptions?.useOwnTranscription}
      />
    </Box>
  );
};
