import { useCallback, useEffect, useRef } from "react";
import _ from "lodash";
import {
  ActiveUpload,
  Client,
  DiarizeTypes,
  JobTranscriptionSource,
  MediaProvider,
  PendingUpload,
  Upload,
  UploadFileForm,
  UploadStatus,
  User,
} from "@sumit-platforms/types";
import { uploadStore } from "../store/uploads.store";

import UploadService from "./../services/uploadService";

import { getMediaProviderByFile } from "../utils/helpers";
import { generateId } from "../utils/generators";
import { checkMediaGap, loadMedia } from "../utils/media";
import { CallbackDoc } from "react-google-drive-picker/dist/typeDefs";
import { t } from "i18next";
import prettyBytes from "pretty-bytes";
import { FileUploader } from "../services/FileUploader";
import { UpdateClientUploadsSettings } from "../../index";

const ACTIVE_UPLOADS_LIMIT = 1;

export const useNewUploads = ({
  config,
  user,
  client,
  defaultSettings,
  onlyUserUploads,
}: {
  config: any;
  user: User | null;
  client: Client;
  defaultSettings?: Partial<UploadFileForm>;
  onlyUserUploads?: boolean;
}): {
  getUploads: typeof getUploads;
  uploads: typeof uploads;
  uploadForm: typeof uploadForm;
  updateUploadForm: typeof updateUploadForm;
  uppyInstances: typeof uppyInstances;
  handleCreateUpload: typeof handleCreateUpload;
  handleUploadMediaToJob: typeof handleUploadMediaToJob;
  activeUploads: typeof activeUploads;
  handleUploadCancel: typeof handleUploadCancel;
  pendingUploads: typeof pendingUploads;
  succeedUploads: typeof succeedUploads;
  progress: typeof progress;
  addUploadsToQueue: typeof addUploadsToQueue;
  startUploadQueue: typeof startUploadQueue;
  deleteUploads: typeof deleteUploads;
  updateUploads: typeof updateUploads;
  mergeUploads: typeof mergeUploads;
  unmergeUploads: typeof unmergeUploads;
  uploadSourceFile: typeof uploadSourceFile;
  uploadAAFFile: typeof uploadAAFFile;
} => {
  const uploadService = useRef(UploadService({ config })).current;
  const tusEndpoint = `${config.server.host}/${config.server.uploads}/tus`;

  const {
    uploads,
    uploadForm,
    setUploadForm,
    pendingUploads,
    activeUploads,
    succeedUploads,
    progress,
    getPendingUploads,
    getActiveUploads,
    setUploads,
    setUppyInstances,
    uppyInstances,
    setPendingUploads,
    setActiveUploads,
    setSucceedUploads,
    setProgress,
    setUploadInfoText,
  } = uploadStore();

  useEffect(() => {
    if (!client?.idClient) return;
    getUploads();
    getDefaultUploadSettings();
  }, [client?.idClient]);

  // --- ACTIONS ---

  const getUploads = useCallback(async () => {
    if (client.idClient) {
      const newUploads = await uploadService.getClientUploads(client.idClient, {
        onlyUserUploads,
      });
      setUploads(() => newUploads);
    }
  }, [client, setUploads, uploadService]);

  const getDefaultUploadSettings = useCallback(async () => {
    let uploadsSettings = _.clone(defaultSettings || {});
    if (client?.uploadSettings) {
      // init the state with the client defaults
      uploadsSettings = _.merge(uploadsSettings, client.uploadSettings);
    }
    setUploadForm(() => uploadsSettings);
  }, [client?.uploadSettings, setUploadForm]);

  const addUploadsToQueue = async ({
    files,
    attachedFiles,
    // getUserToken,
    googleDriveToken,
    client,
    duration,
    mediaProvider,
    idJob,
    onSuccess,
    onFail,
  }: {
    files: File[] | CallbackDoc[];
    attachedFiles?: Record<string, File[]>;
    client: Client;
    // getUserToken: () => Promise<string | undefined>;
    googleDriveToken?: string;
    duration?: number;
    mediaProvider?: MediaProvider;
    idJob?: number;
    onSuccess?: (upload: Upload) => void;
    onFail: () => void;
  }) => {
    if (!user) return;
    const newUploads = files.map((file) => {
      const idUpload = generateId();
      const newUpload: PendingUpload = {
        idUpload,
        name: file.name,
        status: UploadStatus.pending,
        client,
        user,
        idJob,
        startUpload: async () => {
          const uploadFilePayload = {
            idUpload,
            // getUserToken,
            googleDriveToken,
            client,
            file,
            duration,
            mediaProvider,
            idJob,
            onSuccess,
            onFail,
            attachedFiles: attachedFiles && attachedFiles[file.name],
          };
          return newUploadFile(uploadFilePayload);
        },
      };
      return newUpload;
    });
    setPendingUploads((prevUploads: PendingUpload[]) => [
      ...prevUploads,
      ...newUploads,
    ]);
  };

  const startUploadQueue = async () => {
    const _pendingUploads = getPendingUploads();
    const _activeUploads = getActiveUploads();
    const _stableActiveUploads = _activeUploads.filter(
      (upload) =>
        ![UploadStatus.error, UploadStatus.canceled].includes(upload.status)
    );
    if (
      _stableActiveUploads.length === ACTIVE_UPLOADS_LIMIT ||
      _.isEmpty(_pendingUploads)
    )
      return;

    const nextUploadToActivate = _pendingUploads[0];
    nextUploadToActivate.status = UploadStatus.uploading;
    await nextUploadToActivate.startUpload();
    setPendingUploads(() => _pendingUploads.splice(1));

    setActiveUploads(() => [nextUploadToActivate, ..._activeUploads]);
  };

  const updateUppyInstance = (uppyInstance: FileUploader, idUpload: string) => {
    setUppyInstances((uppyInstances) => {
      const updatedState = _.cloneDeep(uppyInstances);
      updatedState[idUpload] = uppyInstance;
      return updatedState;
    });
  };

  const newUploadFile = async ({
    idUpload,
    googleDriveToken,
    client,
    file,
    idJob,
    duration,
    attachedFiles,
    mediaProvider,
    onSuccess,
    onFail,
  }: {
    idUpload: string;
    googleDriveToken?: string;
    client: Client;
    file: File | CallbackDoc;
    idJob?: number;
    duration?: number;
    attachedFiles?: File[]; // change to metadata
    mediaProvider?: MediaProvider;
    onSuccess?: (upload: Upload) => void;
    onFail?: () => void;
  }) => {
    if (!user || !client.idClient) return;

    const provider = mediaProvider || getMediaProviderByFile(file);
    const media = await loadMedia(file as File);
    const fileUploader = new FileUploader({
      file: file as File,
      onError: (error: Error) => {
        handleUploadFail(error, idUpload);
        if (onFail) onFail();
      },
      onProgress: (pr: {
        uploadStartTime: number;
        bytesUploaded: number;
        bytesTotal?: number | null;
      }) => {
        onUploadProgress(idUpload, {
          total: pr.bytesTotal,
          loaded: pr.bytesUploaded,
          speed: (pr.bytesUploaded / (Date.now() - pr.uploadStartTime)) * 1000,
        });
      },
      onSuccess: async (successPayload: {
        uploadUrl: string;
        fileId: string;
      }) => {
        const upload = await uploadService.onSuccess({
          fileId: successPayload.fileId,
          uploadUrl: successPayload.uploadUrl,
          attachedFiles,
          duration: media?.duration || duration,
          file: file as File,
          idClient: client.idClient,
          idJob,
          idUser: user.idUser,
          provider,
        });

        handleUploadSuccess(upload, idUpload);

        if (onSuccess) {
          onSuccess(upload);
        }
      },
      tusEndpoint,
    });

    switch (provider) {
      case MediaProvider.googleDrive: {
        uploadService
          .uploadFilesWithGoogleDrive({
            fileId: (file as CallbackDoc).id,
            token: googleDriveToken as string,
            idUser: user.idUser,
            idClient: client.idClient,
            uploadTempId: idUpload,
            progress: (progressEvent) => {
              onUploadProgress(idUpload, progressEvent);
            },
          })
          .then((res) => handleUploadSuccess(res, idUpload))
          .catch((err) => handleUploadFail(err, idUpload));
        break;
      }
      default: {
        //TODO: check with premier plugin!
        fileUploader.addFile(file as File);
        updateUppyInstance(fileUploader, idUpload);
        fileUploader.startUpload();
      }
    }
  };

  const deleteUploads = async (uploadIds: number[]): Promise<void> => {
    if (uploadIds.length > 0) {
      await uploadService.remove(uploadIds);

      setUploads((prevUploads: Upload[]) =>
        prevUploads.filter((u) => !uploadIds.includes(u.idUpload))
      );
    } else {
      throw new Error("No uploads selected");
    }
  };

  const updateUploads = async (
    uploadIds: number[],
    uploadData: Partial<Upload>
  ): Promise<void> => {
    if (uploadIds.length < 1) {
      throw new Error("No uploads selected");
    }

    if (uploadData.jobType?.typeName === "interview") {
      _.set(uploadData, "diarize.diarize", DiarizeTypes.UNSUPRTVISED);
    }

    const updatedUploads: Upload[] = await uploadService.updateMany(
      uploadIds,
      uploadData
    );

    // Creating a map so we can keep the indexes order
    const updatedUploadsMap = new Map(
      updatedUploads.map((upload) => [upload.idUpload, upload])
    );

    setUploads((prevUploads) => {
      return prevUploads.map((upload) => {
        if (uploadIds.includes(upload.idUpload)) {
          return updatedUploadsMap.get(upload.idUpload) as Upload;
        }
        return upload;
      });
    });
  };

  const mergeUploads = async (uploads: Upload[]): Promise<void> => {
    const uploadIds = uploads.map((u) => u.idUpload);
    const filesToGetMerged = _.flatMapDeep(uploads.map((u) => u.media));

    if (!checkMediaGap(filesToGetMerged.map((f) => f.duration)))
      throw new Error("files_can_not_be_merged");

    const mergedUpload = await uploadService.merge(uploadIds);

    setUploads((prevUploads) => {
      const updatedUploads = [
        ...prevUploads.filter((u) => !uploadIds.includes(u.idUpload)),
        mergedUpload,
      ];
      return updatedUploads;
    });
  };

  const unmergeUploads = async (idUpload: number, idMedia: number) => {
    const uploadToUnmerge = uploads.find((u) => u.idUpload === idUpload);
    if (!uploadToUnmerge) return;
    const { originUpload, upload } = await uploadService.unmerge(idMedia);
    setUploads((prevUploads) => {
      const updatedUploads = prevUploads.filter(
        (u: Upload) => u.idUpload !== idUpload
      );
      return [originUpload, upload, ...updatedUploads];
    });
  };

  // --- ACTIONS ---

  // --- HANDLERS ---

  const handleCreateUpload = async ({
    files,
    client,
    onSuccess,
    onFail,
    attachedFiles,
    googleDriveToken,
    // getToken,
    duration,
    mediaProvider,
  }: {
    files: File[] | CallbackDoc[];
    client: Client;
    onSuccess?: (upload: Upload) => void;
    onFail: () => void;
    attachedFiles?: Record<string, File[]>; // additional files to upload to same bucket path such as aaf
    googleDriveToken?: string;
    // getToken: (forceRefresh?: boolean) => Promise<string | undefined>;
    duration?: number;
    mediaProvider?: MediaProvider;
  }) => {
    if (!client.idClient || !user?.idUser) {
      console.error("no user or client", {
        idClient: client.idClient,
        user: user,
      });
      onFail && onFail();
      return;
    }
    await addUploadsToQueue({
      // getUserToken: () => getToken(true),
      googleDriveToken,
      files: files as File[],
      attachedFiles,
      client: client,
      duration,
      mediaProvider,
      onSuccess,
      onFail: () => onFail && onFail(),
    });
    startUploadQueue();
  };

  const handleUploadMediaToJob = async ({
    files,
    attachedFiles,
    client,
    idJob,
    duration,
    mediaProvider,
    googleDriveToken,
    // getToken,
    onSuccess,
    onFail,
  }: {
    files: File[] | CallbackDoc[];
    attachedFiles?: Record<string, File[]>; // additional files to upload to same bucket path such as aaf
    client: Client;
    idJob: number;
    duration?: number;
    mediaProvider?: MediaProvider;
    googleDriveToken?: string;
    // getToken: (forceRefresh?: boolean) => Promise<string | undefined>;
    onSuccess?: (upload: Upload) => void;
    onFail: () => void;
  }) => {
    if (!idJob || !client.idClient || !user?.idUser) {
      console.error("upload failed", {
        idJob,
        idClient: client.idClient,
        user,
      });
      onFail && onFail();
      return;
    }
    await addUploadsToQueue({
      files: files as File[],
      attachedFiles,
      client: client,
      idJob,
      duration,
      mediaProvider,
      googleDriveToken,
      // getUserToken: () => getToken(true),
      onSuccess: (media) => onSuccess && onSuccess(media),
      onFail: () => onFail && onFail(),
    });
    startUploadQueue();
  };

  const onUploadProgress = (
    idUpload: string,
    progressInfo: {
      total?: number | null;
      loaded: number;
      speed: number;
    }
  ) => {
    if (
      progressInfo &&
      _.isNumber(progressInfo.total) &&
      _.isNumber(progressInfo.loaded)
    ) {
      const uploadProgress = Math.round(
        (progressInfo.loaded / progressInfo.total) * 100
      );
      setProgress(idUpload, {
        progress: uploadProgress,
        speed: progressInfo.speed,
        total: progressInfo.total,
        loaded: progressInfo.loaded,
      });
      const uploadInfoText = `(${prettyBytes(
        progressInfo.loaded
      )} of ${prettyBytes(progressInfo.total)})`;
      setUploadInfoText(idUpload, uploadInfoText);
    }
  };

  const handleUploadSuccess = (upload: Upload, idUpload: string) => {
    setActiveUploads((prevUploads) =>
      prevUploads.filter((u) => u.idUpload !== idUpload)
    );
    setUploads((prevUploads) => [upload, ...prevUploads]);
    setSucceedUploads((prevUploads) => [upload, ...prevUploads]);
    setProgress(idUpload, null);
    setUploadInfoText(idUpload, "");
    startUploadQueue();
  };

  const handleUploadFail = (err: any, idUpload: string | number) => {
    setActiveUploads((prevActiveUploads) => {
      const updatedActiveUploads = _.map(prevActiveUploads, (u) => {
        const updatedActiveUpload = u;
        if (u.idUpload === idUpload) {
          updatedActiveUpload.status = UploadStatus.error;
        }
        return updatedActiveUpload;
      });
      return updatedActiveUploads;
    });
    startUploadQueue();
  };

  const handleUploadCancel = (idUpload: string | number) => {
    const uploadUppyInstance = uppyInstances[idUpload];
    if (!uploadUppyInstance?.fileId) {
      throw new Error("No uppy instance");
    }
    uploadUppyInstance.cancelUpload();

    setActiveUploads((prevActiveUploads) => {
      const updatedActiveUploads = prevActiveUploads.filter(
        (up) => up.idUpload !== idUpload
      );
      return updatedActiveUploads;
    });
    startUploadQueue();
  };

  const updateUploadForm: UpdateClientUploadsSettings = ({ data, field }) => {
    setUploadForm((prev) => {
      const prevClone = _.cloneDeep(prev);
      const updatedState = _.set(prevClone, field, data);
      return updatedState;
    });
  };

  const uploadSourceFile = async (file: File) => {
    const fileInfo = await uploadService.uploadSourceFile(-1, file);
    updateUploadForm({
      data: fileInfo,
      field: "srcFile",
    });
    updateUploadForm({
      data: JobTranscriptionSource.SOURCE_FILE,
      field: "jobType.transcriptionSource" as keyof UploadFileForm,
    });
    return fileInfo;
  };

  const uploadAAFFile = async (file: File) => {
    const fileInfo = await uploadService.uploadSettingsFile(-1, file);
    updateUploadForm({
      data: fileInfo,
      field: "settings.aaf" as keyof UploadFileForm,
    });
    return fileInfo;
  };

  // --- HANDLERS ---

  return {
    getUploads,
    uploads,
    pendingUploads,
    activeUploads,
    succeedUploads,
    progress,
    uppyInstances,
    handleCreateUpload,
    handleUploadMediaToJob,
    addUploadsToQueue,
    startUploadQueue,
    deleteUploads,
    updateUploads,
    uploadForm,
    updateUploadForm,
    handleUploadCancel,
    mergeUploads,
    unmergeUploads,
    uploadSourceFile,
    uploadAAFFile,
  };
};
