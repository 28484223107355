import React from "react";
import { Checkbox } from "../../../../index";
import { Box, MenuItem } from "@mui/material";
import type { SelectOption } from "./Select";

interface Props {
  height: number;
  props: any;
  option: SelectOption;
  checked?: boolean;
  multiple?: boolean;
}

export const SelectOptionItem = ({
  checked,
  multiple,
  height,
  props,
  option,
}: Props) => {
  return option ? (
    <MenuItem
      {...props}
      className={"SelectItemOption"}
      sx={{
        height,
      }}
      key={`${option.value}-${option.label}`}
    >
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        width={"100%"}
      >
        {option.label}
        {multiple && !option.triggerNewValue && (
          <Checkbox checked={!!checked} />
        )}
      </Box>
    </MenuItem>
  ) : null;
};
