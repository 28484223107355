import React from "react";
import {
  Box,
  ListItem as MuiListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { Direction } from "@sumit-platforms/types";
import classNames from "classnames";

import "./List.scss";

type ListItemAction = {
  icon: IconProp;
  bgColor?: string;
  iconColor?: string;
  closeOnClick?: boolean;
  action: any;
};

export interface ListItemProps {
  text: string;
  description?: string;
  actions?: ListItemAction[];
  dir?: Direction;
}

export const ListItem = ({
  text,
  description,
  actions,
  close,
  dir,
}: ListItemProps & { close: () => void }) => {
  return (
    <MuiListItem className={classNames("ListItem", dir)} divider={false}>
      <ListItemText
        primary={text}
        secondary={description}
        className={classNames("ListItemText")}
      />
      <Box className={"actionsWrapper"}>
        {actions?.map((action, idx) => {
          return (
            <ListItemButton
              sx={{
                backgroundColor: `${action.bgColor} !important`,
              }}
              onClick={() => {
                action.action();
                if (action.closeOnClick) {
                  close();
                }
              }}
              className={"actionButton"}
              key={idx}
            >
              <Box>
                <FontAwesomeIcon
                  className={"actionIcon"}
                  icon={action.icon}
                  style={{
                    color: action.iconColor,
                    fontSize: "1.25rem",
                  }}
                />
              </Box>
            </ListItemButton>
          );
        })}
      </Box>
    </MuiListItem>
  );
};
