import React from "react";
import { Box, InputAdornment, TextField } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faSearch,
} from "@fortawesome/pro-light-svg-icons";
import { Direction } from "@sumit-platforms/types";
import { SpinningLoader } from "../../../../index";
import classNames from "classnames";

interface Props {
  params: any;
  placeholder?: string;
  dir: Direction;
  open: boolean;
  displayTags?: boolean;
  loading?: boolean;
  isFocus?: boolean;
  setOpen: any;
  inputValue: string;
  onInputChange: (event: any) => void;
  onFocus?: (event: any) => void;
  onBlur?: (event: any) => void;
  value: (string | number)[];
}

export const SelectInput = ({
  params,
  placeholder,
  dir,
  value,
  open,
  loading,
  displayTags,
  isFocus,
  setOpen,
  inputValue,
  onInputChange,
  onBlur,
  onFocus,
}: Props) => {
  return (
    <TextField
      {...params}
      placeholder={
        displayTags
          ? placeholder
          : inputValue || (value?.length && !isFocus)
          ? ""
          : placeholder
      }
      className={classNames("NewSelectInput", {
        grayBg: isFocus,
      })}
      value={inputValue}
      onChange={onInputChange}
      onFocus={onFocus}
      onBlur={onBlur}
      disabled={loading}
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <InputAdornment
            className={classNames("SelectInputEndAdornment", dir)}
            position="end"
          >
            {loading && (
              <Box
                width={"16px"}
                sx={{ position: "absolute", right: dir === "rtl" ? -24 : 24 }}
              >
                <SpinningLoader />
              </Box>
            )}
            <FontAwesomeIcon
              className="openIcon"
              icon={
                open
                  ? faChevronDown
                  : dir === "rtl"
                  ? faChevronLeft
                  : faChevronRight
              }
              style={{ cursor: "pointer" }}
              onClick={() => setOpen((prev: boolean) => !prev)}
            />
          </InputAdornment>
        ),
        startAdornment: isFocus ? (
          <>
            <FontAwesomeIcon className={"searchInputIcon"} icon={faSearch} />
            {/*{value?.length ? null : params.InputProps.startAdornment}*/}
          </>
        ) : displayTags ? null : (
          params.InputProps.startAdornment
        ),
      }}
    />
  );
};
