import { Attachment } from "./Attachment";
import { JobTypeMeta } from "./JobTypes";
import { UploadStatus } from "../constants/statuses";
import { Media } from "./Media";
import { Tag } from "./Tag";
import { Client } from "./Client";
import { User } from "./User";
import { Diarize, DiarizeTypes } from "../Algo/diarize";
import { MulterFileInfo } from "../common/fileinfo";
import { CallbackDoc } from "react-google-drive-picker/dist/typeDefs";
import { ValidationsConfig } from "../config-settings/ValidationsConfig";
import { Languages } from "../constants/languages";
import { Project } from "./Project";

interface Upload {
  idUpload: number;
  projectName: string;
  name: string;
  status: UploadStatus;
  jobType: JobTypeMeta;
  inputLanguage: Languages[];
  outputLanguage: Languages[];
  delivery: Date | null;
  price: number;
  client: Client;
  user: User;
  attachments: Attachment[];
  media: Media[];
  tags: Tag[];
  createdAt: Date;
  settings: UploadSettings;
  note?: string;
  isSubmitted?: boolean;
  validationPreset?: ValidationsConfig;
  idValidationPreset?: number | null;
  srcFile?: MulterFileInfo | null;
  idProject?: number;
  project?: Project;
}

interface ActiveUpload {
  idUpload: string;
  name: string;
  status: UploadStatus;
  client: Client;
  user: User;
  idJob?: number;
}
interface PendingUpload extends ActiveUpload {
  startUpload: () => Promise<void>;
}

type UploadSettings = {
  diarize?: Diarize;
  aaf?: MulterFileInfo;
};

type UploadProgress = {
  progress: number;
  speed: number;
  total: number;
  loaded: number;
};

interface UploadTableRow {
  id: number | string;
  name: string;
  language: { input: string[]; output: string[] };
  jobType: JobTypeMeta | null;
  delivery: Date | null;
  actions: any;
  submit: any;
  note: string | null;
  createdAt?: Date;
  attachments: Attachment[];
  tags: Tag[];
  media: Media[];
  duration: number;
  settings: UploadSettings;
  price?: number;
  status?: UploadStatus;
  disabled?: boolean;
  isSubmitable?: boolean;
  file?: File | CallbackDoc | undefined;
  validationPreset?: ValidationsConfig;
  srcFile?: MulterFileInfo | null;
  project?: Project;
}

interface ActiveUploadTableRow {
  id: string;
  name: string;
  status: UploadStatus;
}

type UploadFileForm = Partial<Upload> & {
  multiTrackMedia?: boolean;
  multiTrackMediaName?: string;
  concatMedia?: boolean;
  tempTranscriptionFile?: File | null;
  tempAAFFile?: File | null;
};

export type AdvancedSettingsRenderConfig = {
  showRepresentative: boolean;
  showValidation: boolean;
  showSpeakersSplit: boolean;
  useOwnTranscription: boolean;
  showSkipStt: boolean;
};

export type AvailableUploadDeliveryTypes =
  | "protocol"
  | "brief"
  | "interview"
  | "subtitles";

export const uploadAdvancedSettingsOptions: Record<
  AvailableUploadDeliveryTypes,
  AdvancedSettingsRenderConfig
> = {
  subtitles: {
    showRepresentative: false,
    showValidation: true,
    showSpeakersSplit: false,
    useOwnTranscription: true,
    showSkipStt: true,
  },
  protocol: {
    showRepresentative: true,
    showValidation: false,
    showSpeakersSplit: true,
    useOwnTranscription: true,
    showSkipStt: true,
  },
  brief: {
    showRepresentative: true,
    showValidation: false,
    showSpeakersSplit: true,
    useOwnTranscription: true,
    showSkipStt: true,
  },
  interview: {
    showRepresentative: false,
    showValidation: false,
    showSpeakersSplit: true,
    useOwnTranscription: true,
    showSkipStt: true,
  },
};

const DEFAULT_UPLOAD_SETTINGS: Partial<UploadFileForm> = {
  settings: {
    diarize: {
      diarize: DiarizeTypes.UNSUPRTVISED,
    },
  },
};

export type {
  Upload,
  ActiveUpload,
  PendingUpload,
  UploadSettings,
  UploadProgress,
  UploadTableRow,
  ActiveUploadTableRow,
  UploadFileForm,
};
export { DEFAULT_UPLOAD_SETTINGS };
