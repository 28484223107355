import { useTranslation } from "react-i18next";
import {
  Button,
  IconButton,
  type UpdateClientUploadsSettings,
} from "../../../../../index";
import { Switch } from "../../../../../v3";
import {
  fileInputAccept,
  JobTranscriptionSource,
  MulterFileInfo,
  srtExtensions,
  UploadFileForm,
} from "@sumit-platforms/types";
import { useCallback, useState, useRef, ChangeEvent, useMemo } from "react";
import Box from "@mui/material/Box";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImport, faTrash } from "@fortawesome/pro-light-svg-icons";
import Typography from "@mui/material/Typography";

const MAX_FILE_SIZE = 20 * 1024 * 1024; // 20MB in bytes

export const ImportTranscriptionInput = ({
  onUploadsUpdate,
  uploadState,
}: {
  uploadState: UploadFileForm;
  onUploadsUpdate: UpdateClientUploadsSettings;
}) => {
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnSwitchChange = useCallback(
    (e: any) => {
      onUploadsUpdate({
        data: e.target.checked
          ? JobTranscriptionSource.SOURCE_FILE
          : JobTranscriptionSource.STT,
        field: "jobType.transcriptionSource" as keyof UploadFileForm,
      });
      if (!e.target.checked) {
        if (inputRef.current) {
          inputRef.current.value = "";
        }
      }
    },
    [onUploadsUpdate]
  );

  const handleOnFileChange = useCallback(
    async (e: ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0] || null;
      if (file) {
        if (file.size > MAX_FILE_SIZE) {
          if (inputRef.current) {
            inputRef.current.value = "";
          }
          return;
        }

        onUploadsUpdate({
          data: file,
          field: "tempTranscriptionFile",
        });
      }
    },
    [onUploadsUpdate, t]
  );

  const handleClearFile = useCallback(async () => {
    if (inputRef.current) {
      inputRef.current.value = "";
    }

    onUploadsUpdate({
      data: null,
      field: "tempTranscriptionFile",
    });
  }, [onUploadsUpdate]);

  const browseFile = useCallback(() => {
    inputRef.current?.click();
  }, []);

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}
      className={"ImportTranscriptionInput"}
      width={"100%"}
    >
      <Switch
        label={t("use_my_own_transcription")}
        checked={
          uploadState.jobType?.transcriptionSource ===
          JobTranscriptionSource.SOURCE_FILE
        }
        id="transcription-switch"
        onChange={handleOnSwitchChange}
        reverse
      />
      {uploadState.jobType?.transcriptionSource ===
        JobTranscriptionSource.SOURCE_FILE && (
        <>
          <input
            className="hiddenInput"
            ref={inputRef}
            accept={fileInputAccept(srtExtensions)}
            type="file"
            onChange={handleOnFileChange}
            style={{ display: "none" }}
          />
          <Box display="flex" alignItems="center" gap={2}>
            <Button
              className={"importSrcFileInputButton"}
              variant="outlined"
              onClick={browseFile}
              startIcon={
                <FontAwesomeIcon
                  icon={faFileImport}
                  style={{ marginRight: "10px" }}
                />
              }
            >
              <Typography variant={"body2"}>
                {t("upload_transcription")}
              </Typography>
            </Button>
            {uploadState?.tempTranscriptionFile && (
              <>
                <Box component="span" sx={{ flex: 1 }}>
                  {uploadState?.tempTranscriptionFile?.name}
                </Box>
                <IconButton
                  onClick={handleClearFile}
                  tooltipText={t("remove_uploaded_transcription")}
                  icon={faTrash}
                  size="small"
                />
              </>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};
